import BoldText from '@/components/BoldText';
import { ICon } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice from '@/redux/slice/widget.slice';
import { Icon, SkeletonBodyText, TextField } from '@shopify/polaris';
import { ChevronDownIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { ContactFormStyled } from './styled';
interface IProps {
  handleCloseWindow: () => void;
}
const ContactForm = ({ handleCloseWindow }: IProps) => {
  const dispatch = useDispatch();
  const contactForm = useChannelSelected(ICon.ContactForm).channel;
  const handleClose = () => {
    handleCloseWindow();
    dispatch(widgetSlice.actions.handleWindow(undefined));
  };

  return (
    <ContactFormStyled
      backgroundColor={contactForm?.advance?.formSubmitButtonBackgroundColor || '#000'}
      textColor={contactForm?.advance?.formSubmitButtonTextColor || '#fff'}
    >
      <div className="header">
        <div style={{ width: '80%' }}>
          <BoldText>{contactForm?.advance?.formTitle}</BoldText>
        </div>
        <div onClick={handleClose} className="wrap-icon">
          <Icon source={ChevronDownIcon} />
        </div>
      </div>
      <div className="body">
        <TextField requiredIndicator label="Name" placeholder="Enter your name" autoComplete="off" />
        <div className="mt-4">
          <TextField requiredIndicator label="Email" placeholder="Enter your email" autoComplete="off" />
        </div>
        <div className="mt-4">
          <TextField label="Phone" placeholder="Enter your phone number" autoComplete="off" />
        </div>
        <div className="d-flex flex-center justify-center mt-8">
          <SkeletonBodyText lines={1} />
        </div>
      </div>
      <div className="footer">
        <button className="btn-submit">{contactForm?.advance?.formSubmitButtonText}</button>
      </div>
    </ContactFormStyled>
  );
};

export default memo(ContactForm);
