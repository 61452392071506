import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const Waze = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Enter your Waze link. I.e., https://waze.com/ul/hdr5ru7vtv"
      tooltip="Enter your Waze link. I.e., https://waze.com/ul/hdr5ru7vtv"
    />
  ) : null;
};
export default memo(Waze);
