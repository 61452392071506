import { Button, List } from '@shopify/polaris';
import { useState } from 'react';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import RegularText from '../RegularText';

interface IProps {
  dataRender: { id: number | string; value: string }[];
  numberRowsDefaultDisplay: number;
  rowId: string | number;
}

const ExpandData = ({ dataRender, numberRowsDefaultDisplay, rowId }: IProps) => {
  const [expandRows, setExpandRows] = useState<(string | number)[]>([]);
  const handleToogleCollapsible = (id: number | string) => {
    let newExpandRows = [...expandRows];
    if (!newExpandRows.includes(id)) newExpandRows.push(id);
    else newExpandRows = newExpandRows.filter((rowId) => rowId !== id);
    setExpandRows(newExpandRows);
  };
  return (
    <>
      <div
        onClick={() => {
          dataRender.length > numberRowsDefaultDisplay && handleToogleCollapsible(rowId);
        }}
        style={{ cursor: dataRender.length > numberRowsDefaultDisplay ? 'pointer' : 'default' }}
      >
        <List type="bullet">
          {dataRender.slice(0, numberRowsDefaultDisplay)?.map((row) => (
            <List.Item key={row.id}>
              <div
                className={`${expandRows.includes(rowId) ? 'text-pre-line overflow-wrap-break' : 'text-ellipsis'}`}
                key={row.id}
              >
                <RegularText>{row.value}</RegularText>
              </div>
            </List.Item>
          ))}
        </List>
        {!expandRows.includes(rowId) && dataRender.length > numberRowsDefaultDisplay ? (
          <div style={{ marginLeft: '1.25rem' }}>
            <Button variant="plain">See more</Button>
          </div>
        ) : null}
      </div>
      <BaseCollapsible
        open={expandRows.includes(rowId) && dataRender.length > numberRowsDefaultDisplay}
        id="collapsible table answer"
      >
        <div
          onClick={() => {
            handleToogleCollapsible(rowId);
          }}
          className="cursor-pointer"
        >
          <List type="bullet">
            {dataRender.slice(numberRowsDefaultDisplay)?.map((row) => (
              <List.Item key={row.id}>
                <div className={`${expandRows.includes(rowId) ? 'text-pre-line overflow-wrap-break' : 'text-ellipsis'}`}>
                  <RegularText>{row.value}</RegularText>
                </div>
              </List.Item>
            ))}
          </List>
        </div>
      </BaseCollapsible>
    </>
  );
};

export default ExpandData;
