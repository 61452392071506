import { apiCaller } from '@/redux/query';

export const usePages = () => {
  const { data } = apiCaller.useGetPagesQuery();
  return {
    pageOptions:
      data?.data.map((item) => {
        return {
          value: item.handle,
          label: item.title,
        };
      }) || [],
  };
};
