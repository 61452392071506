import DatePicker from '@/components/DatePicker';
import LimitedBanner from '@/components/LimitedBanner';
import Layout from '@/components/layout';
import { PricingPlan } from '@/constants/enum';
import { dateToTimeStamp } from '@/helpers/time';
import { apiCaller } from '@/redux/query';
import dashboardSlice, { dashboardDataSelector, titleBtnDatePickerSelector } from '@/redux/slice/dashboard.slice';
import { Card, InlineGrid, Tooltip } from '@shopify/polaris';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Analytics from '../Home/components/Analytics';
import Table from '../Widgets/component/Table';
import Chart from './components/Chart';
import CustomBarChart from './components/CustomBarChart';
import CustomDonutChart from './components/CustomDonutChart';
import TableChannel from './components/TableChannel';
import { DashboardStyled } from './styled';

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(dashboardDataSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const { data: dataSetting, isLoading } = apiCaller.useGeneralSettingsQuery();
  const { data, isFetching } = apiCaller.useGetDataSummaryQuery({
    startTime: dateToTimeStamp(dashboard.startDate),
    endTime: dateToTimeStamp(dashboard.endDate),
  });

  const isLimited = useMemo(() => {
    return [PricingPlan.Free, PricingPlan.Basic].includes(dataSetting?.data?.plan || PricingPlan.Free);
  }, [dataSetting?.data?.plan]);

  return (
    <Layout title="Analytics">
      <LimitedBanner
        title="Advanced Visitor Analytics"
        content={
          <div>
            <p>
              Visitor analytics help you understand engagement, conversion rates of chat widgets, and more. Upgrade plan to
              unlock.
            </p>
            <p>You can refer to the sample data below.</p>
          </div>
        }
      />

      <DashboardStyled>
        <div className="dashboard-controller mb-8">
          <DatePicker
            titleButton={titleBtnDatePicker}
            setTitleButton={(value: string) => dispatch(dashboardSlice.actions.handleTitleBtnDatePicker(value))}
            startDate={dashboard.startDate}
            endDate={dashboard.endDate}
            onSave={(start, end) =>
              dispatch(
                dashboardSlice.actions.handleData({
                  endDate: end,
                  startDate: start,
                }),
              )
            }
            disabled={isLimited}
          />
        </div>
        {isLimited ? (
          <Tooltip content="Available on higher plan">
            <Analytics isInHomePage={false} />
          </Tooltip>
        ) : (
          <Analytics isInHomePage={false} />
        )}
        <div className={`${isLimited ? 'disabled-element' : ''} mt-16`}>
          {isLimited ? (
            <Tooltip content="Available on higher plan">
              <Chart startTime={dashboard.startDate} endTime={dashboard.endDate} />
            </Tooltip>
          ) : (
            <Chart startTime={dashboard.startDate} endTime={dashboard.endDate} />
          )}
        </div>

        {dataSetting?.data.countButtons && dataSetting?.data.countButtons > 0 && !isLoading ? (
          <InlineGrid columns={{ xs: 1, md: 2 }} gap="400">
            <div className={`${isLimited ? 'disabled-element' : ''} mt-16`}>
              {isLimited ? (
                <Tooltip content="Available on higher plan">
                  <CustomBarChart data={data} isLoading={isFetching} />
                </Tooltip>
              ) : (
                <CustomBarChart data={data} isLoading={isFetching} />
              )}
            </div>
            <div className={`${isLimited ? 'disabled-element' : ''} mt-16`}>
              {isLimited ? (
                <Tooltip content="Available on higher plan">
                  <CustomDonutChart data={data} isLoading={isFetching} />
                </Tooltip>
              ) : (
                <CustomDonutChart data={data} isLoading={isFetching} />
              )}
            </div>
          </InlineGrid>
        ) : null}

        {isLimited ? null : (
          <div className="mt-16">
            <Card padding={'400'}>
              <Table endTime={dashboard.endDate.getTime()} startTime={dashboard.startDate.getTime()} />
            </Card>
          </div>
        )}

        <div className={`${isLimited ? 'disabled-element' : ''} mt-16`}>
          <Card padding={'400'}>
            {isLimited ? (
              <Tooltip content="Available on higher plan">
                <TableChannel data={data?.data?.channelsSummary || []} isLoading={isFetching} />
              </Tooltip>
            ) : (
              <TableChannel data={data?.data?.channelsSummary || []} isLoading={isFetching} />
            )}
          </Card>
        </div>
      </DashboardStyled>
    </Layout>
  );
};
export default memo(Dashboard);
