import { BREAKPOINT } from '@/constants/enum';
import { Card, Icon, InlineGrid, Tooltip } from '@shopify/polaris';
import { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BannerTemplateStyled } from './styled';
import { XIcon } from '@shopify/polaris-icons';
interface Props {
  src: string;
  children: React.ReactNode;
  onDismiss?: () => void;
}
const BannerTemplate = ({ src, children, onDismiss }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });

  return (
    <BannerTemplateStyled>
      {onDismiss && (
        <div
          className="dismiss-btn"
          onClick={() => {
            onDismiss();
          }}
        >
          <Tooltip content="Dismiss">
            <Icon source={XIcon} tone="base" />
          </Tooltip>
        </div>
      )}
      <Card padding={'0'}>
        <InlineGrid columns={isMobile ? 1 : ['oneThird', 'twoThirds']}>
          <div className="banner-template-left">
            <img className="banner-img" src={src} alt="_blank" rel={'preload'} />
          </div>
          <div className={`banner-template-right ${isMobile ? 'pd-16' : 'pl-16 pd-16'}`}>{children}</div>
        </InlineGrid>
      </Card>
    </BannerTemplateStyled>
  );
};

export default memo(BannerTemplate);
