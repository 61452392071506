import { IconIds } from '@/constants/enum';
import { IIConProps } from '@/types/components/channels';
import { MessengerIcon } from '../Messenger';

export const MessageVariant = ({ size, fill, iconColor }: IIConProps) => {
  return [
    {
      id: IconIds.Default,
      element: <MessengerIcon fill={fill} />,
    },
    {
      id: IconIds.Icon2,
      element: (
        <svg width={size} height={size} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect className="color-element" width="39" height="39" fill={fill || 'white'}></rect>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 9.63934C0 4.29861 4.68939 0 10.4209 0C16.1524 0 20.8418 4.29861 20.8418 9.63934C20.8418 14.98 16.1524 19.2787 10.4209 19.2787C9.37878 19.2787 8.33673 19.1484 7.42487 18.8879L3.90784 20.8418V17.1945C1.56311 15.3708 0 12.6353 0 9.63934ZM8.85779 10.1604L11.463 13.0261L17.1945 6.90384L12.1143 9.76959L9.37885 6.90384L3.64734 13.0261L8.85779 10.1604Z"
            transform="translate(9.01854 10.3146)"
            fill={iconColor || '#1E88E5'}
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon3,
      element: (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 39 39">
          <rect className="color-element" width="39" height="39" fill={fill || 'white'}></rect>
          <path
            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
            fill={iconColor || '#1E88E5'}
            transform="translate(7, 8)"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon4,
      element: (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
          <rect className="color-element" width="120" height="120" fill={fill || 'white'}></rect>
          <g transform="translate(14, 12)">
            <path
              d="M84.351,68.07c2.508-4.59,3.829-9.759,3.829-15.006c0-13.118-8.112-24.36-19.575-29.021 C62.65,12.338,50.493,4.295,36.486,4.295c-19.862,0-36.021,16.159-36.021,36.021c0,6.072,1.541,12.057,4.462,17.36L0,76.059 l18.011-4.824c5.403,3.236,11.549,4.975,17.862,5.082c5.558,5.019,12.913,8.083,20.973,8.083c5.64,0,11.142-1.512,15.971-4.379 l15.878,4.254L84.351,68.07z M18.655,66.921L5.657,70.402l3.561-13.285l-0.419-0.72c-2.835-4.873-4.334-10.434-4.334-16.082 c0-17.656,14.365-32.021,32.021-32.021s32.021,14.364,32.021,32.021S54.142,72.337,36.486,72.337 c-6.066,0-11.976-1.711-17.091-4.948L18.655,66.921z M83.037,78.618l-10.861-2.91l-0.74,0.468C67.07,78.94,62.025,80.4,56.847,80.4 c-5.511,0-10.633-1.656-14.93-4.473c17.294-2.626,30.59-17.595,30.59-35.611c0-3.712-0.565-7.294-1.612-10.667 c7.947,4.786,13.286,13.48,13.286,23.415c0,4.823-1.28,9.57-3.701,13.728l-0.419,0.72L83.037,78.618z"
              fill={iconColor || 'black'}
            ></path>
          </g>
        </svg>
      ),
    },
    {
      id: IconIds.Icon5,
      element: (
        <svg
          width={size}
          height={size}
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 120 120"
        >
          <rect className="color-element" width={120} height={120} fill={fill || 'white'}></rect>;
          <g transform="translate(20, 24)" fill={iconColor || 'black'}>
            <path d="M11.902,67.18l0.021-14.51L0,52.559V8.524h64.319v44.053h-31.25L11.902,67.18z M4,48.596l11.928,0.111l-0.016,10.846 l15.911-10.976h28.496V12.524H4V48.596z"></path>
            <path d="M81.146,26.524H64.319v-18H0v44.035l11.922,0.111l-0.021,14.51L31.16,53.895v7.167h24.152l16.763,11.561l-0.018-11.495 l9.089-0.084V26.524z M4,48.596V12.524h56.319v36.053H31.823L15.912,59.553l0.016-10.846L4,48.596z M77.146,57.08l-9.095,0.084 l0.012,7.833l-11.505-7.936H35.16v-4.484h29.159V30.524h12.827C77.146,30.524,77.146,57.08,77.146,57.08z"></path>
          </g>
        </svg>
      ),
    },
    {
      id: IconIds.Icon6,
      element: (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
          <rect className="color-element" width={120} height={120} fill={fill || 'white'}></rect>;
          <g transform="translate(22, 28)" fill={iconColor || 'black'}>
            <path d="M37.666,75.318l-9.413-16.303H17c-9.374,0-17-7.626-17-17v-25c0-9.374,7.626-17,17-17h41.333c9.374,0,17,7.626,17,17v25 c0,9.374-7.626,17-17,17H47.078L37.666,75.318z M17,4.016c-7.168,0-13,5.832-13,13v25c0,7.168,5.832,13,13,13h13.563l7.103,12.302 l7.104-12.303h13.563c7.168,0,13-5.832,13-13v-25c0-7.168-5.832-13-13-13L17,4.016L17,4.016z"></path>
            <circle cx="54.822" cy="31.128" r="4.206"></circle>
            <circle cx="37.667" cy="31.128" r="4.206"></circle>
            <circle cx="20.511" cy="31.128" r="4.206"></circle>
          </g>
        </svg>
      ),
    },
    {
      id: IconIds.Icon7,
      element: (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 120">
          <rect className="color-element" width={120} height={120} fill={fill || 'white'}></rect>;
          <g transform="translate(25, 32)" fill={iconColor || 'black'}>
            <circle cx="52.46" cy="27.353" r="4.206"></circle>
            <circle cx="35.306" cy="27.353" r="4.206"></circle>
            <circle cx="18.151" cy="27.353" r="4.206"></circle>
            <path d="M13.223,67.769l0.022-15.916L0,51.728V3.247h71.015v48.5H36.448L13.223,67.769z M4,47.765l13.25,0.125l-0.017,12.252 l17.968-12.396h31.813v-40.5H4V47.765z"></path>
          </g>
        </svg>
      ),
    },
    {
      id: IconIds.Icon8,
      element: (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 700 700">
          <rect className="color-element" width={700} height={700} fill={fill || 'white'}></rect>;
          <path
            transform="translate(100, 90)"
            fill={iconColor || 'black'}
            d="M456,224V200a8,8,0,0,0-8-8h-8.18C435.61,94.24,354.78,16,256,16S76.39,94.24,72.18,192H64a8,8,0,0,0-8,8v24A48.051,48.051,0,0,0,8,272v96a48.051,48.051,0,0,0,48,48h8v16a48.051,48.051,0,0,0,48,48H216v8a8,8,0,0,0,8,8h64a8,8,0,0,0,8-8V456a8,8,0,0,0-8-8H224a8,8,0,0,0-8,8v8H112a32.036,32.036,0,0,1-32-32V416h32a8,8,0,0,0,8-8V392h4a28.031,28.031,0,0,0,28-28V276a28.031,28.031,0,0,0-28-28h-4V200c0-74.99,61.01-136,136-136s136,61.01,136,136v48h-4a28.031,28.031,0,0,0-28,28v88a28.031,28.031,0,0,0,28,28h4v16a8,8,0,0,0,8,8h56a48.051,48.051,0,0,0,48-48V272A48.051,48.051,0,0,0,456,224ZM232,464h48v16H232Zm160-88h-4a12.01,12.01,0,0,1-12-12V276a12.01,12.01,0,0,1,12-12h4Zm48-168v16H408V208ZM104,400H56a32.036,32.036,0,0,1-32-32V272a32.036,32.036,0,0,1,32-32h48Zm0-176H72V208h32Zm20,40a12.01,12.01,0,0,1,12,12v88a12.01,12.01,0,0,1-12,12h-4V264ZM256,48c-81.13,0-147.62,63.89-151.79,144H88.19C92.39,103.06,166.05,32,256,32s163.61,71.06,167.81,160H407.79C403.62,111.89,337.13,48,256,48ZM488,368a32.036,32.036,0,0,1-32,32H408V240h48a32.036,32.036,0,0,1,32,32Z"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon9,
      element: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700">
          <rect className="color-element" width={700} height={700} fill={fill || 'white'}></rect>
          <g transform="translate(80, 110)" fill={iconColor || 'black'}>
            <path d="m464 48h-320a40.045 40.045 0 0 0 -40 40v64h-56a40.045 40.045 0 0 0 -40 40v160a40.045 40.045 0 0 0 40 40h40v72a8 8 0 0 0 13.657 5.657l77.657-77.657h188.686a40.045 40.045 0 0 0 40-40v-64h56a40.045 40.045 0 0 0 40-40v-160a40.045 40.045 0 0 0 -40-40zm-72 304a24.027 24.027 0 0 1 -24 24h-192a8 8 0 0 0 -5.657 2.343l-66.343 66.343v-60.686a8 8 0 0 0 -8-8h-48a24.027 24.027 0 0 1 -24-24v-160a24.027 24.027 0 0 1 24-24h320a24.027 24.027 0 0 1 24 24zm96-104a24.027 24.027 0 0 1 -24 24h-56v-80a40.045 40.045 0 0 0 -40-40h-248v-64a24.027 24.027 0 0 1 24-24h320a24.027 24.027 0 0 1 24 24z"></path>
            <path d="m208 248a32 32 0 1 0 32 32 32.036 32.036 0 0 0 -32-32zm0 48a16 16 0 1 1 16-16 16.019 16.019 0 0 1 -16 16z"></path>
            <path d="m112 248a32 32 0 1 0 32 32 32.036 32.036 0 0 0 -32-32zm0 48a16 16 0 1 1 16-16 16.019 16.019 0 0 1 -16 16z"></path>
            <path d="m304 312a32 32 0 1 0 -32-32 32.036 32.036 0 0 0 32 32zm0-48a16 16 0 1 1 -16 16 16.019 16.019 0 0 1 16-16z"></path>
            <path d="m336 344h-72a8 8 0 0 0 0 16h72a8 8 0 0 0 0-16z"></path>
            <path d="m368 344h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z"></path>
          </g>
        </svg>
      ),
    },
    {
      id: IconIds.Icon10,
      element: (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 750 750">
          <rect className="color-element" width={750} height={750} fill={fill || 'white'}></rect>;
          <g transform="translate(130, 160)" fill={iconColor || 'black'}>
            <path d="m472 192.5h-56v-168c0-13.253906-10.746094-24-24-24h-368c-13.253906 0-24 10.746094-24 24v264c0 13.253906 10.746094 24 24 24h24v88c0 3.367188 2.109375 6.371094 5.273438 7.519531 3.167968 1.152344 6.71875.191407 8.878906-2.398437l77.601562-93.121094h52.246094v80c0 13.253906 10.746094 24 24 24h193.199219l32 52.175781c1.878906 3.03125 5.539062 4.453125 8.972656 3.484375s5.8125-4.09375 5.828125-7.660156v-48h16c13.253906 0 24-10.746094 24-24v-176c0-13.253906-10.746094-24-24-24zm-336 104c-2.375-.003906-4.628906 1.046875-6.152344 2.871094l-65.847656 79.03125v-73.902344c0-4.417969-3.582031-8-8-8h-32c-4.417969 0-8-3.582031-8-8v-264c0-4.417969 3.582031-8 8-8h368c4.417969 0 8 3.582031 8 8v168h-184c-13.253906 0-24 10.746094-24 24v80zm344 96c0 4.417969-3.582031 8-8 8h-24c-4.417969 0-8 3.582031-8 8v27.632812l-19.503906-31.808593c-1.453125-2.375-4.035156-3.820313-6.816406-3.824219h-197.679688c-4.417969 0-8-3.582031-8-8v-176c0-4.417969 3.582031-8 8-8h256c4.417969 0 8 3.582031 8 8zm0 0"></path>
            <path d="m344 280.5c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0"></path>
            <path d="m416 280.5c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0"></path>
            <path d="m272 280.5c-13.253906 0-24 10.746094-24 24s10.746094 24 24 24 24-10.746094 24-24-10.746094-24-24-24zm0 32c-4.417969 0-8-3.582031-8-8s3.582031-8 8-8 8 3.582031 8 8-3.582031 8-8 8zm0 0"></path>
          </g>
        </svg>
      ),
    },
  ];
};
