import styled from 'styled-components';
interface IProps {
  borderRadius: string;
}
export const StyledUploadedFiles = styled.div<IProps>`
  width: fit-content;
  .Polaris-DropZone__Container {
    max-width: 40px;
    max-height: 40px !important;
    display: block;
  }
  .Polaris-DropZone {
    max-width: 40px;
    max-height: 40px !important;
    min-height: 40px !important;
    &::after {
      display: none;
    }
  }
  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #030303;
    svg {
      fill: white;
    }
  }
  .upload-container,
  img {
    width: 40px;
    height: 40px;
    border-radius: ${(props) => props.borderRadius};
  }
`;

export const WidgetUploadStyled = styled.div`
  display: flex;
  align-items: center;
  .upload-container-active,
  .upload-container:hover {
    border: 2px solid #94d1ff;
  }
  svg {
    fill: white;
  }
  .Polaris-DropZone {
    margin-bottom: 5px;
    width: 100%;
    height: fit-content;
    min-height: fit-content;
    &::after {
      display: none;
    }
  }
  .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
