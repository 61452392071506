import styled from 'styled-components';

export const TableWidgetsStyled = styled.div`
  .Polaris-IndexTable__EmptySearchResultWrapper > .Polaris-Box {
    padding: 0;
  }
  .preview-icon-container {
    svg {
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }
  .name-cell {
    min-width: 120px;
    h6 {
      white-space: break-spaces;
    }
  }
`;
