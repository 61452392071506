export const WaringIcon = () => {
  return (
    <svg
      className="mr-8"
      width={20}
      height={20}
      data-v-601b36aa=""
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svg-inline=""
      role="presentation"
      focusable="false"
    >
      <path
        data-v-601b36aa=""
        d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z"
        fill="#FFC700"
        stroke="#CB9E00"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        data-v-601b36aa=""
        d="M12 9v4M12 17h.01"
        stroke="#092030"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
