import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Email = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: john@example.com"
      tooltip="Add your email address. E.g., support@premio.io"

      // expandActionSettings={
      //   <TextField
      //     placeholder="{title} and {URL} tags are supported"
      //     label="Mail subject"
      //     autoComplete="off"
      //     value={channel.advance?.emailSubject}
      //     onChange={(value) => {
      //       if (onBlur) {
      //         onBlur({
      //           ...channel,
      //           advance: {
      //             emailSubject: value,
      //           },
      //         });
      //       }
      //     }}
      //   />
      // }
    />
  ) : null;
};
export default memo(Email);
