import { LimitedBannerStyled } from '@/components/LimitedBanner/styled';
import { PricingPlan, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { Banner, Button } from '@shopify/polaris';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  content: ReactNode;
  actions?: ReactNode;
}

const LimitedBanner = ({ title, content, actions }: IProps) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();
  return [PricingPlan.Free, PricingPlan.Basic].includes(dataSetting?.data?.plan || PricingPlan.Free) && show ? (
    <LimitedBannerStyled className="mt-8 mb-16">
      <Banner
        title={title}
        onDismiss={() => {
          setShow(false);
        }}
        tone="info"
      >
        {content}
        <div className="mt-8">
          {actions || (
            <Button
              onClick={() => {
                navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
              }}
              variant="primary"
            >
              Upgrade now
            </Button>
          )}
        </div>
      </Banner>
    </LimitedBannerStyled>
  ) : null;
};

export default LimitedBanner;
