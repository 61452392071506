import styled from 'styled-components';

interface IProps {
  bottom: any;
}

export const WindowStyled = styled.div<IProps>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fadeIn 1000ms forwards;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  width: 50%;
  min-width: 220px;
  z-index: 1;

  .Polaris-Text--root,
  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 80%;
  }
`;
