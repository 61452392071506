import { colors } from '@/constants/colors';
import styled from 'styled-components';

export const NewWidgetStyled = styled.div`
  .pd-16 {
    padding: 16px;
  }
  .welcome-underline {
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
  .progress-control-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .welcome-process-wrapper {
    position: sticky;
    background-color: white;
    padding: 1rem;
    z-index: 32;
    border-radius: 12px;
  }
  .welcome-process-container {
    height: 0.75rem;
    background-color: ${colors.gray_background};
    border-radius: 0.375rem;
    overflow: hidden;
  }
  .welcome-process {
    background-color: #1a1a1a;
    height: 100%;
  }
  a {
    text-decoration: none;
    &:active {
      color: #49687e;
    }
  }
  .center {
    color: #49687e;
    flex: 1;
    .container {
      width: 100%;
    }

    .progress {
      cursor: pointer;
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      z-index: 3;
      border-radius: 8px;
      padding: 5px 0;
      &:hover {
        background-color: rgba(26, 26, 26, 0.17);
      }
    }
    .progress span {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-bottom: 0.125rem;
      border: 5px solid #fff;
      border-radius: 50%;
      background-color: #1a1a1a;
    }
    .progress.active span {
      background-color: #1a1a1a;
    }
    .progress.active div {
      color: #1a1a1a;
    }
  }
  .custom-card-header {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eaeff2;
    .Polaris-Text--root.Polaris-Text--headingMd {
      width: 100%;
    }
  }
`;

interface IPositionIcon {
  marginRight?: string;
  marginBottom?: string;
  groupColor?: string;
  scale: number;
}

export const ClientStyled = styled.div<IPositionIcon>`
  margin-top: 1rem;
  @keyframes hover {
    from {
      transform: scale(1.15);
    }
    to {
      transform: scale(1);
    }
  }
  .icon-close {
    display: flex;
    fill: ${(props) => props.groupColor};
  }
  .icon-button-container {
    flex-wrap: wrap;
    display: flex;
    margin-top: 20px;
    svg {
      box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    }
  }
  .icon-container {
    transition: opacity 0.3s ease;
    svg {
      border-radius: 50%;
    }
    cursor: pointer;
    svg {
      width: 48px;
      height: 48px;
    }
    &.disabled-select {
      cursor: not-allowed !important;
    }
  }

  .text-container {
    text-align: center;
    font-size: 12px;
  }

  div[class='channel-container-select']:hover {
    animation: hover 0.4s ease-in-out;
    .add-custom-container {
      background-color: #030303;
    }
  }
  .channel-container-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    transition: border-color 0.3s linear;
    margin-bottom: 8px;
    &.disabled-select {
    }
  }
  .selected-icon-container {
    border: 1px solid transparent;
    transition: border-color 0.3s;
    border-radius: 12px;
    margin-top: 8px;
  }

  .selected-icon-container:hover {
    border-color: rgb(188 188 188);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px 0px;
  }

  .text-field label {
    margin: 10px 10px 10px 0px;
  }
  .text-field {
    width: 400px;
  }

  .custom-link {
    padding-top: 20px;
    margin-left: 30px;
  }
  /* CSS */
  @media only screen and (max-width: 545px) {
    .progress-control-container {
      flex-direction: column;
      gap: 0.5rem;
    }
  }
`;
