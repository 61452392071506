/* eslint-disable jsx-a11y/anchor-is-valid */
import CriticalBanner from '@/components/CriticalBanner';
import { borderConfig } from '@/components/Icons/IconBorder';
import { WaringIcon } from '@/components/Icons/Waring';
import Preview from '@/components/Preview/Preview';
import RegularText from '@/components/RegularText';
import SkeletonPage from '@/components/SkeletonPage';
import Layout from '@/components/layout';
import { config } from '@/config';
import { BREAKPOINT, Border, Device, ICon, IconIds, WorkingDayType } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import widgetSlice, {
  channelsSeletedSelector,
  customizeSelected,
  dataBackupSelector,
  dataSelector,
  errorSelector,
} from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { IChannelState } from '@/types/components/widget';
import { Button, Card, InlineGrid, Modal, Text, TextProps } from '@shopify/polaris';
import { ArrowLeftIcon, ArrowRightIcon } from '@shopify/polaris-icons';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import StepFour from './StepFour';
import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';
import { ClientStyled, NewWidgetStyled } from './styled';

const NewWidgets = () => {
  const error = useSelector(errorSelector);
  const channelsSelected = useSelector(channelsSeletedSelector);
  const [modalDiscard, setModalDiscard] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const groupButtonDetail = apiCaller.useGroupButtonDetailQuery({ id: Number(id) || -1 }, { skip: !id });
  const dispatch = useDispatch();
  const [createGroupButton, createGroupButtonStatus] = apiCaller.useCreateGroupButtonMutation();
  const [updateGroupButton, updateGroupButtonStatus] = apiCaller.useUpdateGroupButtonMutation();
  const data = useSelector(dataSelector);
  const dataBackup = useSelector(dataBackupSelector);
  const customize = useSelector(customizeSelected);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const isVerrySmallDisplay = useMediaQuery({ maxWidth: 420 });
  const [progress, setProgress] = useState(0);
  const stepConfig = [
    {
      label: 'Channels',
      component: <StepOne />,
      alignment: 'start',
    },
    {
      label: 'Configuration',
      component: <StepTwo />,
      alignment: 'center',
    },
    {
      label: 'Style',
      component: <StepThree />,
      alignment: 'center',
    },
    {
      label: 'Triggers and Targeting',
      component: <StepFour />,
      alignment: 'end',
    },
  ];

  const handleChangeStep = (value: number) => () => {
    setProgress(value);
  };

  const handleSave = () => {
    const listInvalidateBtns = [ICon.HomePage, ICon.CartPage, ICon.ScrollToTop];
    const contactForm = channelsSelected.find((item) => item.channel === ICon.ContactForm);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail =
      !contactForm ||
      !contactForm?.value ||
      (contactForm && (!contactForm.value || (contactForm.value && emailRegex.test(String(contactForm.value).toLowerCase()))));
    if (data.channelsSelected.length === 0) {
      dispatch(
        widgetSlice.actions.handleError({
          status: true,
          field: 'buttons',
        }),
      );
      setProgress(0);
    } else if (
      channelsSelected.find(
        (item) =>
          ((!contactForm && !item.value) || (contactForm && contactForm.value && !isValidEmail)) &&
          !listInvalidateBtns.includes(item.channel),
      )
    ) {
      let errors = channelsSelected
        .filter((item) => !item.value && !listInvalidateBtns.includes(item.channel))
        .map((item) => (item.channel !== ICon.CustomLink ? item.channel : ((item.channel + ` ${item.customLinkId}`) as ICon)));
      if (!isValidEmail && !errors.includes(ICon.ContactForm)) errors.push(ICon.ContactForm);
      dispatch(
        widgetSlice.actions.handleError({
          status: errors.length > 0,
          icon: errors,
        }),
      );
      setProgress(1);
    } else if (!data.name) {
      dispatch(
        widgetSlice.actions.handleError({
          status: true,
          field: 'name',
        }),
      );
      setProgress(0);
    } else {
      const action = id ? updateGroupButton : createGroupButton;
      const { customize } = data;
      const { trigger } = data;
      const groupButton: IParamsApi.ICreateGroupButton = {
        id: id ? Number(id) : undefined,
        name: data.name,
        size: customize.widgetSize,
        bottomSpacing: customize.bottomSpacing,
        buttonDirection: customize.buttonDirection,
        closeHoverText: customize.closeHoverText,
        groupIconColor: customize.groupIconColor,
        buttons: data.channelsSelected.map((item) => {
          const newFormFields = item.formFields?.map((field) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { customId, ...rest } = field;
            return rest;
          });
          const newAgents = item?.agents?.map((agent) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { customId, ...rest } = agent;
            return rest;
          });
          return {
            ...item,
            id: id ? item.id : undefined,
            formFields: newFormFields,
            agents: newAgents,
            customImage: item.iconId === IconIds.Custom ? item.customImage?.file || item.customImage?.url : null,
            iconId: item.customImage?.file ? IconIds.Custom : item.iconId,
            isDesktop: item.showOn.includes(Device.Pc),
            isMobile: item.showOn.includes(Device.Mobile),
            advance: {
              ...item.advance,
              whatsappProfileImage: item.advance?.whatsappProfileImage?.file || item.advance?.whatsappProfileImage?.url,
              weChatQr: item.advance?.weChatQr?.file,
              messengerProfileImage: item.advance?.messengerProfileImage?.file || item.advance?.messengerProfileImage?.file,
            },
          };
        }),
        ctaBackground: customize.ctaBackground,
        ctaBehavior: customize.ctaBehavior,
        ctaText: customize.ctaText,
        ctaTextColor: customize.ctaTextColor,
        groupColor: customize.groupColor,
        groupIcon: customize.groupIcon.file || customize.groupIcon.url,
        groupIconId: customize.groupIcon.key,
        isActive: trigger.active,
        openAction: customize.openAction,
        customCss: customize.customCss,

        renderDelayPercentPage: Number(trigger.displayAfterScrollValue),
        renderDelaySeconds: Number(trigger.displayAfterSecondValue),
        rules: trigger.targetRules,
        sideSpacing: customize.sideSpacing,
        buttonStyle: borderConfig.find((item) => item.border === customize.buttonStyle)?.value || Border.Circle,
        startWorkingTime: trigger.startWorkingTime,
        endWorkingTime: trigger.endWorkingTime,
        workingDays: trigger.workingDays.map((item) => Number(item)),
        timezone: trigger.timezone,
        isUseWorkingTime: trigger.isUseWorkingTime === WorkingDayType.CustomDay,
        pageTargetType: trigger.pageTargetType,
        excludePages: trigger.excludePages,
        specificPages: trigger.specificPages,
      };
      action(groupButton).then((res) => {
        dispatch(widgetSlice.actions.handleDataBackup(data));
        const error = checkShowErrorInline(res);
        if (!error.status) {
          dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
          navigate(PATH.WIDGETS);
        } else {
          dispatch(widgetSlice.actions.handleError({ ...error, field: 'name' }));
          setProgress(0);
          window.scrollTo(0, 0);
        }
      });
    }
  };

  useEffect(() => {
    if (id && groupButtonDetail.data) {
      const details = groupButtonDetail.data.data;
      const initData: IChannelState = {
        channelsSelected: details.buttons.map((button) => {
          const showOn: Device[] = [];
          if (button.isDesktop) showOn.push(Device.Pc);
          if (button.isMobile) showOn.push(Device.Mobile);
          return {
            ...button,
            showOn,
            customLinkId: button.channel === ICon.CustomLink ? Number(button.id) : undefined,
            customImage: {
              file: undefined,
              url: button.customImage || undefined,
            },
            advance: {
              ...button.advance,
              whatsappProfileImage: {
                url: button.advance?.whatsappProfileImage,
              },
              weChatQr: {
                url: button.advance?.weChatQr,
              },
              messengerProfileImage: {
                url: button.advance?.messengerProfileImage,
              },
            },
          };
        }),
        customize: {
          buttonStyle: borderConfig.find((item) => item.value === details.buttonStyle)?.border || 'none',
          bottomSpacing: details.bottomSpacing,
          buttonDirection: details.buttonDirection,
          ctaBackground: details.ctaBackground,
          ctaBehavior: details.ctaBehavior,
          ctaText: details.ctaText,
          ctaTextColor: details.ctaTextColor,
          groupColor: details.groupColor,
          groupIcon: {
            key: details.groupIconId,
            file: undefined,
            url: details.groupIcon,
          },
          openAction: details.openAction,
          sideSpacing: details.sideSpacing,
          widgetSize: details.size,
          customCss: details.customCss,
          closeHoverText: details.closeHoverText,
          groupIconColor: details.groupIconColor,
        },
        name: details.name,
        trigger: {
          active: details.isActive,
          displayAfterScrollValue: details.renderDelayPercentPage ? details.renderDelayPercentPage.toString() : '0',
          displayAfterSecondValue: details.renderDelaySeconds ? details.renderDelaySeconds.toString() : '0',
          startWorkingTime: details.startWorkingTime,
          endWorkingTime: details.endWorkingTime,
          workingDays: details.workingDays ? details.workingDays.split(',').map(Number) : [],
          targetRules: details.rules,
          timezone: details.timezone,
          isUseWorkingTime: details.isUseWorkingTime ? WorkingDayType.CustomDay : WorkingDayType.AllDay,
          pageTargetType: details.pageTargetType,
          excludePages: details.excludePages,
          specificPages: details.specificPages,
        },
      };
      dispatch(widgetSlice.actions.handleData(initData));
      dispatch(widgetSlice.actions.handleDataBackup(initData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(groupButtonDetail.data), id]);

  const handleDiscard = () => {
    dispatch(widgetSlice.actions.handleData(dataBackup));
    setModalDiscard(false);
  };

  useEffect(() => {
    dispatch(widgetSlice.actions.handleWindow(undefined));
  }, [dispatch]);

  return (
    <Layout title={id ? 'Edit widget' : 'Create widget'} backAction={{ onAction: () => navigate(PATH.WIDGETS) }}>
      {groupButtonDetail.isLoading ? (
        <SkeletonPage />
      ) : (
        <NewWidgetStyled>
          <Card padding={'0'}>
            <CriticalBanner isVisible={(error.status && error.field === 'buttons') || (error.field === 'name' && !!error.msg)}>
              {error.field === 'name' && !!error.msg ? 'Widget name is already existed' : 'You must select at least one channel'}
            </CriticalBanner>
          </Card>

          <ClientStyled scale={customize.widgetSize} groupColor={customize.groupColor}>
            <InlineGrid columns={isMobile ? 1 : ['twoThirds', 'oneThird']} gap={'400'}>
              <Card padding={'0'}>
                <div className="welcome-process-wrapper" style={{ top: config.embedded !== '1' ? '28px' : '0px' }}>
                  <div className="progress-control-container">
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                      <Button
                        icon={ArrowLeftIcon}
                        disabled={progress === 0}
                        onClick={() => {
                          setProgress(progress - 1);
                        }}
                      >
                        Previous
                      </Button>
                      <Button
                        icon={ArrowRightIcon}
                        onClick={() => {
                          setProgress(progress + 1);
                        }}
                        disabled={progress === 3}
                      >
                        Next
                      </Button>
                    </div>
                    <div className="d-flex">
                      <div className="mr-8">
                        <Button
                          onClick={() => {
                            navigate(PATH.WIDGETS);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <Button
                        loading={createGroupButtonStatus.isLoading || updateGroupButtonStatus.isLoading}
                        onClick={handleSave}
                        variant="primary"
                      >
                        Save & Exit
                      </Button>
                    </div>
                  </div>
                  <div className="welcome-process-container mt-16">
                    <div className="welcome-process" style={{ maxWidth: `${(progress + 1) * 25}%`, transition: 'all .5s' }} />
                  </div>
                  <div className="welcome-underline flex-wrap">
                    {stepConfig.map(({ label, alignment }, index) => {
                      return (
                        <div
                          key={index}
                          onClick={handleChangeStep(index)}
                          className={`mt-8 d-flex ${isVerrySmallDisplay ? 'w-50' : ''}`}
                        >
                          {((data.channelsSelected.length === 0 || !data.name) && index === 0) ||
                          (index === 1 && error.icon?.length) ? (
                            <WaringIcon />
                          ) : null}
                          <Text
                            as="span"
                            variant={index === progress ? 'headingMd' : 'bodyMd'}
                            alignment={alignment as TextProps['alignment']}
                          >
                            {label}
                          </Text>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="pd-16">{stepConfig[progress].component}</div>
              </Card>
              <Preview step={progress + 1} />
            </InlineGrid>
          </ClientStyled>
          <Modal
            open={modalDiscard}
            onClose={() => setModalDiscard(false)}
            title={'Discard all unsaved changes'}
            primaryAction={{
              destructive: true,
              content: 'Discard changes',
              onAction: handleDiscard,
            }}
            secondaryActions={[
              {
                content: 'Continue editing',
                onAction: () => setModalDiscard(false),
              },
            ]}
          >
            <Modal.Section>
              <RegularText>If you discard changes, you’ll delete any edits you made since you last saved.</RegularText>
            </Modal.Section>
          </Modal>
        </NewWidgetStyled>
      )}
    </Layout>
  );
};
export default memo(NewWidgets);
