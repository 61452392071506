import { getSomeDaysAgo } from '@/helpers/time';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IDashboardData {
  startDate: Date;
  endDate: Date;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  dashboardData: IDashboardData;
  titleBtnDatePicker: string;
} = {
  dashboardData: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
  },
  titleBtnDatePicker: 'Last 7 days',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleData: (state, action: PayloadAction<IDashboardData>) => {
      state.dashboardData = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type

export const dashboardDataSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.dashboardData,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.dashboard,
  (state) => state.titleBtnDatePicker,
);

export default dashboardSlice;
