import { IconIds } from '@/constants/enum';
import { IIConProps } from '@/types/components/channels';
import { ScrollIcon } from '../ScrollTop';

export const ScrollTopVariant = ({ size, fill, iconColor }: IIConProps) => {
  return [
    {
      id: IconIds.Default,
      element: <ScrollIcon fill={fill} />,
    },
    {
      id: IconIds.Icon2,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            fill={iconColor || '#000'}
            transform="translate(208, 176)"
            d="M35.5 248l148-148.5c4.7-4.7 12.3-4.7 17 0l148 148.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L209 156.1V468c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12V156.1L59.6 272c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.6-4.7-12.2 0-16.9zM0 44v8c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44z"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon3,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            transform="translate(179, 120)"
            fill={iconColor || '#000'}
            d="M6.101 359.293L25.9 379.092c4.686 4.686 12.284 4.686 16.971 0L224 198.393l181.13 180.698c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 132.908c-4.686-4.686-12.284-4.686-16.971 0L6.101 342.322c-4.687 4.687-4.687 12.285 0 16.971z"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon4,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            transform="translate(185, 125)"
            fill={iconColor || '#000'}
            d="M180.573 448C169.211 448 160 438.789 160 427.428V255.991H43.021c-7.125 0-10.695-8.612-5.66-13.653L209.444 70.035c8.036-8.046 21.076-8.047 29.112 0L410.64 242.338c5.035 5.041 1.464 13.653-5.66 13.653H288v171.437C288 438.79 278.789 448 267.427 448h-86.854m0 32h86.855C296.416 480 320 456.416 320 427.428V287.991h84.979c35.507 0 53.497-43.04 28.302-68.266L261.198 47.422c-20.55-20.576-53.842-20.58-74.396 0L14.719 219.724c-25.091 25.122-7.351 68.266 28.302 68.266H128v139.437C128 456.416 151.584 480 180.573 480z"
          ></path>
        </svg>
      ),
    },
  ];
};
