import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const TikTok = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: @Tiktok_username"
      tooltip="Handle for the TikTok account (part of the web page address). For 'tiktok.com/@example ' the handle is '@example'"
    />
  ) : null;
};
export default memo(TikTok);
