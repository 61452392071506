import { ICon } from '@/constants/enum';
import { IParamsApi } from '@/types/apis/params';
import { ICustomChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
interface IProps extends ICustomChanelProps {
  channel: IParamsApi.IChannelButton;
}

const CustomLink = ({ channel, keyValue }: IProps) => {
  return channel ? (
    <ChannelsButton
      customId={channel.customLinkId}
      key={`${ICon.CustomLink} ${channel.customLinkId}`}
      keyValue={keyValue}
      placeholder="Example: https://www.example.com/contact"
    />
  ) : null;
};
export default memo(CustomLink);
