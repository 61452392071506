import SkeletonPage from '@/components/SkeletonPage';
import Layout from '@/components/layout';
import { PricingPlan as PricingPlanEnum, Subscription } from '@/constants/enum';
import { pricings } from '@/constants/pricing';
import PricingCard from '@/pages/PricingPlan/components/PricingCard';
import { PricingPlanStyled } from '@/pages/PricingPlan/styled';
import { apiCaller } from '@/redux/query';
import { IResponseApi } from '@/types/apis/response';
import { Button, InlineGrid } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PricingPlan = () => {
  const [subscription, setSubscription] = useState<Subscription>(Subscription.Monthly);
  const [pricingData, setPricingData] = useState<IResponseApi.IAppPricing[]>([]);
  const [freePlan, setFreePlan] = useState<IResponseApi.IAppPricing | undefined>();
  const location = useLocation();
  const { data, isLoading } = apiCaller.useGetAppPricingQuery();
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();

  useEffect(() => {
    if (dataSetting?.data?.plan === PricingPlanEnum.Free) setSubscription(Subscription.Monthly);
    else if (dataSetting?.data?.subscription || location.state)
      setSubscription(location.state || dataSetting?.data?.subscription);
  }, [dataSetting?.data?.plan, dataSetting?.data?.subscription, location.state]);

  useEffect(() => {
    const newPricingData: IResponseApi.IAppPricing[] = new Array(3);

    data?.data?.forEach((plan) => {
      switch (plan.plan) {
        case PricingPlanEnum.Free: {
          setFreePlan(plan);
          break;
        }
        case PricingPlanEnum.Basic: {
          newPricingData[0] = plan;
          break;
        }
        case PricingPlanEnum.Pro: {
          newPricingData[1] = plan;
          break;
        }
        case PricingPlanEnum.Premium: {
          newPricingData[2] = plan;
          break;
        }
        default:
          break;
      }
    });
    setPricingData(newPricingData);
  }, [data?.data]);

  return (
    <Layout title="Subscription plan">
      <PricingPlanStyled>
        <div className="d-flex justify-center">
          <div className="flex-center wrap-content-top">
            <Button
              variant={subscription === Subscription.Monthly ? 'primary' : 'tertiary'}
              onClick={() => setSubscription(Subscription.Monthly)}
            >
              Monthly
            </Button>
            <div className="ml-8">
              <Button
                onClick={() => setSubscription(Subscription.Annualy)}
                variant={subscription === Subscription.Annualy ? 'primary' : 'tertiary'}
              >
                Yearly
              </Button>
            </div>
          </div>
        </div>
        <div className="d-flex justify-center mt-16 mb-16">{/* <Button variant="plain">Apply your discount code</Button> */}</div>
        {isLoading ? (
          <SkeletonPage />
        ) : pricingData.length ? (
          <div className="d-flex flex-column">
            <InlineGrid gap="200" columns={{ xs: 1, md: 3 }}>
              {pricingData.map((plan) => (
                <PricingCard
                  key={plan.plan}
                  tag={plan?.tag}
                  subscription={subscription}
                  pricing={plan?.price || 0}
                  title={plan?.displayName || ''}
                  discount={subscription === Subscription.Annualy ? plan?.annuallyDiscount : 0}
                  features={pricings[plan.plan]}
                  description={
                    (subscription === Subscription.Annualy ? plan?.annuallyDescription : plan?.monthlyDescription) ||
                    pricings.subTitle[plan.plan]
                  }
                  plan={plan.plan}
                  selected={dataSetting?.data?.plan === plan.plan && dataSetting?.data?.subscription === subscription}
                  displayPrice={(subscription === Subscription.Annualy ? plan?.annuallyMonthlyDisplayPrice : plan?.price) || 0}
                  trialDays={plan?.trialDays}
                />
              ))}
            </InlineGrid>
            {freePlan ? (
              <div className="mt-8">
                <PricingCard
                  isCardHorizontal
                  key={freePlan?.plan}
                  tag={freePlan?.tag}
                  subscription={subscription}
                  pricing={freePlan?.price || 0}
                  title={'Experience Omega Chat completely for FREE with'}
                  discount={subscription === Subscription.Annualy ? freePlan?.annuallyDiscount : 0}
                  features={pricings[freePlan?.plan]}
                  description={
                    (subscription === Subscription.Annualy ? freePlan?.annuallyDescription : freePlan?.monthlyDescription) ||
                    pricings.subTitle[freePlan?.plan]
                  }
                  plan={freePlan?.plan}
                  selected={dataSetting?.data?.plan === freePlan?.plan && dataSetting?.data?.subscription === subscription}
                  displayPrice={
                    (subscription === Subscription.Annualy ? freePlan?.annuallyMonthlyDisplayPrice : freePlan?.price) || 0
                  }
                  trialDays={freePlan?.trialDays}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </PricingPlanStyled>
    </Layout>
  );
};

export default PricingPlan;
