import { Card, IndexTable, Pagination, SkeletonBodyText } from '@shopify/polaris';
import BoldText from '../BoldText';
import { images } from '@/asset';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { BaseTableStyled } from './styled';

interface IProps {
  itemCount: number;
  headings: NonEmptyArray<IndexTableHeading>;
  isLoading: boolean;
  isFetching?: boolean;
  page?: number;
  onChangePage?: (value: number) => void;
  rowMarkup?: (JSX.Element | null)[];
  totalOfAllItems?: number;
  totalPages?: number;
  style?: React.CSSProperties;
}

const BaseTable = ({
  headings,
  isLoading,
  itemCount,
  rowMarkup,
  onChangePage,
  page,
  totalOfAllItems,
  isFetching,
  totalPages,
  style,
}: IProps) => {
  const PERPAGE = 10;
  return (
    <BaseTableStyled style={style}>
      {isLoading ? (
        <Card>
          <SkeletonBodyText lines={8} />
        </Card>
      ) : (
        <>
          <IndexTable
            emptyState={
              <div className="d-flex flex-center justify-center f-wrap">
                <img src={images.emptyState} alt="No data" />
                <div className="w-full text-center">
                  <BoldText>No data to display</BoldText>
                </div>
              </div>
            }
            itemCount={itemCount}
            headings={headings}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
          {page !== undefined && totalPages && totalOfAllItems && totalOfAllItems > PERPAGE ? (
            <div className="py-8 paginate">
              <Pagination
                label={
                  itemCount
                    ? `Showing ${page * PERPAGE + 1} to ${
                        PERPAGE + page * PERPAGE < totalOfAllItems ? PERPAGE + page * PERPAGE : totalOfAllItems
                      } of ${totalOfAllItems} items`
                    : null
                }
                hasPrevious={!isFetching && page > 0}
                onPrevious={() => {
                  onChangePage?.(page - 1);
                }}
                hasNext={!isFetching && page < totalPages - 1}
                onNext={() => {
                  onChangePage?.(page + 1);
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </BaseTableStyled>
  );
};

export default BaseTable;
