import styled from 'styled-components';

export const AgentCardStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  width: 100%;
  border: 3px solid rgb(221, 221, 221);
  border-radius: 6px;
  cursor: pointer;
  .Polaris-Tag {
    border-radius: 6px;
    color: white;
    background-color: rgb(110, 204, 239);
    max-width: 60px;
    width: fit-content;
    .job-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .wrap-role {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
