import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const EmailIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    // <svg width={scale} height={scale} color={fill || 'red'} viewBox={`0 0 40 40`} fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <circle className="color-element" cx={20} cy={20} r={20} fill={fill || 'red'}></circle>
    //   <path
    //     d="M20.5379 14.2557H1.36919C0.547677 14.2557 0 13.7373 0 12.9597V1.29597C0 0.518387 0.547677 0 1.36919 0H20.5379C21.3594 0 21.9071 0.518387 21.9071 1.29597V12.9597C21.9071 13.7373 21.3594 14.2557 20.5379 14.2557ZM20.5379 12.9597V13.6077V12.9597ZM1.36919 1.29597V12.9597H20.5379V1.29597H1.36919Z"
    //     transform="translate(8.48619 12.3117)"
    //     fill="white"
    //   ></path>
    //   <path
    //     d="M10.9659 8.43548C10.829 8.43548 10.692 8.43548 10.5551 8.30588L0.286184 1.17806C0.012346 0.918864 -0.124573 0.530073 0.149265 0.270879C0.423104 0.0116857 0.833862 -0.117911 1.1077 0.141283L10.9659 7.00991L20.8241 0.141283C21.0979 -0.117911 21.5087 0.0116857 21.7825 0.270879C22.0563 0.530073 21.9194 0.918864 21.6456 1.17806L11.3766 8.30588C11.2397 8.43548 11.1028 8.43548 10.9659 8.43548Z"
    //     transform="translate(8.47443 12.9478)"
    //     fill="white"
    //   ></path>
    //   <path
    //     d="M9.0906 7.13951C8.95368 7.13951 8.81676 7.13951 8.67984 7.00991L0.327768 1.17806C-0.0829894 0.918864 -0.0829899 0.530073 0.190849 0.270879C0.327768 0.0116855 0.738525 -0.117911 1.14928 0.141282L9.50136 5.97314C9.7752 6.23233 9.91212 6.62112 9.63828 6.88032C9.50136 7.00991 9.36444 7.13951 9.0906 7.13951Z"
    //     transform="translate(20.6183 18.7799)"
    //     fill="white"
    //   ></path>
    //   <path
    //     d="M0.696942 7.13951C0.423104 7.13951 0.286185 7.00991 0.149265 6.88032C-0.124573 6.62112 0.012346 6.23233 0.286185 5.97314L8.63826 0.141282C9.04902 -0.117911 9.45977 0.0116855 9.59669 0.270879C9.87053 0.530073 9.73361 0.918864 9.45977 1.17806L1.1077 7.00991C0.970781 7.13951 0.833862 7.13951 0.696942 7.13951Z"
    //     transform="translate(8.47443 18.7799)"
    //     fill="white"
    //   ></path>
    // </svg>
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#FF485F'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39" fill={fill || '#FF485F'}></rect>
      <path
        d="M20.5379 14.2557H1.36919C0.547677 14.2557 0 13.7373 0 12.9597V1.29597C0 0.518387 0.547677 0 1.36919 0H20.5379C21.3594 0 21.9071 0.518387 21.9071 1.29597V12.9597C21.9071 13.7373 21.3594 14.2557 20.5379 14.2557ZM20.5379 12.9597V13.6077V12.9597ZM1.36919 1.29597V12.9597H20.5379V1.29597H1.36919Z"
        transform="translate(8.48619 12.3117)"
        fill={iconColor || 'white'}
      ></path>
      <path
        d="M10.9659 8.43548C10.829 8.43548 10.692 8.43548 10.5551 8.30588L0.286184 1.17806C0.012346 0.918864 -0.124573 0.530073 0.149265 0.270879C0.423104 0.0116857 0.833862 -0.117911 1.1077 0.141283L10.9659 7.00991L20.8241 0.141283C21.0979 -0.117911 21.5087 0.0116857 21.7825 0.270879C22.0563 0.530073 21.9194 0.918864 21.6456 1.17806L11.3766 8.30588C11.2397 8.43548 11.1028 8.43548 10.9659 8.43548Z"
        transform="translate(8.47443 12.9478)"
        fill={iconColor || 'white'}
      ></path>
      <path
        d="M9.0906 7.13951C8.95368 7.13951 8.81676 7.13951 8.67984 7.00991L0.327768 1.17806C-0.0829894 0.918864 -0.0829899 0.530073 0.190849 0.270879C0.327768 0.0116855 0.738525 -0.117911 1.14928 0.141282L9.50136 5.97314C9.7752 6.23233 9.91212 6.62112 9.63828 6.88032C9.50136 7.00991 9.36444 7.13951 9.0906 7.13951Z"
        transform="translate(20.6183 18.7799)"
        fill={iconColor || 'white'}
      ></path>
      <path
        d="M0.696942 7.13951C0.423104 7.13951 0.286185 7.00991 0.149265 6.88032C-0.124573 6.62112 0.012346 6.23233 0.286185 5.97314L8.63826 0.141282C9.04902 -0.117911 9.45977 0.0116855 9.59669 0.270879C9.87053 0.530073 9.73361 0.918864 9.45977 1.17806L1.1077 7.00991C0.970781 7.13951 0.833862 7.13951 0.696942 7.13951Z"
        transform="translate(8.47443 18.7799)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
