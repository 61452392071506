import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const LinkedinIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#0077b5'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39" fill={fill || '#0077b5'}></rect>
      <path
        id="LinkedIn_color"
        d="M18,20.1v6.655H14.142V20.549c0-1.56-.558-2.624-1.954-2.624a2.111,2.111,0,0,0-1.98,1.411,2.64,2.64,0,0,0-.128.941v6.481H6.221s.052-10.516,0-11.606H10.08V16.8c-.008.012-.018.026-.025.037h.025V16.8a3.832,3.832,0,0,1,3.478-1.918C16.1,14.88,18,16.539,18,20.1ZM2.184,9.558a2.011,2.011,0,1,0-.051,4.011h.026a2.012,2.012,0,1,0,.025-4.011ZM.229,26.758H4.087V15.152H.229Z"
        transform="translate(11 1.442)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
