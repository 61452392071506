import { colors } from '@/constants/colors';
import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const HomeStyled = styled.div`
  .dashboard-img {
    img {
      width: 230px;
      @media screen and (max-width: ${BREAKPOINT.SM}px) {
        height: auto;
        width: 60%;
      }
    }
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    @media screen and (max-width: ${BREAKPOINT.SM}px) {
      justify-content: center;
      padding-right: 0rem;
    }
  }
  .action-item-container {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
    &:hover {
      background-color: ${colors.bgHover};
    }
  }
  .apps-title {
    display: flex;
    justify-content: space-between;
  }
  .apps-wrapper {
    .recommended-apps-container {
      display: flex;
      .recommend-app-logo {
        width: 50px;
        height: 50px;
        border-radius: 0.5rem;
      }
      .recommend-app-content {
        width: 100%;
        .Polaris-Text--root {
          line-height: 1.25rem;
        }
        .Polaris-Icon {
          svg {
            stroke: yellow;
            opacity: 0.6;
          }
          margin: 0;
          margin-right: 0.25rem;
        }
        margin-left: 0.5rem;
      }
    }
    .recommend-app-banner {
      margin-top: 1rem;
      width: 100%;
    }
    .recommend-app-btn {
      padding: 16px 54px;
    }
    .Polaris-ShadowBevel {
      height: 100%;
      .Polaris-Box {
        display: flex;
      }
    }
  }
  .recommend-apps-controller {
    display: flex;
    cursor: pointer;
  }
  .homepage-help-center {
    padding: 1rem;
    background-color: #ebebeb;
    .Polaris-Link {
      display: flex;
      margin-bottom: 0.5rem;
    }
  }

  .bfs-badge {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    font-weight: 500;
    padding: 4px 8px 4px 8px;
    background-color: rgb(224, 240, 255);
    border-radius: 4px;
    gap: 4px;
  }
`;
