import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const VkontakteIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#5076AA'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39"></rect>
      <path
        d="M11.0953 12.4775H12.5097C12.5097 12.4775 12.934 12.4774 13.0755 12.1971C13.2169 12.0569 13.2169 11.6363 13.2169 11.6363C13.2169 11.6363 13.2169 9.81373 14.0656 9.53334C14.9142 9.25294 15.9043 11.2157 17.0359 12.0569C17.8845 12.6176 18.5917 12.4775 18.5917 12.4775H21.562C21.562 12.4775 23.1178 12.3373 22.4106 11.2157C22.4106 11.0755 21.9863 10.2343 20.1476 8.69216C18.3088 7.0098 18.4503 7.29019 20.8548 4.20588C22.2692 2.38333 22.835 1.26176 22.6935 0.700978C22.5521 0.28039 21.562 0.42059 21.562 0.42059H18.1674C18.1674 0.42059 17.8845 0.420588 17.7431 0.560784C17.6016 0.70098 17.4602 0.981371 17.4602 0.981371C17.4602 0.981371 16.8944 2.38333 16.1872 3.6451C14.6313 6.16863 14.0656 6.30882 13.7827 6.16863C13.2169 5.74804 13.3584 4.62647 13.3584 3.92549C13.3584 1.40196 13.7827 0.420589 12.6512 0.140197C12.2268 1.06543e-06 11.944 -2.13922e-06 11.0953 -2.13922e-06C9.82233 -2.13922e-06 8.83224 -1.06543e-06 8.26647 0.280391C7.84215 0.420587 7.55927 0.841177 7.70071 0.841177C7.9836 0.841177 8.40792 0.98137 8.6908 1.40196C8.97368 1.82255 8.97368 2.94412 8.97368 2.94412C8.97368 2.94412 9.11513 5.88824 8.54936 6.16863C8.12503 6.44902 7.41783 5.88824 6.14485 3.6451C5.43764 2.52353 4.87188 1.12157 4.87188 1.12157C4.87188 1.12157 4.73044 0.841174 4.589 0.700978C4.30611 0.560782 4.02323 0.560784 4.02323 0.560784H0.770071C0.770071 0.560784 0.345746 0.560785 0.0628629 0.841177C-0.0785787 0.981374 0.0628629 1.40196 0.0628629 1.40196C0.0628629 1.40196 2.60881 7.2902 5.43764 10.2343C8.12503 12.7578 11.0953 12.4775 11.0953 12.4775Z"
        transform="translate(6.78613 14.4898)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
