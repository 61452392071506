import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Instagram = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: Instagram_handle"
      tooltip={`Enter your Instagram handle (part of the web page address, for "instagram.com/example " the username is "example`}
    />
  ) : null;
};
export default memo(Instagram);
