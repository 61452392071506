import { images } from '@/asset';
import { listDefaultAvatarAgents } from '@/components/ChannelsButton/Component/ModalAgent';
import { workingTimeFilter } from '@/components/Icons/Window/AgentWindow/helper/agent';
import { PricingPlan, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { triggerSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { Badge, Button, Tooltip } from '@shopify/polaris';
import { TeamIcon, XIcon } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface IProps {
  showAddAgent?: boolean;
  channel: {
    customChannel: IParamsApi.IChannelButton | undefined;
    channel: IParamsApi.IChannelButton | undefined;
  };
  setIsOpenModalAddAgent: React.Dispatch<React.SetStateAction<boolean>>;
  setAgentSelected: React.Dispatch<React.SetStateAction<IParamsApi.IAgent | undefined>>;
  setIsOpenModalRemoveAgent: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAgent = ({ showAddAgent, channel, setIsOpenModalAddAgent, setAgentSelected, setIsOpenModalRemoveAgent }: IProps) => {
  const navigate = useNavigate();
  const trigger = useSelector(triggerSelector);
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();

  const handleEditAgent = (agent: IParamsApi.IAgent) => {
    setAgentSelected(agent);
    setIsOpenModalAddAgent(true);
  };

  return showAddAgent ? (
    <div className="d-flex mt-16 flex-center wrap gap-10 wrap-add-agent">
      {dataSetting?.data?.plan === PricingPlan.Premium ? (
        <>
          <Button onClick={() => setIsOpenModalAddAgent(true)} icon={TeamIcon} fullWidth>
            Add agent
          </Button>
          {channel.channel?.agents?.map((agent) => {
            const avatarUrl = agent.customAvatar
              ? agent.customAvatar
              : listDefaultAvatarAgents.find((avatar) => avatar.id === agent.avatarId)?.url;

            const isOnWorkingTime = workingTimeFilter(channel.channel?.agents || [], trigger.timezone)?.find((agentIsWorking) =>
              agentIsWorking.id
                ? agentIsWorking.id === agent.id
                : agentIsWorking.customId
                ? agentIsWorking.customId === agent.customId
                : false,
            );

            const TooltipContent = (
              <>
                <div>Name: {agent.name}</div>
                <div>Role: {agent.role}</div>
                <div>Jobs: {agent.jobs}</div>
                <div className="d-flex mb-4">
                  Status:
                  <div className="ml-8">
                    <Badge tone={isOnWorkingTime ? 'success' : undefined}>{isOnWorkingTime ? 'Active' : 'Inactive'}</Badge>
                  </div>
                </div>
              </>
            );
            return (
              <div className="ml-8 wrap-avatar" key={agent.customId || agent.id}>
                <div className="remove-btn">
                  <Button
                    onClick={() => {
                      setAgentSelected(agent);
                      setIsOpenModalRemoveAgent(true);
                    }}
                    icon={XIcon}
                    variant="plain"
                  ></Button>
                </div>
                <Tooltip content={TooltipContent}>
                  <div
                    className="wrap-img"
                    onClick={() => {
                      handleEditAgent(agent);
                    }}
                  >
                    <img
                      src={avatarUrl}
                      alt="avatar"
                      width={35}
                      height={35}
                      style={{ filter: isOnWorkingTime ? 'grayscale(0)' : 'grayscale(1)' }}
                    />
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className="w-full mt-4">
            <Tooltip content="Available on Premium plan">
              <Button disabled icon={TeamIcon} fullWidth>
                Add agent
              </Button>
            </Tooltip>
          </div>

          <div className="d-flex mb-4" style={{ marginTop: -8 }}>
            <img className="mr-4" src={images.crown} alt="vip" />
            <p>
              <span
                onClick={() => {
                  navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
                }}
                style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--p-color-text-emphasis)' }}
              >
                Upgrade to Premium plan
              </span>
              <span style={{ marginLeft: 4, color: '#9b6d00' }}>to add unlimited agents.</span>
            </p>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default AddAgent;
