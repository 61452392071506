import { Border } from '@/constants/enum';
import { memo } from 'react';
import { IconBorderStyled } from './styled';

interface IProps {
  value: Border;
  setValue: (value: string) => void;
}
export const borderConfig = [
  {
    value: Border.Square,
    border: 'none',
  },
  {
    value: Border.Conner,
    border: '5px',
  },
  {
    value: Border.Circle,
    border: '50%',
  },
  {
    value: Border.TopRight,
    border: '50% 5px 50% 50%',
  },
  {
    value: Border.TopLeft,
    border: '5px 50% 50% 50%',
  },
  {
    value: Border.BottomRight,
    border: '50% 50% 5px 50%',
  },
  {
    value: Border.BottomLefft,
    border: '50% 50% 50% 5px',
  },
];

const IConBorder = ({ value, setValue }: IProps) => {
  return (
    <IconBorderStyled>
      {borderConfig.map((item) => {
        return (
          <div key={item.value} className="icon-border-wrapper" onClick={() => setValue(item.border)}>
            <div
              className={item.value === value ? 'icon-border-container active' : 'icon-border-container'}
              style={{ borderRadius: item.border }}
            ></div>
          </div>
        );
      })}
    </IconBorderStyled>
  );
};

export default memo(IConBorder);
