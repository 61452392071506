import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
import { HomeVariant } from '../Icons/Variant/Home';
const GoToHome = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      variants={HomeVariant({})}
      key={keyValue}
      keyValue={keyValue}
      placeholder=""
      tooltip={`Add button to go to home page`}
      disabledInput
    />
  ) : null;
};
export default memo(GoToHome);
