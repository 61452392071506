import { useSelector } from 'react-redux';
import Message from './Message';
import { customizeSelected, windowSelector } from '@/redux/slice/widget.slice';
import { ICon } from '@/constants/enum';
import { useMemo, useRef } from 'react';
import { WindowStyled } from './styled';
import Whatsapp from './Whatsapp';
import WeChat from './WeChat';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import ContactForm from './ContactForm';
import { IParamsApi } from '@/types/apis/params';

interface IProps {
  handleCloseWindow: () => void;
  agentSelected?: IParamsApi.IAgent;
}
const WindowPreview = ({ handleCloseWindow, agentSelected }: IProps) => {
  const ref = useRef<any>(null);
  const window = useSelector(windowSelector);
  const customize = useSelector(customizeSelected);
  const scale = customize.widgetSize;
  const message = useChannelSelected(ICon.Messenger).channel?.advance?.messengerChatWidget;
  const whatsapp = useChannelSelected(ICon.Whatsapp).channel?.advance?.whatsappChatWidget;
  const qrUrl = useChannelSelected(ICon.WeChat).channel?.advance?.weChatQr?.url;
  const windowConfig = useMemo(() => {
    return [
      {
        channel: ICon.Messenger,
        element: <Message handleCloseWindow={handleCloseWindow} agentSelected={agentSelected} />,
        enableWidget: message,
      },
      {
        channel: ICon.Whatsapp,
        element: <Whatsapp handleCloseWindow={handleCloseWindow} agentSelected={agentSelected} />,
        enableWidget: whatsapp,
      },
      {
        channel: ICon.WeChat,
        element: <WeChat handleCloseWindow={handleCloseWindow} />,
        enableWidget: !!qrUrl,
      },
      {
        channel: ICon.ContactForm,
        element: <ContactForm handleCloseWindow={handleCloseWindow} />,
        enableWidget: true,
      },
    ];
  }, [agentSelected, handleCloseWindow, message, qrUrl, whatsapp]);
  const selected = windowConfig.find((item) => item.channel === window && item.enableWidget)?.element;

  const style: React.CSSProperties = useMemo(() => {
    return {
      [customize.sideSpacing >= 50 ? 'left' : 'right']: `calc(${
        customize.sideSpacing >= 50 ? 100 - customize.sideSpacing : customize.sideSpacing
      }%)`,
      bottom: `calc(${customize.bottomSpacing}% + ${scale + 8}px)`,
      display: selected ? 'flex' : 'none',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize.bottomSpacing, customize.sideSpacing, customize.sideSpacing, scale, window, ref.current]);

  return (
    <WindowStyled ref={ref} style={style} bottom={style.bottom}>
      {selected}
    </WindowStyled>
  );
};

export default WindowPreview;
