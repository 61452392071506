import { IColorPicker } from '@/types/components/channels';
import { memo, useState, useEffect } from 'react';
import RegularText from '../RegularText';
import { ColorPickerStyled } from './styled';
import { defaultColorIcon } from '@/constants/colors';
import { capitalizeFirstLetter } from '@/helpers/string';
const ColorPicker = ({ defaultValue, id, onBlur, title, iconKey }: IColorPicker) => {
  const defaultBackgroundColor = defaultColorIcon.find((item) => item.icon === iconKey)?.backgroundColor;
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(defaultValue);
  useEffect(() => {
    setBackgroundColor(defaultValue || defaultBackgroundColor);
  }, [defaultValue, defaultBackgroundColor]);

  return (
    <ColorPickerStyled key={id} backgroundColor={backgroundColor}>
      <label htmlFor={id}>
        <div className="d-flex">
          <div className="icon-background-color"></div>
          <div className="description">
            <RegularText>{title}</RegularText>
            <RegularText>
              {!backgroundColor
                ? 'Default'
                : backgroundColor.includes('linear-gradient')
                ? 'linear-gradient'
                : capitalizeFirstLetter(backgroundColor)}
            </RegularText>
          </div>
        </div>
        <input
          onBlur={(e) => {
            onBlur(e.target.value);
            setBackgroundColor(e.target.value);
          }}
          defaultValue={capitalizeFirstLetter(backgroundColor || '')}
          id={id}
          type="color"
        />
      </label>
    </ColorPickerStyled>
  );
};

export default memo(ColorPicker);
