import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import storageSession from 'redux-persist/lib/storage/session';
import { apiCaller } from './query';
import bannerSlice from './slice/banner.slice';
import thinksToDoSlice from './slice/thinksToDo.slice';
import toastSlice from './slice/toast.slice';
import widgetsSlice from './slice/widgets.slice';
import dashboardSlice from './slice/dashboard.slice';
import widgetSlice from './slice/widget.slice';
import homeSlice from './slice/home.slice';
import formAnswerSlice from './slice/formAnswer.slice';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { sessionSlice } from '@/redux/slice/session.slice';

const bannerPersistConfig = {
  key: 'banner',
  storage,
};

const thinksTodoPersistConfig = {
  key: 'thinksTodo',
  storage,
};

const sessionPersistConfig = {
  key: 'session',
  storage: storageSession,
};

const rootReducer = combineReducers({
  toast: toastSlice.reducer,
  banner: persistReducer(bannerPersistConfig, bannerSlice.reducer),
  widgets: widgetsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  home: homeSlice.reducer,
  apiCaller: apiCaller.reducer,
  widget: widgetSlice.reducer,
  thinksTodo: persistReducer(thinksTodoPersistConfig, thinksToDoSlice.reducer),
  formAnswer: formAnswerSlice.reducer,
  session: persistReducer(sessionPersistConfig, sessionSlice.reducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiCaller.middleware);
  },
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
