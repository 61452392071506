const search = window.location.search.substring(1);
const convertPathname = (pathname: string) => {
  return {
    pathname: `${pathname ? '/' + pathname : ''}`,
    search: '?' + search,
  };
};

export const PATH = {
  DEFAULT: convertPathname('home'),
  ANALYTICS: convertPathname('analytics'),
  WIDGETS: convertPathname('widgets'),
  FORM_ANSWER: convertPathname('form-answer'),
  NEW_WIDGET: convertPathname('widgets/create'),
  EDIT_WIDGET: convertPathname('widgets/:id'),
  PRICING_PLAN: convertPathname('pricing-plan'),
  APP_BRIDGE_MODAL: convertPathname('form-create-button'),
};
