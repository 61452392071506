import { Collapsible } from '@shopify/polaris';

interface IProps {
  children: React.ReactNode;
  transition?: {
    duration: string;
    timingFunction?: string;
  };
  open: boolean;
  id: string;
  expandOnPrint?: boolean;
}

const BaseCollapsible = ({ children, open, transition, id, expandOnPrint }: IProps) => {
  return (
    <Collapsible
      transition={transition || { duration: '200ms', timingFunction: 'ease-in-out' }}
      id={id}
      open={open}
      expandOnPrint={expandOnPrint}
    >
      {children}
    </Collapsible>
  );
};

export default BaseCollapsible;
