import styled from 'styled-components';
interface Props {
  borderRadius: string;
}

export const WhatsappStyled = styled.div<Props>`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    color: white;
    background-image: linear-gradient(#007542, #58bb43 30%);
    border-radius: 12px 12px 18px 18px;
    box-shadow: #00000029 0 5px 40px;
    position: relative;
    height: 100px;
    padding: 0 8px;
    padding-top: 12px;

    &::after {
      content: '';
      position: absolute;
      height: 30px;
      background-color: #fff9;
      bottom: -4px;
      left: 8px;
      right: 8px;
      border-radius: 15px 15px 0 0;
    }
    button {
      width: 20px;
      border-radius: 50%;
      padding: 0;
      background-color: #00000033;
      svg {
        fill: white;
      }
    }
    .Polaris-Text--root {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    /* border-bottom: 0.5px solid #aaaaaaaa; */
  }
  .body {
    bottom: 0;
    position: absolute;
    height: 220px;
    padding: 8px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    background-image: url('https://megamind-chat-buttons.s3.amazonaws.com/assets/whatsapp_bg+(1).png');
    z-index: 1;
    width: 100%;
    .message-contaner {
      padding: 4px 12px;
      background-color: #fff;
      border-radius: 6px;
      max-width: calc(100% - 50px);
      font-size: 14px;
      font-weight: 500;
      color: #333;
      display: flex;
      flex-direction: column;
      & > div {
        height: 16px;
      }
      .nickname {
        font-size: 10px;
      }
      .message {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time {
        font-size: 10px;
      }
    }
    .input-container {
      display: flex;
      input {
        font-size: 10px;
        border: none;
        padding: 6px;
        border-radius: 6px;
        background-color: #fff;
        outline: 0;
        width: 100%;
        margin: 0;
        color: #111b21;
        box-shadow: none;
      }
      .send-icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #c6d7e3;
        margin-left: 4px;
      }
    }
    .power-by {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }
  }
`;
