import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const Teams = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https://teams.microsoft.com/join/work_space"
      tooltip={`Add your Microsoft Teams workspace link. E.g., https://teams.microsoft.com/join/work_space`}
    />
  ) : null;
};
export default memo(Teams);
