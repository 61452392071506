/* eslint-disable jsx-a11y/img-redundant-alt */
import widgetSlice, {
  channelsSeletedSelector,
  customizeSelected,
  triggerSelector,
  windowSelector,
} from '@/redux/slice/widget.slice';
import { Button, ButtonGroup } from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultState, Device, Display, ICon, IconIds, PricingPlan } from '@/constants/enum';
import { useWidgetIcon } from '@/hooks/useWidgetIcon';
import { listIconPreview } from '@/pages/NewWidget/listIconDefault';
import { apiCaller } from '@/redux/query';
import { IParamsApi } from '@/types/apis/params';
import { CloseIcon } from '../Icons/Close';
import { CustomLinkIcon } from '../Icons/CustomLink';
import CustomWidgetIcon from '../Icons/CustomWidgetIcon';
import useVariant from '../Icons/Variant';
import WindowPreview from '../Icons/Window';
import AgentWindow from '../Icons/Window/AgentWindow';
import { workingTimeFilter } from '../Icons/Window/AgentWindow/helper/agent';
import { IconStyled, PreviewStyled } from './Styled';

interface IProps {
  step: number;
}

const Preview = ({ step }: IProps) => {
  const variant = useVariant();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();
  const [showAllIcons, setShowAllIcons] = useState(false);
  const trigger = useSelector(triggerSelector);
  const scale = useSelector(customizeSelected).widgetSize;
  const [mode, setMode] = useState(Device.Pc);
  const [agentSelected, setAgentSelected] = useState<IParamsApi.IAgent>();
  const [currentChannel, setCurrentChannel] = useState<ICon | undefined>();
  const window = useSelector(windowSelector);
  const channelsSelected = useSelector(channelsSeletedSelector);
  const customize = useSelector(customizeSelected);
  const widget = useWidgetIcon();
  const handleViewMode = (value: Device) => {
    setMode(value);
  };

  const filterIcon = useCallback(
    (channel: IParamsApi.IChannelButton) => {
      const image = channel.customImage;
      if (channel.iconId === IconIds.Custom) {
        return <img alt="" style={{ width: `${scale}px`, height: `${scale}px` }} src={image?.url} />;
      }
      if (channel.customLinkId) return <CustomLinkIcon channel={channel} />;
      const result = listIconPreview.find((item) => item.keyValue === channel.channel);
      if (result) {
        if (result.variant) {
          return variant
            .find((item) => item.id === channel.channel)
            ?.variant(customize.widgetSize, channel.backgroundColor, channel.iconColor)
            .find((item) => item.id === channel.iconId)?.element;
        } else {
          return result.icon(channel.backgroundColor, channel.iconColor);
        }
      }
    },
    [customize.widgetSize, scale, variant],
  );

  const visibleIcons = channelsSelected.filter((item) => item.showOn.includes(mode));
  const handleClick = (channel: ICon) => () => {
    setShowAllIcons(false);

    const isChannelHasAgents = workingTimeFilter(
      channelsSelected?.find((channelSelected) => channelSelected.channel === channel)?.agents || [],
      trigger.timezone,
    )?.length;
    isChannelHasAgents && setCurrentChannel(channel);
    setAgentSelected(undefined);

    const listButtonHasAction = [ICon.Messenger, ICon.Whatsapp, ICon.WeChat, ICon.ContactForm];
    if (listButtonHasAction.includes(channel)) {
      dispatch(widgetSlice.actions.handleWindow(window === channel ? undefined : channel));
    }
  };
  useEffect(() => {
    if (ref && ref.current && visibleIcons.length > 2) {
      if (customize.buttonDirection === Display.Vertical) {
        ref.current.scrollTop = ref.current.scrollHeight;
      } else {
        ref.current.scrollRight = ref.current.scrollWidth;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(channelsSelected), customize.buttonDirection]);

  useEffect(() => {
    if (step === 3) {
      setShowAllIcons(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step === 3]);
  useEffect(() => {
    setShowAllIcons(true);
    dispatch(widgetSlice.actions.handleWindow(undefined));
    setCurrentChannel(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customize.openAction]);

  useEffect(() => {
    if (currentChannel && !channelsSelected.find((channel) => channel.channel === currentChannel)?.agents?.length) {
      setCurrentChannel(undefined);
      setAgentSelected(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(channelsSelected), currentChannel]);

  const handleCloseWindow = () => {
    setShowAllIcons(true);
  };

  const handleSelectedAgent = (agent: IParamsApi.IAgent | undefined) => {
    setCurrentChannel(undefined);
    setAgentSelected(agent);
  };

  const handleClosePreviewAgent = () => {
    setShowAllIcons(true);
    dispatch(widgetSlice.actions.handleWindow(undefined));
    setCurrentChannel(undefined);
  };

  return (
    <PreviewStyled
      borderRadius={customize.buttonStyle || 'none'}
      scale={customize.widgetSize}
      display={customize.buttonDirection}
      bottom={customize.bottomSpacing}
      positionValue={customize.sideSpacing}
      groupColor={customize.groupColor}
      tooltipBackgroundColor={customize.ctaBackground}
      tooltipTextColor={customize.ctaTextColor}
      style={{ top: '72px' }}
    >
      <div className={`tv-screen ${mode === Device.Mobile ? 'mobile-view' : 'pc-view'}`}>
        {currentChannel && ![PricingPlan.Free, PricingPlan.Basic]?.includes(dataSetting?.data?.plan || PricingPlan.Free) ? (
          <AgentWindow channel={currentChannel} handleClose={handleClosePreviewAgent} handleSelectedAgent={handleSelectedAgent} />
        ) : (
          <WindowPreview agentSelected={agentSelected} handleCloseWindow={handleCloseWindow} />
        )}
        <div className="position-icon">
          <div
            className={`list-channels-button ${
              showAllIcons || visibleIcons.length === 1 ? 'list-channels-button-active' : 'list-channels-button-hidden'
            } `}
            style={{ paddingBottom: visibleIcons.length > 1 && customize.buttonDirection === Display.Vertical ? '8px' : 0 }}
            ref={ref}
          >
            {visibleIcons.reverse().map((icon, index) => {
              return (
                <IconStyled
                  iconColor={icon.iconColor}
                  display={customize.buttonDirection}
                  positionValue={customize.sideSpacing}
                  key={index}
                  onClick={handleClick(icon.channel)}
                  borderRadius={customize.buttonStyle}
                  scale={customize.widgetSize}
                  backgroundColor={icon.backgroundColor}
                  tooltipBackgroundColor={customize.ctaBackground}
                  tooltipTextColor={customize.ctaTextColor}
                  totalNumberOfProductsText={icon?.advance?.totalQuantityTextColorCartPage}
                  totalNumberOfProductsBackground={icon?.advance?.totalQuantityBackgroundColorCartPage}
                  style={{
                    transition: '0.5s',
                    transform:
                      visibleIcons.length === 1
                        ? undefined
                        : customize.buttonDirection === Display.Vertical
                        ? `translate(0px, ${
                            showAllIcons
                              ? 0
                              : (visibleIcons.length - index - (customize.openAction === DefaultState.Click ? 0 : 1)) *
                                  customize.widgetSize +
                                8
                          }px`
                        : `translate(${
                            showAllIcons
                              ? 0
                              : customize.sideSpacing < 50
                              ? (visibleIcons.length - index - (customize.openAction === DefaultState.Click ? 0 : 1)) *
                                  customize.widgetSize +
                                8
                              : (-visibleIcons.length + index - (customize.openAction === DefaultState.Click ? 0 : 1)) *
                                  customize.widgetSize -
                                8
                          }px,0)`,
                  }}
                >
                  {icon?.hoverText ? <span className="tooltip">{icon?.hoverText}</span> : null}
                  <div
                    className={
                      icon?.channel === ICon.CartPage && icon?.advance?.isShowQuantityProductInCart && showAllIcons
                        ? 'wrap-cart-btn-preview'
                        : ''
                    }
                  >
                    {filterIcon(icon)}
                  </div>
                </IconStyled>
              );
            })}
          </div>

          {customize.openAction === DefaultState.Click ? (
            <div
              onClick={() => {
                if (window) {
                  dispatch(widgetSlice.actions.handleWindow(undefined));
                }
                setCurrentChannel(undefined);
                setShowAllIcons(!showAllIcons);
              }}
            >
              {visibleIcons.length >= 2 ? (
                <div>
                  <div onClick={() => dispatch(widgetSlice.actions.handleWindow(undefined))} className="close-icon-container">
                    {customize.ctaText ? <span className="close-icon-tooltip">{customize.closeHoverText}</span> : null}
                    <div className={showAllIcons ? 'widget-active' : 'widget-hidden'}>
                      <CloseIcon />
                    </div>
                  </div>
                  <div className={'widget-tooltip-container'}>
                    {customize.groupIcon.url ? (
                      <div>
                        {customize.ctaText ? <span className="widget-tooltip">{customize.ctaText}</span> : null}
                        <div className={!showAllIcons ? 'widget-active' : 'widget-hidden'}>
                          <CustomWidgetIcon />
                        </div>
                      </div>
                    ) : (
                      <div>
                        {customize.ctaText ? <span className="widget-tooltip">{customize.ctaText}</span> : null}
                        <div className={!showAllIcons ? 'widget-active' : 'widget-hidden'}>
                          {widget.groupIcon.find((item) => item.key === customize.groupIcon.key)?.icon}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-16">
        <ButtonGroup variant="segmented">
          <Button icon={DesktopIcon} pressed={mode === Device.Pc} onClick={() => handleViewMode(Device.Pc)}></Button>
          <Button icon={MobileIcon} pressed={mode === Device.Mobile} onClick={() => handleViewMode(Device.Mobile)}></Button>
        </ButtonGroup>
      </div>
    </PreviewStyled>
  );
};

export default memo(Preview);
