import { getSomeDaysAgo } from '@/helpers/time';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SortDirection } from '@/constants/enum';

export interface IWidgetData {
  startDate: Date;
  endDate: Date;
  page: number;
  perPage: number;
  sortDirection: SortDirection;
}

export interface IWidgetIds {
  id: number;
}

export interface IRename {
  id: number;
  widgetName: string;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  widgetsData: IWidgetData;
  titleBtnDatePicker: string;
} = {
  widgetsData: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
    page: 1,
    perPage: 5,
    sortDirection: SortDirection.Asc,
  },
  titleBtnDatePicker: 'Last 7 days',
};

export const widgetsSlice = createSlice({
  name: 'widgets',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleData: (state, action: PayloadAction<IWidgetData>) => {
      state.widgetsData = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type
export const widgetsDataSelector = createSelector(
  (state: RootState) => state.widgets,
  (state) => state.widgetsData,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.widgets,
  (state) => state.titleBtnDatePicker,
);

export default widgetsSlice;
