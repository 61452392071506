import { colors } from '@/constants/colors';
import styled from 'styled-components';
interface Props {
  backgroundColor: string;
  borderRadius: string;
  iconColor?: string;
  isMobile: boolean;
}
export const ChannelButtonStyled = styled.div<Props>`
  padding: ${(props) => (props.isMobile ? '0.5rem' : '1.75rem')};
  background-color: ${colors.bgHover};
  border-radius: 12px;
  display: flex;
  align-items: center;
  position: relative;
  .remove-btn {
    position: absolute;
    top: 0.5rem;
    right: 0rem;
    cursor: pointer;
    &:hover {
      svg {
        fill: ${colors.critical};
      }
    }
  }
  .icon-type {
    svg {
      border-radius: ${(props) => props.borderRadius};
    }
  }
  .header-container {
    margin-left: 1rem;
    display: flex;
    width: 100%;
  }
  .right-container {
    width: 100%;
  }
  .branch-container {
    position: relative;
    width: 40px;
    height: 40px;
    svg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
    }

    path:not(.not-press) {
      fill: ${(props) => props.iconColor};
      &.color-zalo-bgc {
        fill: ${(props) => (props.backgroundColor !== 'default' ? props.backgroundColor : 'white')};
      }
    }
    .Polaris-Icon__Svg path {
      fill: #767676;
    }
    .branch-icon {
      position: absolute;
      top: -0.75rem;
      right: -1.25rem;
    }
  }
  .icon-type {
    display: flex;
    svg {
      width: 40px;
      height: 40px;
    }
    .Polaris-Choice {
      display: flex;
      flex-direction: column;
      width: fit-content;
    }
    .Polaris-Choice__Label {
      margin-top: 0.25rem;
    }
  }

  .field-w-100 {
    .Polaris-Labelled--hidden {
      width: 100%;
    }
  }
  .Polaris-TextField__Input.PhoneInput {
    input {
      width: 100%;
    }
  }

  .selected-flag,
  .flag-dropdown {
    height: 35px;
  }
  .form-control {
    width: 100% !important;
  }
  .drag-icon {
    cursor: move;
  }
  .Polaris-InlineError__Icon {
    margin-right: 0;
  }
  .icon-type-item:hover,
  .icon-type-item-active {
    svg {
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
      border: 2px solid #94d1ff;
    }
  }
  .wrap-avatar {
    cursor: pointer;
    position: relative;
    .remove-btn {
      position: absolute;
      top: -6px;
      right: -12px;
      z-index: 1;
      button {
        padding: 0;
      }
    }
    .wrap-img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .wrap-add-agent > span {
    display: inline-block;
    width: 100%;
  }
  .show-on-container {
    .Polaris-Icon__Svg {
      width: 30px;
      height: 30px;
    }
  }
`;
