import { Text, TextProps } from '@shopify/polaris';
import { memo } from 'react';
type IAlignment = 'start' | 'center' | 'end' | 'justify';

const RegularText = ({
  children,
  alignment,
  props,
}: {
  children: React.ReactNode;
  alignment?: IAlignment;
  props?: TextProps;
}) => {
  return (
    <Text {...props} as="span" variant="bodySm" alignment={alignment}>
      {children}
    </Text>
  );
};
export default memo(RegularText);
