import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const WeChatIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#05dc6b'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39"></rect>
      <path
        d="M17.3707 6.90938C15.0508 7.03077 13.0281 7.71242 11.3888 9.26248C9.73039 10.8312 8.97307 12.7455 9.18397 15.1172C8.27327 15.0052 7.44884 14.8838 6.61482 14.8184C6.32723 14.7904 5.99171 14.8278 5.74246 14.9585C4.93721 15.4067 4.16071 15.9016 3.24042 16.4619C3.41298 15.7242 3.51843 15.0705 3.71015 14.4449C3.85395 13.9874 3.78685 13.7353 3.35546 13.4364C0.584998 11.5222 -0.594125 8.65553 0.287821 5.69547C1.10266 2.95952 3.09663 1.30674 5.80957 0.447674C9.50991 -0.728879 13.68 0.475687 15.9232 3.33303C16.738 4.36952 17.2365 5.53673 17.3707 6.90938ZM6.69152 5.98494C6.71069 5.44336 6.23137 4.95779 5.65619 4.93912C5.07142 4.92044 4.5921 5.35932 4.57293 5.91958C4.55375 6.48918 5.00431 6.94673 5.58908 6.95607C6.18344 6.97474 6.67234 6.53587 6.69152 5.98494ZM12.2612 4.93912C11.686 4.94846 11.2067 5.42468 11.2163 5.96627C11.2259 6.52653 11.7052 6.9654 12.29 6.9654C12.8843 6.95607 13.3253 6.51719 13.3253 5.93825C13.3157 5.37799 12.846 4.92978 12.2612 4.93912Z"
        transform="translate(7 9)"
        fill={iconColor || 'white'}
      ></path>
      <path
        d="M12.48 13.2605C11.7418 12.943 11.0708 12.4668 10.3518 12.3921C9.63283 12.3174 8.88509 12.7189 8.13735 12.7936C5.8558 13.0177 3.8139 12.4014 2.13629 10.8887C-1.06556 8.00334 -0.605416 3.57726 3.09492 1.21482C6.38305 -0.886168 11.2146 -0.185839 13.5345 2.72753C15.5572 5.26739 15.3175 8.64764 12.8538 10.786C12.1349 11.4023 11.876 11.9158 12.3362 12.7282C12.4225 12.8776 12.432 13.0737 12.48 13.2605ZM4.11108 5.3701C4.58081 5.3701 4.96426 5.01527 4.98344 4.56706C5.00261 4.09084 4.60957 3.68932 4.12066 3.68932C3.63176 3.68932 3.22913 4.09084 3.2483 4.55772C3.26748 5.00593 3.65093 5.3701 4.11108 5.3701ZM9.5082 3.68932C9.05764 3.68932 8.6646 4.04415 8.64543 4.49236C8.62626 4.96858 9.00971 5.36077 9.48903 5.36077C9.95876 5.36077 10.3326 5.01527 10.3422 4.55772C10.371 4.09084 9.98752 3.68932 9.5082 3.68932Z"
        transform="translate(17.1057 16.7395)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
