import { dateToTimeStamp, formatDate } from '@/helpers/time';
import { apiCaller } from '@/redux/query';
import { Card, SkeletonBodyText } from '@shopify/polaris';
import { LineChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
interface IProps {
  startTime: Date;
  endTime: Date;
}
const Chart = (props: IProps) => {
  const { data } = apiCaller.useGetDataSummaryQuery({
    startTime: dateToTimeStamp(props.startTime),
    endTime: dateToTimeStamp(props.endTime),
  });
  const isLoading = false;
  const dataChart = useMemo(() => {
    if (data) {
      const listKey = ['Visitors', 'Unique clicks'];
      const objectChart = {
        visitors: data.data.chart.visitors,
        clicks: data.data.chart.clicks,
      };
      const isDay = dateToTimeStamp(props.endTime) - dateToTimeStamp(props.startTime) > 86400000;
      const categories = data?.data.chart.categories.map((item) => formatDate(item / 1000, isDay ? 'D MMM' : 'h:mm a'));
      const colors = ['#008170', '#75C2F6'];
      const result = Object.entries(objectChart).map(([_, value], index) => {
        return {
          name: listKey[index],
          color: colors[index],
          data: value.map((item, i) => {
            return {
              value: item,
              key: categories[i],
            };
          }),
        };
      });
      return result;
    }
    return [];
  }, [data, props.endTime, props.startTime]);
  return (
    <Card>
      <PolarisVizProvider>
        <div style={{ height: 300, marginTop: '1rem' }}>
          {isLoading ? <SkeletonBodyText lines={16} /> : <LineChart theme="Light" data={dataChart} />}
        </div>
      </PolarisVizProvider>
    </Card>
  );
};

export default memo(Chart);
