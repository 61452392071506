import { listDefaultAvatarAgents } from '@/components/ChannelsButton/Component/ModalAgent';
import { uniq } from '@/helpers/string';
import { IParamsApi } from '@/types/apis/params';
import { SkeletonBodyText } from '@shopify/polaris';
import { AgentCardStyled } from './styled';

interface IProps {
  data: IParamsApi.IAgent;
}

const AgentCard = ({ data }: IProps) => {
  const avatarUrl = data.customAvatar
    ? data.customAvatar
    : listDefaultAvatarAgents.find((avatar) => avatar.id === data.avatarId)?.url;

  const jobs = uniq(data.jobs.split(',')?.map((item: string) => item.trim())).filter((item) => item !== '');
  return (
    <AgentCardStyled>
      <div className="avatar-container">
        <img src={avatarUrl} alt="avatar" width={35} height={35} />
      </div>
      <p className="text-center wrap-role font-bold">{data.name}</p>
      <p className="text-center wrap-role">{data.role}</p>
      <div className="flex-center">
        <div className="mt-8 d-flex flex-column">
          {[...jobs].splice(0, 2).map((itemAfterTrim) => {
            return (
              <div className="mt-4 Polaris-Tag" key={itemAfterTrim}>
                <p className="job-text">{itemAfterTrim}</p>
              </div>
            );
          })}
          {jobs.length > 2 ? (
            <div className="mt-8">
              <SkeletonBodyText lines={1} />
            </div>
          ) : null}
        </div>
      </div>
    </AgentCardStyled>
  );
};

export default AgentCard;
