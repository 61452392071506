import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const TiktokIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#000100'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39"></rect>
      <path
        stroke="null"
        d="m29.11825,14.02763c-1.25151,0 -2.40924,-0.41538 -3.33648,-1.11484c-1.06393,-0.80129 -1.83038,-1.98045 -2.10105,-3.33648c-0.067,-0.33498 -0.10183,-0.6807 -0.10451,-1.03712l-3.58035,0l0,9.78165l-0.00268,5.35445c0,1.43375 -0.9326,2.64775 -2.227,3.07385c-0.37519,0.12595 -0.77986,0.18223 -1.20328,0.16079c-0.53598,-0.02948 -1.04248,-0.19296 -1.47931,-0.45558c-0.93529,-0.55742 -1.56775,-1.57042 -1.58382,-2.72814c-0.0268,-1.81162 1.43643,-3.28824 3.24537,-3.28824c0.35643,0 0.69945,0.05896 1.02104,0.16348l0,-3.63396c-0.33767,-0.04824 -0.68338,-0.07503 -1.03176,-0.07503c-1.98045,0 -3.83227,0.82273 -5.15613,2.30739c-1.00228,1.1202 -1.60259,2.54859 -1.6937,4.04933c-0.12328,1.96973 0.59762,3.84299 1.99653,5.22314c0.20635,0.20367 0.42074,0.39127 0.64586,0.56546c1.19792,0.92189 2.66383,1.42303 4.20745,1.42303c0.34839,0 0.69409,-0.02679 1.03176,-0.07772c1.44179,-0.21171 2.77102,-0.87365 3.82154,-1.91077c1.28904,-1.27564 2.00189,-2.96666 2.00994,-4.76755l-0.01877,-7.99952c0.61638,0.47434 1.28904,0.86829 2.00994,1.17112c1.12288,0.47434 2.31544,0.71554 3.54016,0.71554l0,-3.56428c0,0 -0.01072,0 -0.01072,0l-0.00001,0zm0,0"
        fill={iconColor || 'white'}
        fillRule="nonzero"
      ></path>
    </svg>
  );
};
