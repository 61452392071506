import styled from 'styled-components';

export const WeChatWindowStyled = styled.div`
  height: 280px;
  padding: 0.5rem 1rem;
  background-color: #05dc6b;
  border-radius: 8px;
  .wechat-header {
    width: 100%;
    justify-content: center;
    position: relative;
    svg {
      position: absolute;
      top: 15px;
      right: 0px;
      opacity: 0.85;
      :hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  .wechat-qr {
    background-color: white;
    height: 196px;
  }
`;
