import styled from 'styled-components';

export const TableChannelStyled = styled.div`
  .Polaris-IndexTable__EmptySearchResultWrapper > .Polaris-Box {
    padding: 0;
  }
  .preview-icon-container {
    svg {
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
    }
  }
  .table-analytics {
    text-align: center;
  }
  .empty-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    h6 {
      width: 100%;
      text-align: center;
    }
  }
  .wrap-icon > svg {
    border-radius: 50%;
  }
`;
