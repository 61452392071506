import BoldText from '@/components/BoldText';
import { capitalizeFirstLetter } from '@/helpers/string';
import { IResponseApi } from '@/types/apis/response';
import { Card, SkeletonBodyText, Text } from '@shopify/polaris';
import { BarChart, PolarisVizProvider } from '@shopify/polaris-viz';
import { memo, useMemo } from 'react';
interface IProps {
  data?: IResponseApi.IGetDashboardSummary;
  isLoading: boolean;
}
const CustomBarChart = ({ data, isLoading }: IProps) => {
  const dataChart = useMemo(() => {
    if (data) {
      const listKey = ['Desktop clicks', 'Mobile clicks'];

      const objectChart = {
        desktopClicks: data.data?.topChannels?.desktopClicks,
        mobileClicks: data.data?.topChannels?.mobileClicks,
      };
      const colors = ['#00a2ff', '#f6b0ff'];
      const categories = data.data?.topChannels?.categories;
      const result = Object.entries(objectChart).map(([_, value], index) => {
        return {
          name: listKey[index],
          color: colors[index],
          data: value.map((item, i) => {
            return {
              value: item,
              key: capitalizeFirstLetter(categories[i]),
            };
          }),
        };
      });
      return result;
    }
    return [];
  }, [data]);
  return (
    <Card>
      {data?.data.summary.clicks && data?.data.summary.clicks > 0 ? (
        <>
          <BoldText>Top 3 channels</BoldText>

          <PolarisVizProvider>
            <div style={{ height: 416 }}>
              {isLoading ? <SkeletonBodyText lines={20} /> : <BarChart theme="Light" data={dataChart} direction="horizontal" />}
            </div>
          </PolarisVizProvider>
        </>
      ) : (
        <div style={{ height: 420, marginTop: '1rem' }} className="flex-center">
          <div className="flex-center" style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
            <BoldText>Nothing to see here yet!</BoldText>
            <div className="mt-8">
              <Text as="p" alignment="center">
                When someone clicks any button, the details will appear here.
              </Text>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default memo(CustomBarChart);
