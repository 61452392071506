import { memo } from 'react';
import ChannelsButton from '.';

import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';

const Telegram = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: TelegramTips"
      tooltip="Enter the username of your Telegram profile or channel. You can find your username by going into the Telegram profile. E.g., TelegramTips"
    />
  ) : null;
};
export default memo(Telegram);
