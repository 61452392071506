import { customizeSelected } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';

export const WidgetIconThree = () => {
  const customize = useSelector(customizeSelected);
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svg-inline=""
      role="presentation"
      focusable="false"
      tabIndex={-1}
      className="chat-icon chat-bubble"
    >
      <rect x="0" y="0" width="48" height="48" fill={customize.groupColor} className="color-fill"></rect>
      <path
        d="M21.6 13.333h4.711c2.667 0 5.334 1.067 7.2 3.023 1.867 1.866 3.022 4.533 3.022 7.2 0 5.333-4.088 9.777-9.422 10.222v3.91c0 .356-.178.623-.444.8h-.356c-.177 0-.444-.177-.622-.355L21.6 33.69c-2.667 0-5.333-1.067-7.2-3.022-1.867-1.956-3.022-4.534-3.022-7.2 0-5.6 4.622-10.134 10.222-10.134zm8.622 11.734c.978 0 1.6-.623 1.6-1.6 0-.978-.622-1.6-1.6-1.6-.977 0-1.6.622-1.6 1.6.09.977.712 1.6 1.6 1.6zm-6.222 0c.978 0 1.6-.623 1.6-1.6 0-.978-.622-1.6-1.6-1.6-.978 0-1.6.622-1.6 1.6 0 .977.622 1.6 1.6 1.6zm-6.311 0c.978 0 1.6-.623 1.6-1.6 0-.978-.622-1.6-1.6-1.6-.978 0-1.6.622-1.6 1.6 0 .977.622 1.6 1.6 1.6z"
        fill={customize.groupIconColor || 'white'}
      ></path>
    </svg>
  );
};
