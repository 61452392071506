import { images } from '@/asset';
import AddAgent from '@/components/AddAgent';
import { BREAKPOINT, Device, ICon, IconIds, Subscription } from '@/constants/enum';
import { PATH } from '@/constants/path';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { listIconDefault, listIconPreview } from '@/pages/NewWidget/listIconDefault';
import toastSlice from '@/redux/slice/toast.slice';
import widgetSlice, { channelsSeletedSelector, customizeSelected, errorSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { IVariantIcon } from '@/types/components/icon';
import { IUpload } from '@/types/components/upload';
import { Button, Checkbox, Icon, InlineGrid, Modal, TextField, Tooltip } from '@shopify/polaris';
import { DragHandleIcon, InfoIcon, SettingsIcon, XIcon } from '@shopify/polaris-icons';
import { HTMLInputTypeAttribute, memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import BaseCollapsible from '../BaseCollapsible/BaseCollapsible';
import ColorPicker from '../ColorPicker';
import { CustomLinkIcon } from '../Icons/CustomLink';
import useVariant from '../Icons/Variant';
import ModalConfirm from '../ModalConfirm';
import RegularText from '../RegularText';
import UploadImage from '../UploadImage';
import ModalAgent from './Component/ModalAgent';
import { ChannelButtonStyled } from './styled';
interface Props {
  style?: React.CSSProperties;
  customId?: number;
  keyValue: ICon | string;
  isPhoneNumber?: boolean;
  tooltip?: string;
  expandActionSettings?: React.ReactElement;
  preExpandActionSettings?: React.ReactElement;
  placeholder?: string;
  prefix?: React.ReactElement;
  suffix?: React.ReactElement;
  disabledInput?: boolean;
  showAddAgent?: boolean;
  variants?: Array<IVariantIcon>;
  label?: string;
  isOptional?: boolean;
  isReverseContentToBottom?: boolean;
  inputType?: HTMLInputTypeAttribute;
  limitedContent?: string;
  limitedTitle?: string;
  isShowLimitedText?: boolean;
}

const ChannelsButton = ({ ...props }: Props) => {
  const [isOpenModalAddAgent, setIsOpenModalAddAgent] = useState(false);
  const error = useSelector(errorSelector);
  const [modalConfirmRemove, setModalConfirmRemove] = useState(false);
  const variant = useVariant();
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const channels = useSelector(channelsSeletedSelector);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [agentSelected, setAgentSelected] = useState<IParamsApi.IAgent | undefined>();
  const [isOpenModalRemoveAgent, setIsOpenModalRemoveAgent] = useState(false);
  const customize = useSelector(customizeSelected);
  const channelSelected = useChannelSelected(props.keyValue as ICon);

  const channel = useMemo(() => {
    if (props.keyValue.includes(ICon.CustomLink)) {
      return {
        channel: channels.find((item) => item.customLinkId === props.customId) || channels[0],
        customChannel: channels.find((item) => item.customLinkId === props.customId) || channels[0],
      };
    } else {
      return channelSelected;
    }
  }, [channelSelected, channels, props.customId, props.keyValue]);
  const onRemoveChannels = () => {
    setModalConfirmRemove(true);
  };
  const handleRemoveCustomChannel = (customId?: number) => {
    const newChannels = [...channels];
    const index = channels.findIndex((item) => item.customLinkId === customId);
    newChannels.splice(index, 1);
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const handleRemove = () => {
    if (props.customId) {
      handleRemoveCustomChannel(props.customId);
    } else {
      const newChannels = [...channels];
      const index = channels.findIndex((item) => item.channel === props.keyValue);
      if (index !== -1) {
        newChannels.splice(index, 1);
        dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      }
    }
    const index = error.icon?.findIndex((icon) => icon === props.keyValue);
    if (index !== -1) {
      const newIcons = error.icon?.filter((icon) => icon !== props.keyValue);
      dispatch(
        widgetSlice.actions.handleError({
          ...error,
          icon: newIcons,
        }),
      );
    }
    setModalConfirmRemove(false);
    dispatch(
      toastSlice.actions.handleToast({
        content: 'Deleted',
        error: false,
        isOpen: true,
      }),
    );
  };

  const handleUploadFile = (value: IUpload) => {
    const newChannels = [...channels];

    let index: number;
    if (props.customId) {
      index = newChannels.findIndex((item) => item.customLinkId === props.customId);
    } else {
      index = newChannels.findIndex((item) => item.channel === props.keyValue);
    }

    if (index !== -1) {
      newChannels[index] = {
        ...newChannels[index],
        iconId: IconIds.Custom,
        customImage: value,
      };
    }
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const handleChangeIconId = (id: IconIds) => {
    const newChannels = [...channels];
    let index: number;
    if (props.customId) {
      index = newChannels.findIndex((item) => item.customLinkId === props.customId);
    } else {
      index = newChannels.findIndex((item) => item.channel === props.keyValue);
    }

    if (index !== -1) {
      newChannels[index] = {
        ...newChannels[index],
        iconId: id,
      };
    }
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const url = useMemo(() => {
    const channel = channels.find((item) => {
      if (props.customId) return item.customLinkId === props.customId;
      return item.channel === props.keyValue;
    });
    if (channel && channel.customImage && channel.customImage.url) {
      return channel.customImage.url;
    }
    return undefined;
  }, [channels, props.customId, props.keyValue]);
  const filterIcon = useMemo(() => {
    if (channel.channel?.iconId === IconIds.Custom || channel.customChannel?.iconId === IconIds.Custom) {
      const image = channel.channel
        ? channel.channel?.customImage?.url
        : channel.customChannel
        ? channel.customChannel.customImage?.url
        : '';

      return <img alt="" style={{ width: `40px`, height: `40px`, borderRadius: '50%' }} src={image} />;
    }
    if (channel.customChannel?.customLinkId) return <CustomLinkIcon channel={channel.customChannel} />;
    const result = listIconPreview.find((item) => item.keyValue === channel.channel?.channel);
    if (result) {
      if (result.variant) {
        return variant
          .find((item) => item.id === channel.channel?.channel)
          ?.variant(40, channel.channel?.backgroundColor, channel.channel?.iconColor)
          .find((item) => item.id === channel.channel?.iconId)?.element;
      } else {
        return result.icon(channel.channel?.backgroundColor, channel.channel?.iconColor);
      }
    }
  }, [channel, variant]);

  const handleChange = (key: keyof IParamsApi.IChannelButton) => (value: string | Array<Device>) => {
    const newChannelsSelected = [...channels];
    if (props.keyValue.includes(ICon.CustomLink)) {
      const index = newChannelsSelected.findIndex((item) => item.customLinkId === props.customId);

      newChannelsSelected[index] = {
        ...newChannelsSelected[index],
        [key]: value,
      };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannelsSelected));
    } else {
      if (channel.channel) {
        const index = newChannelsSelected.findIndex((item) => item.channel === props.keyValue);
        newChannelsSelected[index] = {
          ...channel.channel,
          [key]: value,
        };
        dispatch(widgetSlice.actions.handleChannelsSelected(newChannelsSelected));
      }
    }
  };

  const handleConfirmRemoveAgent = () => {
    if (!channels) return;
    const newChannels = [...channels];
    const index = channels.findIndex((item) => item.channel === props.keyValue);
    if (index !== -1 || channels[index]) {
      const newAgents =
        channels[index].agents?.filter((agent) =>
          agent.customId ? agent.customId !== agentSelected?.customId : agent.id ? agent.id !== agentSelected?.id : false,
        ) || [];
      newChannels[index] = { ...newChannels[index], agents: newAgents };
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      setIsOpenModalRemoveAgent(false);
      setAgentSelected(undefined);
    }
  };

  return (
    <ChannelButtonStyled
      id={`channel-selected-${props.keyValue}`}
      iconColor={channel.channel?.iconColor}
      borderRadius={customize.buttonStyle}
      backgroundColor={channel.channel?.backgroundColor || 'default'}
      style={props.style}
      isMobile={isMobile}
    >
      <div className="drag-icon">
        <Icon source={DragHandleIcon} tone="base" />
      </div>
      <div className="header-container">
        <div className="remove-btn" onClick={onRemoveChannels}>
          <Icon tone="subdued" source={XIcon} />
        </div>

        <div className="branch-container">
          {filterIcon}

          <div className="branch-icon">
            <Tooltip content={props.tooltip}>
              <Icon source={InfoIcon} tone="base" />
            </Tooltip>
          </div>
        </div>
        <div className="ml-16 right-container">
          {!props.isReverseContentToBottom && props.preExpandActionSettings ? (
            <div className="mb-8">{props.preExpandActionSettings}</div>
          ) : null}
          {props.isPhoneNumber ? (
            props.prefix
          ) : (
            <TextField
              onChange={(value) => {
                handleChange('value')(value);
                const icons = [...(error.icon || [])];
                if (icons.includes(props.keyValue as ICon)) {
                  const newIcons = icons.filter((item) => item !== props.keyValue);
                  dispatch(
                    widgetSlice.actions.handleError({
                      ...error,
                      icon: newIcons,
                    }),
                  );
                }
              }}
              value={channel.channel?.value}
              autoComplete="off"
              label={props.label || ''}
              labelHidden={!props.label}
              placeholder={props.placeholder}
              prefix={props.prefix}
              suffix={props.suffix}
              error={
                !props.isOptional &&
                (error.icon?.includes(props.keyValue as ICon)
                  ? props.keyValue === ICon.ContactForm
                    ? 'Invalid email address'
                    : 'This field is required'
                  : undefined)
              }
              disabled={props.disabledInput}
              type={(props.inputType as any) || 'text'}
            />
          )}
          {props.isShowLimitedText ? (
            <div className="d-flex mt-4">
              <img className="mr-4" src={images.crown} alt="vip" />
              <p>
                <span
                  onClick={() => {
                    navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
                  }}
                  style={{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--p-color-text-emphasis)' }}
                >
                  {props.limitedTitle}
                </span>
                <span style={{ marginLeft: 4, color: '#9b6d00' }}>{props.limitedContent}</span>
              </p>
            </div>
          ) : null}

          {props.isReverseContentToBottom && props.preExpandActionSettings ? (
            <div className="mb-8">{props.preExpandActionSettings}</div>
          ) : null}

          <div className="mt-8">
            <Button pressed={isOpenSettings} onClick={() => setIsOpenSettings(!isOpenSettings)} icon={SettingsIcon}>
              Settings
            </Button>
          </div>

          <BaseCollapsible open={isOpenSettings} id="expand-settings">
            <AddAgent
              channel={channel}
              showAddAgent={props.showAddAgent}
              setIsOpenModalAddAgent={setIsOpenModalAddAgent}
              setAgentSelected={setAgentSelected}
              setIsOpenModalRemoveAgent={setIsOpenModalRemoveAgent}
            />

            <div className="mt-8 show-on-container">
              <RegularText>Show on</RegularText>
              <div className="d-flex">
                <Checkbox
                  label="Desktop"
                  checked={channel.channel?.showOn.includes(Device.Pc)}
                  onChange={() => {
                    let device = channel.channel ? [...channel.channel.showOn] : [];
                    const index = device.findIndex((item) => item === Device.Pc);
                    if (index === -1) {
                      device.push(Device.Pc);
                    } else {
                      device.splice(index, 1);
                    }

                    handleChange('showOn')(device);
                  }}
                />
                <div className="ml-16">
                  <Checkbox
                    label="Mobile"
                    checked={channel.channel?.showOn.includes(Device.Mobile)}
                    onChange={() => {
                      let device = channel.channel ? [...channel.channel?.showOn] : [];
                      const index = device.findIndex((item) => item === Device.Mobile);
                      if (index === -1) {
                        device.push(Device.Mobile);
                      } else {
                        device.splice(index, 1);
                      }
                      handleChange('showOn')(device);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <TextField
                autoComplete="off"
                label="Hover text"
                value={channel.channel?.hoverText}
                onChange={handleChange('hoverText')}
              />

              <div className="mt-8">
                <InlineGrid columns={2} gap={'200'}>
                  <ColorPicker
                    iconKey={props.keyValue as ICon}
                    title="Background"
                    id={`icon-background-color-${props.keyValue}`}
                    defaultValue={channel.channel?.backgroundColor}
                    onBlur={handleChange('backgroundColor')}
                  />
                  <ColorPicker
                    title="Icon color"
                    id={`icon-color-${props.keyValue}`}
                    defaultValue={channel.channel?.iconColor}
                    onBlur={handleChange('iconColor')}
                  />
                </InlineGrid>
              </div>
            </div>
            <div className="upload-image mt-8">
              <div className="icon-type">
                <InlineGrid
                  columns={props.variants ? (props.variants.length > 5 ? props.variants.length / 2 : props.variants.length) : 5}
                  gap={'200'}
                >
                  {props.variants?.map((item) => (
                    <span
                      className={item.id === channel.channel?.iconId ? 'icon-type-item icon-type-item-active' : 'icon-type-item'}
                      style={{ cursor: 'pointer' }}
                      key={item.id}
                      onClick={() => handleChangeIconId(item.id)}
                    >
                      {item.element}
                    </span>
                  ))}
                </InlineGrid>
              </div>

              <UploadImage url={url} setFile={handleUploadFile}></UploadImage>
            </div>
            <div className="mt-8">{props.expandActionSettings}</div>
          </BaseCollapsible>
        </div>
      </div>

      <Modal
        open={modalConfirmRemove}
        title="Are you sure?"
        onClose={() => setModalConfirmRemove(false)}
        sectioned
        footer={
          <div className="content-right" style={{ width: isMobile ? 'calc(100vw - 3rem)' : '588px' }}>
            <Button onClick={() => setModalConfirmRemove(false)}>No</Button>
            <div className="ml-8">
              <Button tone="critical" variant="primary" onClick={handleRemove}>
                Yes
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>
          Do you want to remove the{' '}
          <b>{listIconDefault.find((item) => item.keyValue === props.keyValue)?.label || 'Custom link'}</b> button from the
          widget?
        </RegularText>
      </Modal>

      <ModalAgent
        buttonId={channel.channel?.id}
        channel={props.keyValue}
        open={isOpenModalAddAgent}
        handleClose={() => {
          setIsOpenModalAddAgent(false);
          setAgentSelected(undefined);
        }}
        value={agentSelected}
      />
      <ModalConfirm
        isOpen={isOpenModalRemoveAgent}
        onClose={() => {
          setIsOpenModalRemoveAgent(false);
          setAgentSelected(undefined);
        }}
        onConfirm={handleConfirmRemoveAgent}
        isLoading={false}
        title="Confirm remove agent"
      />
    </ChannelButtonStyled>
  );
};

export default memo(ChannelsButton);
