import { DayOfTheWeek } from './enum';

export const dayOfWeekOptions: Array<{ label: string; value: string }> = [
  {
    label: 'Sunday',
    value: DayOfTheWeek.Sunday.toString(),
  },
  {
    label: 'Monday',
    value: DayOfTheWeek.Monday.toString(),
  },
  {
    label: 'Tuesday',
    value: DayOfTheWeek.Tuesday.toString(),
  },
  {
    label: 'Wednesday',
    value: DayOfTheWeek.Wednesday.toString(),
  },
  {
    label: 'Thursday',
    value: DayOfTheWeek.Thursday.toString(),
  },
  {
    label: 'Friday',
    value: DayOfTheWeek.Friday.toString(),
  },
  {
    label: 'Saturday',
    value: DayOfTheWeek.Saturday.toString(),
  },
];
