import { colors } from '@/constants/colors';
import styled from 'styled-components';
export const StepFourStyled = styled.div`
  .turned-off {
    display: flex;
    align-items: center;
  }
  .trigger-settings-container {
    background-color: ${colors.gray_background};
    padding: 1rem;
  }
  .display-after {
    display: flex;
    line-height: 2rem;
  }
  .text-filed {
    width: 100px;
    margin: 0 0.5rem;
  }
  .show-widget {
    display: flex;
    background-color: ${colors.gray_background};
    padding: 1rem;
    align-items: flex-end;
    border-radius: 0.5rem;

    .Polaris-Choice {
      height: 2rem;
      margin-right: 1rem;
    }
  }
  .link-about {
    margin-left: 20px;
    .select-rule {
      width: 160px;
      Select {
        padding-left: 10px;
      }
    }
  }
`;
