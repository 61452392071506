import { convertTimeZone } from '@/helpers/time';
import { IParamsApi } from '@/types/apis/params';

export const workingTimeFilter = (agents: IParamsApi.IAgent[], timezone: string) => {
  const result = agents.filter((agent) => {
    if (!agent.startTime || !agent.endTime) {
      return true;
    }
    const agentStartTime = agent.startTime || '00:00';
    const agentEndTime = agent.endTime || '23:59';
    const now = convertTimeZone(new Date(), timezone);
    const startTime = new Date(now);
    const endTime = new Date(now);
    const [hourStart, minuteStart] = agentStartTime.split(':');
    const [hourEnd, minuteEnd] = agentEndTime.split(':');
    startTime.setHours(Number(hourStart), Number(minuteStart), 0);
    endTime.setHours(Number(hourEnd), Number(minuteEnd), 0);
    if (startTime.getTime() > endTime.getTime()) {
      endTime.setDate(now.getDate() + 1);
    }
    return startTime.getTime() < now.getTime() && endTime.getTime() > now.getTime();
  });
  return result;
};
