import { customizeSelected } from '@/redux/slice/widget.slice';
import { memo } from 'react';
import { useSelector } from 'react-redux';

const CustomWidgetIcon = () => {
  const customize = useSelector(customizeSelected);
  return (
    <img
      alt=""
      style={{ width: `${40}px`, height: `${40}px`, borderRadius: customize.buttonStyle, backgroundColor: customize.groupColor }}
      src={customize.groupIcon.url}
    />
  );
};

export default memo(CustomWidgetIcon);
