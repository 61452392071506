import { WidgetIconFour } from '@/components/Icons/WidgetIconFour';
import { WidgetIconOne } from '@/components/Icons/WidgetIconOne';
import { WidgetIconThree } from '@/components/Icons/WidgetIconThree';
import { WidgetIconTwo } from '@/components/Icons/WidgetIconTwo';
import { WidgetIcon } from '@/constants/enum';

export const useWidgetIcon = () => {
  const groupIcon: { key: WidgetIcon; icon: JSX.Element }[] = [
    { key: WidgetIcon.WidgetIconChatBase, icon: <WidgetIconOne /> },
    { key: WidgetIcon.WidgetIconChatSmile, icon: <WidgetIconTwo /> },
    { key: WidgetIcon.WidgetIconChatBubble, icon: <WidgetIconThree /> },
    { key: WidgetIcon.WidgetIconChatDb, icon: <WidgetIconFour /> },
  ];
  return {
    groupIcon,
  };
};
