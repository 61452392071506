import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Vkontakte = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: Vk_username."
      tooltip={`Username for the VK account (part of the web page address). For "vk.com/example " the username is "example"`}
    />
  ) : null;
};
export default memo(Vkontakte);
