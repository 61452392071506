import { colors } from '@/constants/colors';
import styled from 'styled-components';

export const AnalyticsStyled = styled.div`
  .analytics-container {
    padding: 1rem;
    background-color: ${colors.gray_background};
  }
  .analytics-items-title {
    display: flex;
    .Polaris-Icon {
      margin: 0;
      margin-right: 0.25rem;
    }
  }
  .poiter {
    cursor: pointer;
  }
  .overview-header {
    display: flex;
    justify-content: space-between;
  }
  .disabled-element {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    filter: blur(1px);
  }
  .wrap-btn-upgrade .Polaris-Button {
    text-decoration: underline;
  }
`;
