import { images } from '@/asset';
import { Ga4Event } from '@/constants/enum';

export const configRecommend = [
  {
    title: 'S: Estimated Delivery Date ETA',
    description: 'Reduces shipping date inquiries, increases customer satisfaction by showing estimated delivery date',
    logo: images.estimated_delivery,
    href: 'https://apps.shopify.com/omega-estimated-shipping-date?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
    banner: images.banner_estimated_delivery,
    star: '4.8',
    plan: 'Free to install',
    // marketingBanner: 'ads_order_tracking',
    hasBFS: true,
    ga4Event: Ga4Event.EstimatedClick,
  },
  {
    title: 'Synctrack: Returns & Exchanges',
    hasBFS: true,
    brandURL: 'https://apps.shopify.com/partners/omegaapps',
    description: 'Streamline returns, exchanges, refunds to delight customers & retain revenue',
    logo: images.return_drive_app,
    href: 'https://apps.shopify.com/omega-returns-drive?utm_source=fbchat&utm_medium=homeinapp&utm_campaign=crs',
    banner: images.return_drive_banner_app,
    star: '5.0',
    plan: 'Free',
    ga4Event: Ga4Event.ReturnClick,
    // marketingBanner: 'ads_returnsdrive',
  },
];
