import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const InstagramIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="linear-gradient" x1="0.892" y1="0.192" x2="0.128" y2="0.85" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#4a64d5"></stop>
          <stop offset="0.322" stopColor="#9737bd"></stop>
          <stop offset="0.636" stopColor="#f15540"></stop>
          <stop offset="1" stopColor="#fecc69"></stop>
        </linearGradient>
      </defs>
      <rect
        className="color-element"
        x="0"
        y="0"
        width="39"
        height="39"
        fill={fill?.includes('linear-gradient') || !fill ? 'url(#linear-gradient)' : fill}
      ></rect>
      <path
        id="Path_1923"
        data-name="Path 1923"
        d="M13.177,0H5.022A5.028,5.028,0,0,0,0,5.022v8.155A5.028,5.028,0,0,0,5.022,18.2h8.155A5.028,5.028,0,0,0,18.2,13.177V5.022A5.028,5.028,0,0,0,13.177,0Zm3.408,13.177a3.412,3.412,0,0,1-3.408,3.408H5.022a3.411,3.411,0,0,1-3.408-3.408V5.022A3.412,3.412,0,0,1,5.022,1.615h8.155a3.412,3.412,0,0,1,3.408,3.408v8.155Z"
        transform="translate(10 10.4)"
        fill={iconColor || 'white'}
      ></path>
      <path
        id="Path_1924"
        data-name="Path 1924"
        d="M45.658,40.97a4.689,4.689,0,1,0,4.69,4.69A4.695,4.695,0,0,0,45.658,40.97Zm0,7.764a3.075,3.075,0,1,1,3.075-3.075A3.078,3.078,0,0,1,45.658,48.734Z"
        transform="translate(-26.558 -26.159)"
        fill={iconColor || 'white'}
      ></path>
      <path
        id="Path_1925"
        data-name="Path 1925"
        d="M120.105,28.251a1.183,1.183,0,1,0,.838.347A1.189,1.189,0,0,0,120.105,28.251Z"
        transform="translate(-96.119 -14.809)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
