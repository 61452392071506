import { PATH } from '@/constants/path';
import widgetSlice, { dataSelector, initGroupButton } from '@/redux/slice/widget.slice';
import { Button, Modal, TextField, Tooltip } from '@shopify/polaris';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
interface IProps {
  buttonTitle: string;
  disableCreate?: boolean;
}
const CreateWidgetButton = ({ buttonTitle, disableCreate }: IProps) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  return (
    <>
      {disableCreate ? (
        <Tooltip content="Upgrade for Unlimited widgets">
          <Button variant="primary" icon={PlusCircleIcon} disabled>
            {buttonTitle}
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={() => {
            setIsOpenModal(true);
            dispatch(widgetSlice.actions.handleData(initGroupButton));
            dispatch(widgetSlice.actions.handleDataBackup(initGroupButton));
          }}
          variant="primary"
          icon={PlusCircleIcon}
          disabled={disableCreate}
        >
          {buttonTitle}
        </Button>
      )}
      {!disableCreate ? (
        <Modal
          sectioned
          primaryAction={{
            disabled: !data.name,
            content: 'Create',
            onAction: () => {
              navigate(PATH.NEW_WIDGET);
            },
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: () => {
                setIsOpenModal(false);
                setError('');
              },
            },
          ]}
          title="Create new widget"
          open={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
            setError('');
          }}
        >
          <TextField
            autoComplete="off"
            label="Widget name"
            value={data.name}
            onBlur={() => {
              if (!data.name) {
                setError('Widget name is required');
              }
            }}
            error={error}
            onChange={(name) => {
              setError('');
              dispatch(
                widgetSlice.actions.handleData({
                  ...data,
                  name,
                }),
              );
            }}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default memo(CreateWidgetButton);
