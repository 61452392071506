import { colors } from '@/constants/colors';
import styled from 'styled-components';
export interface IProps {
  borderRadius: string;
}
// svg {
//   box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
// }
export const StepTwoStyled = styled.div<IProps>`
  .widget-icon-header {
    padding-top: 8px;
    svg {
      border-radius: 50%;
      width: 40px !important;
      height: 40px !important;
    }
  }
  .close-icon-container {
    padding: 1.75rem;
    padding-left: 72px;
    background-color: ${colors.bgHover};
    border-radius: 1rem;
    .close-icon {
      width: 40px !important;
      min-width: 40px !important;
      height: 40px !important;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .ml-52 {
      margin-left: 52px;
    }
    .w-100 {
      width: 100%;
    }
  }
  .widget-icon-container {
    display: flex;
    overflow: visible;

    .widget-icon {
      margin-right: 8px;
    }
    .widget-icon-active,
    .widget-icon:hover {
      svg,
      .upload-container {
        border: 2px solid #94d1ff;
      }
    }

    svg,
    .upload-container {
      width: 40px !important;
      height: 40px !important;
      box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 9px 2px, rgba(0, 0, 0, 0.05) 0px 2px 10px 0px;
    }
    .Polaris-Choice__Control {
      margin: 0;
    }
    .Polaris-Choice {
      display: flex;
      flex-direction: column-reverse;
      margin-right: 0.5rem;
    }
    svg {
      border-radius: ${(props) => props.borderRadius};
    }
  }
  .flag-dropdown.open {
    z-index: 21;
  }
`;
