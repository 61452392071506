import { customizeSelected } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';

export const WidgetIconOne = () => {
  const customize = useSelector(customizeSelected);
  const scale = customize.widgetSize;
  const iconColor = customize.groupIconColor;

  return (
    <svg
      width={scale}
      height={scale}
      viewBox="-496 507.7 54 54"
      xmlns="http://www.w3.org/2000/svg"
      svg-inline=""
      role="presentation"
      focusable="false"
      tabIndex={-1}
      className="chat-icon chat-base"
    >
      <rect x="-496" y="507.7" width="54" height="54" fill={customize.groupColor} className="color-fill"></rect>
      <path
        fill={iconColor || 'white'}
        d="M-459.9 523.7h-20.3c-1.9 0-3.4 1.5-3.4 3.4v15.3c0 1.9 1.5 3.4 3.4 3.4h11.4l5.9 4.9c.2.2.3.2.5.2h.3c.3-.2.5-.5.5-.8v-4.2h1.7c1.9 0 3.4-1.5 3.4-3.4v-15.3c0-2-1.5-3.5-3.4-3.5z"
      ></path>
      <path
        fill={customize.groupColor}
        d="M-477.7 530.5h11.9c.5 0 .8.4.8.8 0 .5-.4.8-.8.8h-11.9c-.5 0-.8-.4-.8-.8-.1-.5.3-.8.8-.8zM-477.7 533.5h7.9c.5 0 .8.4.8.8 0 .5-.4.8-.8.8h-7.9c-.5 0-.8-.4-.8-.8-.1-.4.3-.8.8-.8z"
        className="color-fill"
      ></path>
    </svg>
  );
};
