import { ICon } from '@/constants/enum';
import { MessageVariant } from './Message';
import { HomeVariant } from './Home';
import { ScrollTopVariant } from './ScrollTop';
import { CartVariant } from './Cart';

const useVariant = () => {
  return [
    {
      id: ICon.Messenger,
      variant: (size: number, fill?: string, iconColor?: string) => {
        return MessageVariant({ size, fill, iconColor });
      },
    },
    {
      id: ICon.HomePage,
      variant: (size: number, fill?: string, iconColor?: string) => {
        return HomeVariant({ size, fill, iconColor });
      },
    },
    {
      id: ICon.ScrollToTop,
      variant: (size: number, fill?: string, iconColor?: string) => {
        return ScrollTopVariant({ size, fill, iconColor });
      },
    },
    {
      id: ICon.CartPage,
      variant: (size: number, fill?: string, iconColor?: string) => {
        return CartVariant({ size, fill, iconColor });
      },
    },
  ];
};

export default useVariant;
