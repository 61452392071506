import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const SlackIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    // <svg width={scale} height={scale} viewBox={`0 0 50 50`} fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="50" height="50" rx="25" className="color-element" fill={fill || '#350F37'}></rect>{' '}
    //   <g clipPath="url(#clip0_1821_5400)">
    //     {' '}
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M21.7995 13C20.4728 13.001 19.3991 14.0776 19.4001 15.4043C19.3991 16.731 20.4738 17.8076 21.8005 17.8086H24.2009V15.4053C24.2018 14.0786 23.1271 13.002 21.7995 13C21.8005 13 21.8005 13 21.7995 13V13ZM21.7995 19.4128H15.4004C14.0737 19.4138 12.999 20.4904 13 21.8171C12.998 23.1438 14.0727 24.2205 15.3994 24.2224H21.7995C23.1262 24.2214 24.2009 23.1448 24.1999 21.8181C24.2009 20.4904 23.1262 19.4138 21.7995 19.4128V19.4128Z"
    //       className="slack-path"
    //       fill="#36C5F0"
    //     ></path>{' '}
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M36.9997 21.8171C37.0007 20.4904 35.926 19.4138 34.5993 19.4128C33.2726 19.4138 32.1979 20.4904 32.1989 21.8171V24.2224H34.5993C35.926 24.2214 37.0007 23.1448 36.9997 21.8171ZM30.5996 21.8171V15.4043C30.6006 14.0786 29.5269 13.002 28.2002 13C26.8735 13.001 25.7988 14.0776 25.7998 15.4043V21.8171C25.7978 23.1438 26.8725 24.2205 28.1992 24.2224C29.5259 24.2214 30.6006 23.1448 30.5996 21.8171Z"
    //       className="slack-path"
    //       fill="#2EB67D"
    //     ></path>{' '}
    //     <path
    //       className="slack-path"
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M28.2002 37.048C29.5269 37.0471 30.6016 35.9704 30.6006 34.6437C30.6016 33.317 29.5269 32.2404 28.2002 32.2394H25.7998V34.6437C25.7988 35.9694 26.8735 37.0461 28.2002 37.048ZM28.2002 30.6343H34.6003C35.927 30.6333 37.0017 29.5566 37.0007 28.2299C37.0027 26.9033 35.928 25.8266 34.6013 25.8246H28.2012C26.8745 25.8256 25.7998 26.9023 25.8008 28.229C25.7998 29.5566 26.8735 30.6333 28.2002 30.6343V30.6343Z"
    //       fill="#ECB22E"
    //     ></path>{' '}
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M13 28.2299C12.999 29.5566 14.0737 30.6332 15.4004 30.6342C16.7271 30.6332 17.8018 29.5566 17.8008 28.2299V25.8256H15.4004C14.0737 25.8266 12.999 26.9032 13 28.2299ZM19.4001 28.2299V34.6427C19.3981 35.9694 20.4728 37.0461 21.7995 37.048C23.1262 37.0471 24.2009 35.9704 24.1999 34.6437V28.2319C24.2018 26.9052 23.1271 25.8285 21.8005 25.8266C20.4728 25.8266 19.3991 26.9032 19.4001 28.2299C19.4001 28.2309 19.4001 28.2299 19.4001 28.2299Z"
    //       className="slack-path"
    //       fill="#E01E5A"
    //     ></path>{' '}
    //   </g>{' '}
    //   <defs>
    //     {' '}
    //     <clipPath id="clip0_1821_5400">
    //       {' '}
    //       <rect width="24" height="24.048" className="slack-path" fill="white" transform="translate(13 13)"></rect>{' '}
    //     </clipPath>{' '}
    //   </defs>{' '}
    // </svg>

    <svg width={scale} height={scale} viewBox="0 0 50 50" fill={fill || '#350F37'} xmlns="http://www.w3.org/2000/svg">
      <rect width="50" height="50" x="0" y="0" className="color-element"></rect>
      <g clipPath="url(#clip0_1821_5400)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7995 13C20.4728 13.001 19.3991 14.0776 19.4001 15.4043C19.3991 16.731 20.4738 17.8076 21.8005 17.8086H24.2009V15.4053C24.2018 14.0786 23.1271 13.002 21.7995 13C21.8005 13 21.8005 13 21.7995 13V13ZM21.7995 19.4128H15.4004C14.0737 19.4138 12.999 20.4904 13 21.8171C12.998 23.1438 14.0727 24.2205 15.3994 24.2224H21.7995C23.1262 24.2214 24.2009 23.1448 24.1999 21.8181C24.2009 20.4904 23.1262 19.4138 21.7995 19.4128V19.4128Z"
          className="slack-path"
          fill={iconColor || '#36C5F0'}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9997 21.8171C37.0007 20.4904 35.926 19.4138 34.5993 19.4128C33.2726 19.4138 32.1979 20.4904 32.1989 21.8171V24.2224H34.5993C35.926 24.2214 37.0007 23.1448 36.9997 21.8171ZM30.5996 21.8171V15.4043C30.6006 14.0786 29.5269 13.002 28.2002 13C26.8735 13.001 25.7988 14.0776 25.7998 15.4043V21.8171C25.7978 23.1438 26.8725 24.2205 28.1992 24.2224C29.5259 24.2214 30.6006 23.1448 30.5996 21.8171Z"
          className="slack-path"
          fill={iconColor || '#2EB67D'}
        ></path>
        <path
          className="slack-path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2002 37.048C29.5269 37.0471 30.6016 35.9704 30.6006 34.6437C30.6016 33.317 29.5269 32.2404 28.2002 32.2394H25.7998V34.6437C25.7988 35.9694 26.8735 37.0461 28.2002 37.048ZM28.2002 30.6343H34.6003C35.927 30.6333 37.0017 29.5566 37.0007 28.2299C37.0027 26.9033 35.928 25.8266 34.6013 25.8246H28.2012C26.8745 25.8256 25.7998 26.9023 25.8008 28.229C25.7998 29.5566 26.8735 30.6333 28.2002 30.6343V30.6343Z"
          fill={iconColor || '#ECB22E'}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 28.2299C12.999 29.5566 14.0737 30.6332 15.4004 30.6342C16.7271 30.6332 17.8018 29.5566 17.8008 28.2299V25.8256H15.4004C14.0737 25.8266 12.999 26.9032 13 28.2299ZM19.4001 28.2299V34.6427C19.3981 35.9694 20.4728 37.0461 21.7995 37.048C23.1262 37.0471 24.2009 35.9704 24.1999 34.6437V28.2319C24.2018 26.9052 23.1271 25.8285 21.8005 25.8266C20.4728 25.8266 19.3991 26.9032 19.4001 28.2299C19.4001 28.2309 19.4001 28.2299 19.4001 28.2299Z"
          className="slack-path"
          fill={iconColor || '#E01E5A'}
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1821_5400">
          <rect width="24" height="24.048" className="slack-path" fill={iconColor || 'white'} transform="translate(13 13)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
