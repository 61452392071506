import { colors } from '@/constants/colors';
import styled from 'styled-components';
export const WidgetsStyled = styled.div`
  .widgets-controller {
    display: flex;
    justify-content: space-between;
  }
  .preview-icon-container {
    margin-top: 0.5rem;
    & > div {
      margin-left: -0.25rem;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        z-index: 1;
      }
    }
    svg,
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    .preview-icon-item {
      margin-left: -0.35rem;
      position: relative;
      &:hover {
        z-index: 1;
      }
      svg {
        border-radius: 50%;
      }
    }
    .preview-icon-plus {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: #a5a5a5 solid 0.5px;
      line-height: 30px !important;
      text-align: center;
      display: flex;
      align-items: center;
      background-color: white;
      cursor: default;
      .Polaris-Text--root {
        width: 25px;
      }
    }
  }
  .btn-container {
    width: 100px;
    height: 25px;
    align-items: center;
    .control-btn {
      display: none;
    }
    .control-btn-toggle {
      display: block;
    }
    .remove-btn:hover {
      svg {
        fill: ${colors.critical};
      }
    }
  }
  .Polaris-IndexTable__TableRow--hovered {
    .control-btn {
      display: flex;
    }
  }
  .paginate {
    display: flex;
    justify-content: center;
  }
  .table-analytics {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-left: 0.25rem;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .Polaris-Icon {
      margin: 0;
    }
  }
`;
