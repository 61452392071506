import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Slack = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https://workspace.slack.com"
      tooltip={`Add your Slack workspace link. E.g., https://workspace.slack.com`}
    />
  ) : null;
};
export default memo(Slack);
