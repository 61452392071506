import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const ContactFormIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 48 48" fill={fill || '#253974'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="48" height="48"></rect>
      <path
        d="M34.5002 12.334H17.0002C16.3813 12.334 15.7878 12.5798 15.3502 13.0174C14.9127 13.455 14.6668 14.0485 14.6668 14.6673V18.1673H12.3335V20.5007H14.6668V22.834H12.3335V25.1673H14.6668V27.5007H12.3335V29.834H14.6668V33.334C14.6668 33.9528 14.9127 34.5463 15.3502 34.9839C15.7878 35.4215 16.3813 35.6673 17.0002 35.6673H34.5002C34.8096 35.6673 35.1063 35.5444 35.3251 35.3256C35.5439 35.1068 35.6668 34.8101 35.6668 34.5007V13.5007C35.6668 13.1912 35.5439 12.8945 35.3251 12.6757C35.1063 12.4569 34.8096 12.334 34.5002 12.334ZM25.1668 15.8328C27.0895 15.8328 28.6668 17.409 28.6668 19.3328C28.6635 20.2601 28.2937 21.1485 27.638 21.8044C26.9824 22.4602 26.0942 22.8303 25.1668 22.834C23.2453 22.834 21.6668 21.2555 21.6668 19.3328C21.6668 17.409 23.2453 15.8328 25.1668 15.8328ZM32.1668 31.0007H18.1668V30.1257C18.1668 27.5368 21.3227 24.8757 25.1668 24.8757C29.011 24.8757 32.1668 27.5368 32.1668 30.1257V31.0007Z"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
