import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const SMS = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: +840145236987"
      tooltip="Add your full phone number with country code. E.g., +840145236987"
    />
  ) : null;
};
export default memo(SMS);
