import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = {
  isVisibleThinkTodos: true,
  isDoneAllSteps: false,
};

export const thinksToDoSlice = createSlice({
  name: 'thinksToDo',
  initialState,
  reducers: {
    handleVisibleThinksToDo: (
      state,
      action: PayloadAction<{
        key: keyof typeof initialState;
        value: boolean;
      }>,
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const isVisibleThinkTodos = createSelector(
  (state: RootState) => state.thinksTodo,
  (state) => state.isVisibleThinkTodos,
);

export const isDoneAllSteps = createSelector(
  (state: RootState) => state.thinksTodo,
  (state) => state.isDoneAllSteps,
);

export default thinksToDoSlice;
