import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const Viber = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: +18006927753"
      tooltip="Enter your full phone number that you registered with Viber. E.g., +18006927753"
    />
  ) : null;
};
export default memo(Viber);
