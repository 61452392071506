import { getSomeDaysAgo } from '@/helpers/time';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface IHomeData {
  startDate: Date;
  endDate: Date;
}

const now = new Date();
// Define the initial state using that type
const initialState: {
  homeData: IHomeData;
  titleBtnDatePicker: string;
} = {
  homeData: {
    startDate: getSomeDaysAgo(7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
  },
  titleBtnDatePicker: 'Last 7 days',
};

export const homeSlice = createSlice({
  name: 'home',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleData: (state, action: PayloadAction<IHomeData>) => {
      state.homeData = action.payload;
    },
    handleTitleBtnDatePicker: (state, action: PayloadAction<string>) => {
      state.titleBtnDatePicker = action.payload;
    },
  },
});

// Other code such as selectors can use the imported `RootState` type

export const homeDataSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.homeData,
);
export const titleBtnDatePickerSelector = createSelector(
  (state: RootState) => state.home,
  (state) => state.titleBtnDatePicker,
);

export default homeSlice;
