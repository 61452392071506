import { TextField, TextFieldProps } from '@shopify/polaris';
import { memo } from 'react';
import { TimePickerStyled } from './styled';

interface IProps extends Omit<TextFieldProps, 'autoComplete' | 'type' | 'advance'> {}

const TimePicker = (props: IProps) => {
  return (
    <TimePickerStyled>
      <TextField {...props} autoComplete="off" type="time" />
    </TimePickerStyled>
  );
};

export default memo(TimePicker);
