import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const Discord = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;
  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https://discord.gg/invite_link"
      tooltip={`Add your Discord invitation link E.g., https://discord.gg/invite_link`}
    />
  ) : null;
};
export default memo(Discord);
