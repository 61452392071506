import { colors } from '@/constants/colors';
import styled from 'styled-components';
interface Props {
  isOpen: boolean;
}

export const StepContainer = styled.div<Props>`
  .content-container {
    background-color: ${(props) => (props.isOpen ? colors.gray_background : '')};
    border: none;
    outline: none;
    &:hover {
      background-color: ${colors.gray_background};
    }
    padding: 8px;
    border-radius: 8px;
    min-height: 45px;
    margin-top: 8px;
    .header {
      cursor: pointer;
      &.header-disabled {
        cursor: default;
        color: ${colors.text_disabled};
      }

      .header-title {
        display: flex;
        align-items: center;
      }

      .header-icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px dashed gray;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        transition: border-color 0.3s ease 0s;
        margin-right: 8px;
      }
    }
    .collapsible-content {
      margin-left: 28px;
    }
  }
`;
