import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const PoptinPopups = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https//app.popt.in/APIRequest"
      tooltip="You can create a free poptin account by clicking here, and use it to create popups and forms on your site. You can also learn how to use Poptin and Chaty here"
    />
  ) : null;
};
export default memo(PoptinPopups);
