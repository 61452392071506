import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const Line = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https//line.me/ti/p/2a-s5A2B8B"
      tooltip="Add your full profile link of Line. E.g., http://line.me/ti/p/2a-s5A2B8B"
    />
  ) : null;
};
export default memo(Line);
