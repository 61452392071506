import { DropZone, Icon, Text } from '@shopify/polaris';
import { AlertCircleIcon, UploadIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { StyledUploadedFiles } from './styled';
import { useSelector } from 'react-redux';
import { customizeSelected } from '@/redux/slice/widget.slice';
import RegularText from '../RegularText';
import { fileToBase64 } from '@/helpers/file';
import { IUpload } from '@/types/components/upload';

interface IProps {
  setFile: (value: IUpload) => void;
  url?: string;
  label?: string;
  labelHidden?: boolean;
}

function UploadImage({ setFile, url, label, labelHidden }: IProps) {
  const customize = useSelector(customizeSelected);
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
      const uploadedFile = acceptedFiles[0];
      if (uploadedFile) {
        fileToBase64(uploadedFile).then((res) => {
          setFile({
            file: res as string,
            url: window.URL.createObjectURL(uploadedFile),
          });
        });
      }
    },
    [setFile],
  );
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

  const validateFile = (file: any) => {
    setIsShowErrorInline({ status: false, msg: '' });
    if (file.size > 100000) {
      setIsShowErrorInline({ status: true, msg: 'File too large' });
    } else if (!validImageTypes.includes(file.type)) {
      setIsShowErrorInline({ status: true, msg: 'Unsupported format' });
    } else {
      setIsShowErrorInline({ status: false, msg: '' });
    }
    return file.size < 100000 && validImageTypes.includes(file.type);
  };

  return (
    <StyledUploadedFiles borderRadius={customize.buttonStyle}>
      <DropZone
        customValidator={validateFile}
        type="image"
        accept={validImageTypes.toString()}
        label={label || 'Custom image'}
        labelHidden={labelHidden}
        onDrop={handleDropZoneDrop}
        overlayText=""
      >
        <div className="upload-container">{url ? <img alt="" src={url} /> : <Icon source={UploadIcon} />}</div>
      </DropZone>
      <div className="mt-8">
        <RegularText>Supported formats: JPG, JPEG, GIF, and PNG. Maximum 100KB</RegularText>
      </div>
      {isShowErrorInline.status ? (
        <div className="mt-8 d-flex provide-evidence">
          <Icon source={AlertCircleIcon} tone="critical" />
          <Text as="span" variant="bodyLg" tone="critical">
            {isShowErrorInline.msg}
          </Text>
        </div>
      ) : null}
    </StyledUploadedFiles>
  );
}

export default memo(UploadImage);
