import ContactForm from '@/components/ChannelsButton/ContactForm';
import CustomLink from '@/components/ChannelsButton/CustomLink';
import Discord from '@/components/ChannelsButton/Discord';
import Email from '@/components/ChannelsButton/Email';
import GoToCard from '@/components/ChannelsButton/GoToCard';
import GoToHome from '@/components/ChannelsButton/GoToHome';
import GoogleMap from '@/components/ChannelsButton/GoogleMap';
import Instagram from '@/components/ChannelsButton/Instagram';
import Line from '@/components/ChannelsButton/Line';
import Linkedin from '@/components/ChannelsButton/Linkedin';
import Messenger from '@/components/ChannelsButton/Messenger';
import Phone from '@/components/ChannelsButton/Phone';
import PoptinPopups from '@/components/ChannelsButton/PoptinPopups';
import SMS from '@/components/ChannelsButton/SMS';
import ScrollToTop from '@/components/ChannelsButton/ScrollToTop';
import Skype from '@/components/ChannelsButton/Skype';
import Slack from '@/components/ChannelsButton/Slack';
import Snapchat from '@/components/ChannelsButton/Snapchat';
import Teams from '@/components/ChannelsButton/Teams';
import Telegram from '@/components/ChannelsButton/Telegram';
import TikTok from '@/components/ChannelsButton/TikTok';
import Twitter from '@/components/ChannelsButton/Twitter';
import Viber from '@/components/ChannelsButton/Viber';
import Vkontakte from '@/components/ChannelsButton/Vkontakte';
import Waze from '@/components/ChannelsButton/Waze';
import WeChat from '@/components/ChannelsButton/WeChat';
import WhatsApp from '@/components/ChannelsButton/WhatsApp';
import Zalo from '@/components/ChannelsButton/Zalo';
import { ICon } from '@/constants/enum';
import widgetSlice, { channelsSeletedSelector, errorSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useChannelsButton = () => {
  const channelsSelected = useSelector(channelsSeletedSelector);
  const error = useSelector(errorSelector);
  const dispatch = useDispatch();
  const handleValidateInput = (hasValue: boolean, channel: ICon) => {
    if (!hasValue) {
      const newIcons = [...(error.icon || [])];
      newIcons.push(channel);
      dispatch(
        widgetSlice.actions.handleError({
          ...error,
          icon: newIcons,
        }),
      );
    } else {
      const isExistedError = error.icon?.includes(channel);
      if (isExistedError) {
        const newIcons = error.icon?.filter((icon) => icon !== channel);
        dispatch(
          widgetSlice.actions.handleError({
            ...error,
            icon: newIcons,
          }),
        );
      }
    }
  };

  const handleChangeCustomChannelsValue = useCallback(
    (value: IParamsApi.IChannelButton, id: number) => {
      handleValidateInput(!!value.value, (ICon.CustomLink + ` ${id}`) as ICon);
      const channelsValueArr = [...channelsSelected];
      const index = channelsValueArr.findIndex((item) => item.customLinkId === id);
      if (index !== -1) {
        channelsValueArr[index] = value;
      } else {
        channelsValueArr.push(value);
      }
      dispatch(widgetSlice.actions.handleChannelsSelected(channelsValueArr));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(channelsSelected), JSON.stringify(error)],
  );
  const listButton = [
    {
      label: ICon.Whatsapp,
      icon: <WhatsApp keyValue={ICon.Whatsapp} />,
    },
    {
      label: ICon.Messenger,
      icon: <Messenger keyValue={ICon.Messenger} />,
    },
    {
      label: ICon.Phone,
      icon: <Phone keyValue={ICon.Phone} />,
    },
    {
      label: ICon.Email,
      icon: <Email keyValue={ICon.Email} />,
    },
    {
      label: ICon.Instagram,
      icon: <Instagram keyValue={ICon.Instagram} />,
    },
    {
      label: ICon.Sms,
      icon: <SMS keyValue={ICon.Sms} />,
    },
    {
      label: ICon.ContactForm,
      icon: <ContactForm keyValue={ICon.ContactForm} />,
    },
    {
      label: ICon.PoptinPopups,
      icon: <PoptinPopups keyValue={ICon.PoptinPopups} />,
    },
    {
      label: ICon.Line,
      icon: <Line keyValue={ICon.Line} />,
    },
    {
      label: ICon.Telegram,
      icon: <Telegram keyValue={ICon.Telegram} />,
    },
    {
      label: ICon.GoogleMap,
      icon: <GoogleMap keyValue={ICon.GoogleMap} />,
    },
    {
      label: ICon.Viber,
      icon: <Viber keyValue={ICon.Viber} />,
    },
    {
      label: ICon.Twitter,
      icon: <Twitter keyValue={ICon.Twitter} />,
    },
    {
      label: ICon.WeChat,
      icon: <WeChat keyValue={ICon.WeChat} />,
    },
    {
      label: ICon.SnapChat,
      icon: <Snapchat keyValue={ICon.SnapChat} />,
    },
    {
      label: ICon.TikTok,
      icon: <TikTok keyValue={ICon.TikTok} />,
    },
    {
      label: ICon.Skype,
      icon: <Skype keyValue={ICon.Skype} />,
    },
    {
      label: ICon.Waze,
      icon: <Waze keyValue={ICon.Waze} />,
    },
    {
      label: ICon.LinkedIn,
      icon: <Linkedin keyValue={ICon.LinkedIn} />,
    },
    {
      label: ICon.Vkontakte,
      icon: <Vkontakte keyValue={ICon.Vkontakte} />,
    },
    {
      label: ICon.Slack,
      icon: <Slack keyValue={ICon.Slack} />,
    },
    {
      label: ICon.Discrod,
      icon: <Discord keyValue={ICon.Discrod} />,
    },
    {
      label: ICon.Teams,
      icon: <Teams keyValue={ICon.Teams} />,
    },
    {
      label: ICon.Zalo,
      icon: <Zalo keyValue={ICon.Zalo} />,
    },
    {
      label: ICon.ScrollToTop,
      icon: <ScrollToTop keyValue={ICon.ScrollToTop} />,
    },
    {
      label: ICon.HomePage,
      icon: <GoToHome keyValue={ICon.HomePage} />,
    },
    {
      label: ICon.CartPage,
      icon: <GoToCard keyValue={ICon.CartPage} />,
    },
  ];
  const customLink = {
    label: ICon.CustomLink,
    icon: (channel: IParamsApi.IChannelButton) => {
      return (
        <CustomLink
          channel={channel}
          keyValue={`${ICon.CustomLink} ${channel.customLinkId}`}
          onBlur={(value) => {
            handleChangeCustomChannelsValue(value, channel.customLinkId || 0);
          }}
        />
      );
    },
  };

  return {
    listButton,
    customLink,
  };
};
