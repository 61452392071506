import RenderRouter from '@/routes';
import { AppProvider } from '@shopify/polaris';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from './config';
import { NavigationMenu, Provider } from '@shopify/app-bridge-react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { PATH } from './constants/path';

const navigationLinks: Array<{
  label: string;
  destination: string;
}> = [
  {
    label: 'Chat widget',
    destination: PATH.WIDGETS.pathname,
  },
  {
    label: 'Analytics',
    destination: PATH.ANALYTICS.pathname,
  },
  {
    label: 'Form answer',
    destination: PATH.FORM_ANSWER.pathname,
  },
  {
    label: 'Pricing plan',
    destination: PATH.PRICING_PLAN.pathname,
  },
];

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const history = useMemo(() => ({ replace: (path: string) => navigate(path, { replace: true }) }), [navigate]);
  const configApp = {
    apiKey: process.env.REACT_APP_API_KEY || '',
    host: new URLSearchParams(location.search).get('host') || '',
    forceRedirect: process.env.REACT_APP_MODE === 'live',
  };

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history],
  );

  useEffect(() => {
    if (config && config.role !== 'admin' && config.embedded === '1') {
      const timer = setTimeout(() => {
        var crisp = document.createElement('script');
        crisp.setAttribute('src', `${process.env.REACT_APP_API_END_POINT}public/script/plugin.js?shop=${config.shop}`);
        crisp.setAttribute('id', 'omega-chat-crisp');
        crisp.appendChild(document.createTextNode(``));
        document.body.appendChild(crisp);
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppProvider i18n={enTranslations}>
      {config.embedded === '1' ? (
        <Provider config={configApp} router={router}>
          <NavigationMenu
            navigationLinks={navigationLinks}
            matcher={(link, _) => link.destination === location.pathname.split('/')[1]}
          />
          <RenderRouter />
        </Provider>
      ) : (
        <RenderRouter />
      )}
    </AppProvider>
  );
}

export default App;
