import dayjs from 'dayjs';
export const dateToTimeStamp = (date: Date, isOrigin?: boolean) => {
  return isOrigin ? +date : dayjs(date).unix() * 1000;
};
export const getSomeDaysAgo = (day: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() - day);
  date.setHours(0, 0, 0);
  return date;
};

export const getLastMonth = () => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setMonth(firstDate.getMonth() - 1);
  const lastDate = new Date();
  lastDate.setDate(0);
  return {
    start: firstDate,
    end: lastDate,
  };
};
export const getLastSomesMonth = (month: number) => {
  const firtDate = new Date();
  firtDate.setDate(1);
  firtDate.setMonth(firtDate.getMonth() - month);
  const lastDate = new Date();
  lastDate.setMonth(lastDate.getMonth() - month + 1);
  lastDate.setDate(0);
  return {
    start: firtDate,
    end: lastDate,
  };
};

export const formatDate = (date: number | Date, format?: string) => {
  return dayjs(typeof date === 'number' ? date * 1000 : date).format(format ? format : 'D MMM YYYY, h:mm a');
};

export const getTodayTimeStepFromHour = (time: string) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();

  const timeString = time;
  const [hours, minutes] = timeString.split(':').map(Number);

  // Create a new Date object with today's date and the specified time
  return new Date(year, month, day, hours, minutes).getTime();
};

export function convertTimeZone(date: Date, timeZoneOffset?: string): Date {
  let now;
  let newDate;
  if (!timeZoneOffset) {
    newDate = new Date();
  } else {
    now = date;
    const offsetInHours = date.getTimezoneOffset() / 60;
    const gmtIndex = timeZoneOffset?.indexOf('GMT');
    const timezone = timeZoneOffset?.substring(gmtIndex, gmtIndex + 9).slice(-6);
    const firstChar = timezone.charAt(0);
    const hourOffset = Number(timezone.split(':')[0]);
    let minutesOffset = Number(timezone.split(':')[1]);
    if (firstChar === '-') {
      minutesOffset = minutesOffset * -1;
    }
    // Tính toán thời gian theo múi giờ mới
    const offsetMillis = ((hourOffset + offsetInHours) * 60 + minutesOffset) * 60 * 1000;
    newDate = new Date(now.getTime() + offsetMillis);
  }
  return newDate;
}
