import BaseTable from '@/components/BaseTable';
import BoldText from '@/components/BoldText';
import DatePicker from '@/components/DatePicker';
import ExpandData from '@/components/ExpandData';
import Layout from '@/components/layout';
import { SortDirection } from '@/constants/enum';
import { checkShowErrorInline, handleToastMutation } from '@/helpers';
import { formatDate } from '@/helpers/time';
import { apiCaller } from '@/redux/query';
import formAnswerSlice, {
  IDashFormAnswerData,
  formAnswerDataSelector,
  titleBtnDatePickerSelector,
} from '@/redux/slice/formAnswer.slice';
import toastSlice from '@/redux/slice/toast.slice';
import {
  Button,
  ChoiceList,
  IndexFilters,
  IndexFiltersProps,
  IndexTable,
  LegacyCard,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormAnswerStyled } from './styled';
import ModalConfirm from '@/components/ModalConfirm';

interface IDataRender {
  id: string;
  value: string;
}

export default function FormAnswer() {
  const { mode, setMode } = useSetIndexFiltersMode();
  const formAnswer = useSelector(formAnswerDataSelector);
  const titleBtnDatePicker = useSelector(titleBtnDatePickerSelector);
  const dispatch = useDispatch();
  const { data: dataButtonsRes, isLoading: dataButtonsLoading } = apiCaller.useGetAllButtonsQuery();
  const [buttonGroupOptions, setButtonGroupOptions] = useState<{ value: string; label: string }[]>([]);
  const [queryValue, setQueryValue] = useState<string>('');
  const [isChangeQueryValue, setIsChangeQueryValue] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<{ id?: number; isOpen: boolean }>({ isOpen: false });
  const { data, isFetching, isLoading } = apiCaller.useGetListsFormAnswerQuery({
    buttonId: formAnswer.buttonId ? +formAnswer.buttonId : undefined,
    endTime: new Date(formAnswer.endDate).getTime(),
    startTime: new Date(formAnswer.startDate).getTime(),
    page: formAnswer.page + 1,
    perPage: formAnswer.perPage,
    sortDirection: formAnswer.sortDirection,
    value: formAnswer.value || undefined,
  });

  const handleUpdateFilter = useCallback(
    (value: IDashFormAnswerData) => {
      dispatch(formAnswerSlice.actions.handleData(value));
    },
    [dispatch],
  );

  const [handleDeleteFormAnswer, { isLoading: isDeleting }] = apiCaller.useDeleteFormAnswerMutation();

  useEffect(() => {
    if (dataButtonsRes) {
      const optionButtonGroups = dataButtonsRes?.data?.map((button) => ({
        value: button.buttonId + '',
        label: button.groupName,
      }));
      setButtonGroupOptions(optionButtonGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataButtonsRes]);

  const handleGroupButtonChange = useCallback(
    (value: string[]) => {
      handleUpdateFilter({ ...formAnswer, buttonId: value[0], page: 0 });
    },
    [handleUpdateFilter, formAnswer],
  );
  const handleOrderDirectionChange = useCallback(
    (value: SortDirection) => {
      handleUpdateFilter({ ...formAnswer, sortDirection: value, page: 0 });
    },
    [handleUpdateFilter, formAnswer],
  );
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    setIsChangeQueryValue(true);
  }, []);
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      isChangeQueryValue && handleUpdateFilter({ ...formAnswer, value: queryValue, page: 0 });
      setIsChangeQueryValue(false);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryValue), JSON.stringify(handleUpdateFilter), JSON.stringify(formAnswer)]);

  const handleButtonGroupRemove = useCallback(() => {
    handleUpdateFilter({ ...formAnswer, buttonId: undefined, page: 0 });
  }, [handleUpdateFilter, formAnswer]);

  const handleSortRemove = useCallback(() => {
    handleUpdateFilter({ ...formAnswer, sortDirection: SortDirection.Desc, page: 0 });
  }, [handleUpdateFilter, formAnswer]);

  const sortOptions: IndexFiltersProps['sortOptions'] = [
    { label: 'Created At', value: 'createdAt asc', directionLabel: 'Ascending' },
    { label: 'Created At', value: 'createdAt desc', directionLabel: 'Descending' },
  ];

  const filters = [
    {
      key: 'color',
      label: 'Button groups',
      filter: (
        <ChoiceList
          title="Button groups"
          titleHidden
          choices={buttonGroupOptions}
          selected={[formAnswer.buttonId || '']}
          onChange={handleGroupButtonChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (formAnswer.buttonId) {
    const key = 'buttonGroups';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, [formAnswer.buttonId]),
      onRemove: handleButtonGroupRemove,
    });
  }
  if (formAnswer.sortDirection) {
    const key = 'orderDirection';
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, [formAnswer.sortDirection]),
      onRemove: handleSortRemove,
    });
  }

  function disambiguateLabel(key: string, value: string[]): string {
    switch (key) {
      case 'buttonGroups':
        return value
          .map((val) => {
            const selectedGroup = buttonGroupOptions.find((option) => option.value === val)?.label || '';
            return `Button groups: ${selectedGroup}`;
          })
          .toString();
      default:
        return value.toString();
    }
  }

  const handleOpenModalDelete = (id: number) => {
    setModalDelete({
      isOpen: true,
      id,
    });
  };

  const handleConfirmDelete = () => {
    handleDeleteFormAnswer({ id: modalDelete.id as number }).then((res) => {
      const condition = checkShowErrorInline(res);
      if (!condition.status) {
        dispatch(toastSlice.actions.handleToast(handleToastMutation(res)));
      }
      setModalDelete({
        id: 0,
        isOpen: false,
      });
    });
  };

  const rowMarkup = useMemo(() => {
    return data?.data?.map((dataAnswer, index) => {
      if (dataAnswer) {
        const dataRender: IDataRender[] = [];
        const answer = JSON.parse(dataAnswer.answer);
        for (let key in answer) {
          dataRender.push({ id: `${dataAnswer.id}-${dataAnswer.createdAt}-${key}`, value: `${key}: ${answer[key]}` });
        }
        return (
          <IndexTable.Row id={index.toString()} key={dataAnswer.id} position={index}>
            <IndexTable.Cell className="name-cell">{<BoldText>{dataAnswer?.groupName}</BoldText>}</IndexTable.Cell>
            <IndexTable.Cell className="name-cell">{formatDate(dataAnswer?.createdAt)}</IndexTable.Cell>
            <IndexTable.Cell className="answer-cell">
              <ExpandData dataRender={dataRender} numberRowsDefaultDisplay={3} rowId={dataAnswer.id} />
            </IndexTable.Cell>
            <IndexTable.Cell className="actions-cell">
              {
                <div className="text-center btn-action">
                  <Button onClick={() => handleOpenModalDelete(dataAnswer.id)} variant="plain" icon={DeleteIcon}></Button>
                </div>
              }
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
      return null;
    });
  }, [data?.data]);

  const changePage = (page: number) => {
    handleUpdateFilter({ ...formAnswer, page });
  };

  return (
    <Layout title="Form Answer">
      <FormAnswerStyled>
        <div className="form-a-controller mb-8">
          <DatePicker
            titleButton={titleBtnDatePicker}
            setTitleButton={(value: string) => dispatch(formAnswerSlice.actions.handleTitleBtnDatePicker(value))}
            startDate={formAnswer.startDate}
            endDate={formAnswer.endDate}
            onSave={(start, end) =>
              dispatch(
                formAnswerSlice.actions.handleData({
                  ...formAnswer,
                  endDate: end,
                  startDate: start,
                  page: 0,
                }),
              )
            }
          />
        </div>
        <div>
          <LegacyCard>
            <IndexFilters
              cancelAction={{ onAction: () => {} }}
              sortOptions={sortOptions}
              sortSelected={[`createdAt ${formAnswer.sortDirection.toLowerCase()}`]}
              queryValue={queryValue}
              queryPlaceholder="Searching in all"
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={() => setQueryValue('')}
              onSort={(value) => {
                const direction = value[0].split(' ')[1];
                handleOrderDirectionChange(direction.toUpperCase() as SortDirection);
              }}
              tabs={[]}
              selected={0}
              onSelect={() => {}}
              filters={filters}
              appliedFilters={appliedFilters}
              onClearAll={() => {
                handleUpdateFilter({ ...formAnswer, buttonId: undefined, sortDirection: SortDirection.Desc, page: 0 });
              }}
              mode={mode}
              setMode={setMode}
            ></IndexFilters>
            <BaseTable
              style={{ padding: 0 }}
              headings={[
                { title: 'Widget name' },
                { title: 'Created at' },
                { title: 'Answer' },
                { title: 'Actions', alignment: 'center' },
              ]}
              isLoading={isLoading || dataButtonsLoading}
              itemCount={data?.data?.length || 0}
              isFetching={isFetching}
              onChangePage={changePage}
              page={formAnswer.page}
              rowMarkup={rowMarkup}
              totalOfAllItems={data?.meta?.totalResult}
              totalPages={data?.meta?.totalPage}
            />
          </LegacyCard>
        </div>
      </FormAnswerStyled>
      <ModalConfirm
        onClose={() =>
          setModalDelete({
            id: 0,
            isOpen: false,
          })
        }
        isLoading={isDeleting}
        isOpen={modalDelete.isOpen}
        title="Do you want to delete this form answer?"
        onConfirm={handleConfirmDelete}
        sectionText="Once you delete this form answer, you will not be able to restore it."
      />
    </Layout>
  );
}
