import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice, { channelsSeletedSelector } from '@/redux/slice/widget.slice';
import { IChanelProps } from '@/types/components/channels';
import { IUpload } from '@/types/components/upload';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChannelsButton from '.';
import UploadImage from '../UploadImage';

const Wechat = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  const channels = useSelector(channelsSeletedSelector);
  const dispatch = useDispatch();
  const url = channels.find((item) => item.channel === keyValue)?.advance?.weChatQr?.url;

  const handleUploadFile = (value: IUpload) => {
    const newChannels = [...channels];
    const index = newChannels.findIndex((item) => item.channel === keyValue);

    if (index !== -1) {
      newChannels[index] = {
        ...newChannels[index],
        advance: {
          weChatQr: value,
        },
      };
    }
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };
  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: WeChat_ID"
      tooltip="Enter the weChat ID of the profile you want to add. You will usually find the 'WeChat ID' written next to the avatar photo of the profile. Unfortunately, WeChat doesn't have a click-to-chat API, therefore we can only show your username on-click so visitors can look-up for it."
      expandActionSettings={<UploadImage label="QR code" setFile={handleUploadFile} url={url} />}
    />
  ) : null;
};
export default memo(Wechat);
