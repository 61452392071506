import TimePicker from '@/components/TimePicker';
import UploadImage from '@/components/UploadImage';
import { AvatarIds } from '@/constants/enum';
import { uniq } from '@/helpers/string';
import widgetSlice, { channelsSeletedSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { IUpload } from '@/types/components/upload';
import { Icon, InlineGrid, InlineStack, LegacyStack, Modal, RadioButton, Scrollable, TextField } from '@shopify/polaris';
import { CheckIcon } from '@shopify/polaris-icons';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const listDefaultAvatarAgents = [
  {
    id: AvatarIds.Default,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/4.png',
  },
  {
    id: AvatarIds.AvatarTwo,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/1.png',
  },
  {
    id: AvatarIds.AvatarThree,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/2.png',
  },
  {
    id: AvatarIds.AvatarFour,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/3.png',
  },
  {
    id: AvatarIds.AvatarFive,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/5.png',
  },
  {
    id: AvatarIds.AvatarSix,
    url: 'https://apps.synctrack.io/cta-buttons/avatar/6.png',
  },
];
interface IProps {
  open: boolean;
  handleClose: () => void;
  buttonId?: number;
  value?: IParamsApi.IAgent;
  channel: string;
}

interface IState {
  name: string;
  value: string;
  role: string;
  avatar: {
    id: AvatarIds;
    file?: string;
    url?: string;
  };
  jobs: string;
  endTime?: string;
  startTime?: string;
  customId?: number;
}
const ModalAgentMutation = ({ ...props }: IProps) => {
  const dispatch = useDispatch();
  const channelsSelected = useSelector(channelsSeletedSelector);
  const initState = useMemo(() => {
    return {
      name: '',
      value: '',
      role: '',
      jobs: '',
      avatar: {
        id: AvatarIds.Default,
      },
    };
  }, []);
  const initError = useMemo(() => {
    return {
      name: false,
      value: false,
      role: false,
      jobs: false,
    };
  }, []);
  const [state, setState] = useState<IState>(initState);
  const [error, setError] = useState(initError);

  const handleChange =
    (key: keyof typeof state) =>
    (
      value:
        | string
        | {
            id: AvatarIds;
            file?: string;
            url?: string;
          },
    ) => {
      if (error[key as keyof typeof error]) {
        setError({
          ...error,
          [key]: false,
        });
      }
      setState({
        ...state,
        [key]: value,
      });
    };

  useEffect(() => {
    if (props.value) {
      const value = props.value;
      setState({
        name: value.name,
        role: value.role,
        avatar: {
          id: value.avatarId,
          file: value.customAvatar,
        },
        value: value.value,
        startTime: value.startTime,
        endTime: value.endTime,
        customId: value.id || value.customId,
        jobs: value.jobs,
      });
    }
  }, [props.value]);

  const handleCreateOrUpdateAgent = (value: IParamsApi.IAgent) => {
    if (!channelsSelected) return;
    const newChannels = [...channelsSelected];
    const index = channelsSelected.findIndex((item) => item.channel === props.channel);
    if (index !== -1 || channelsSelected[index]) {
      const isUpdate = !!props.value;
      if (isUpdate) {
        const newAgents = [...(newChannels[index].agents || [])];
        const indexOfAgent = newChannels[index].agents?.findIndex(
          (agent) => agent.customId === state.customId || agent.id === state.customId,
        );
        if (indexOfAgent !== -1) {
          if (newAgents[indexOfAgent as number]) {
            newAgents[indexOfAgent as number] = value;
            newChannels[index] = { ...newChannels[index], agents: newAgents };
          }
        }
      } else {
        let maxId = 0;
        channelsSelected[index].agents?.forEach((agent) => {
          if (agent.customId !== undefined && agent.customId > maxId) maxId = agent.customId;
          if (agent.id !== undefined && agent.id > maxId) maxId = agent.id;
        });
        value.customId = maxId + 1;
        newChannels[index] = {
          ...newChannels[index],
          agents: newChannels[index].agents ? [...(newChannels[index].agents || []), value] : [value],
        };
      }
      dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      props.handleClose();
    }
  };

  useEffect(() => {
    if (!props.open) setState(initState);
    return () => {
      setState(initState);
      setError(initError);
    };
  }, [props.open, initState, initError]);

  const onRemoveJob = (jobRemoved: string) => {
    const newJobs = state.jobs
      .split(',')
      .filter((job) => job.trim() !== jobRemoved.trim())
      .join(',');
    handleChange('jobs')(newJobs);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      title={!props.value ? 'Add agent' : 'Update agent'}
      sectioned
      secondaryActions={[{ content: 'Cancel', onAction: props.handleClose }]}
      primaryAction={{
        content: 'Save',
        disabled:
          error.name || error.value || error.role || error.jobs || !state.value || !state.name || !state.jobs || !state.role,
        onAction: () => {
          handleCreateOrUpdateAgent({
            ...props.value,
            name: state.name,
            avatarId: state.avatar.id,
            role: state.role,
            value: state.value,
            customAvatar: state.avatar.file,
            endTime: state.endTime || '23:59',
            startTime: state.startTime || '00:00',
            buttonId: props.buttonId,
            jobs: state.jobs,
          });
        },
      }}
    >
      <TextField
        requiredIndicator
        autoComplete="off"
        label="Name"
        onChange={handleChange('name')}
        value={state.name}
        onBlur={() => {
          if (!state.name) {
            setError({
              ...error,
              name: true,
            });
          }
        }}
        error={error.name ? 'This field is required' : ''}
      />
      <div className="mt-8">
        <TextField
          requiredIndicator
          error={error.role ? 'This field is required' : ''}
          autoComplete="off"
          onBlur={() => {
            if (!state.role) {
              setError({
                ...error,
                role: true,
              });
            }
          }}
          label="Role"
          onChange={handleChange('role')}
          value={state.role}
        />
      </div>
      <div className="mt-8">
        <TextField
          requiredIndicator
          autoComplete="off"
          onBlur={() => {
            if (!state.value) {
              setError({
                ...error,
                value: true,
              });
            }
          }}
          label={'Value'}
          onChange={handleChange('value')}
          value={state.value}
          error={error.value ? 'This field is required' : ''}
        />
      </div>
      <div className="mt-8">
        <TextField
          requiredIndicator
          autoComplete="off"
          onBlur={() => {
            if (!state.jobs) {
              setError({
                ...error,
                jobs: true,
              });
            }
          }}
          label={'Jobs'}
          onChange={handleChange('jobs')}
          value={state.jobs}
          helpText="Enter job titles separated by commas (,). Ex: Developer, Sales"
          error={error.jobs ? 'This field is required' : ''}
        />
        <Scrollable focusable style={{ minHeight: uniq(state.jobs.split(',')).filter((job) => job).length ? 30 : 'unset' }}>
          <InlineStack gap={'100'} wrap={true}>
            {uniq(state.jobs.split(',').map((item) => item.trim()))
              .filter((item: string) => item !== '')
              .splice(0, 500)
              .map((job: string) => {
                return (
                  <div className="mt-8 mr-8 Polaris-Tag" key={job}>
                    <h1 title={job} className="Polaris-Tag__TagText">
                      {job}
                    </h1>
                    <button className="Polaris-Tag__Button" onClick={() => onRemoveJob(job)}>
                      <div>X</div>
                    </button>
                  </div>
                );
              })}
          </InlineStack>
        </Scrollable>
      </div>
      <div className="mt-8">
        <InlineGrid columns={2}>
          <TimePicker label="Start working time" value={state.startTime} onChange={handleChange('startTime')} />
          <TimePicker label="End working time" value={state.endTime} onChange={handleChange('endTime')} />
        </InlineGrid>
      </div>

      <div className="mt-8">
        <LegacyStack>
          <RadioButton
            label="Default"
            checked={state.avatar.id !== AvatarIds.Custom}
            id="default"
            name="default-avatar"
            onChange={() => {
              handleChange('avatar')({ id: AvatarIds.Default });
            }}
          />
          <RadioButton
            label="Custom"
            id="custom"
            name="custom-avatar"
            checked={state.avatar.id === AvatarIds.Custom}
            onChange={() => {
              handleChange('avatar')({ id: AvatarIds.Custom });
            }}
          />
        </LegacyStack>
      </div>

      <div className="mt-16">
        {state.avatar.id === AvatarIds.Custom ? (
          <UploadImage
            labelHidden
            label=""
            url={state.avatar.file}
            setFile={(value: IUpload) => {
              handleChange('avatar')({ id: AvatarIds.Custom, file: value.file, url: value.url });
            }}
          />
        ) : (
          <InlineGrid columns={6}>
            {listDefaultAvatarAgents.map((item) => {
              return (
                <div key={item.id} className="agent-avatar-container">
                  {item.id === state.avatar.id ? (
                    <div className="agent-avatar-selected">
                      <Icon source={CheckIcon} tone="base" />
                    </div>
                  ) : null}
                  <img
                    className={item.id === state.avatar.id ? 'agent-avatar agent-avatar-active' : 'agent-avatar'}
                    width={80}
                    height={80}
                    src={item.url}
                    alt={`avatar ${item.id}`}
                    onClick={() => {
                      handleChange('avatar')({ id: item.id });
                    }}
                  ></img>
                </div>
              );
            })}
          </InlineGrid>
        )}
      </div>
    </Modal>
  );
};

export default memo(ModalAgentMutation);
