import BoldText from '@/components/BoldText';
import ColorPicker from '@/components/ColorPicker';
import IConBorder, { borderConfig } from '@/components/Icons/IconBorder';
import { Border, Display } from '@/constants/enum';
import { capitalizeFirstLetter } from '@/helpers/string';
import widgetSlice, { customizeSelected } from '@/redux/slice/widget.slice';
import Editor from '@monaco-editor/react';
import { InlineGrid, LegacyStack, RadioButton, RangeSlider } from '@shopify/polaris';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const StepThree = () => {
  const customize = useSelector(customizeSelected);
  const border = borderConfig.find((item) => item.border === customize.buttonStyle)?.value || Border.Circle;

  const inconView = [
    { title: Display.Vertical, value: Display.Vertical },
    { title: Display.Horizontal, value: Display.Horizontal },
  ];

  const handleChangeCustomize = useCallback(
    (key: keyof typeof customize) => (value: any) => {
      dispatch(
        widgetSlice.actions.handleCustomize({
          ...customize,
          [key]: value,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customize],
  );

  const dispatch = useDispatch();

  return (
    <div className="custom-mize">
      <RangeSlider
        label={
          <div className="d-flex mt-8">
            <BoldText>Icon size: {customize.widgetSize}px</BoldText>
          </div>
        }
        max={100}
        value={customize.widgetSize}
        onChange={handleChangeCustomize('widgetSize')}
      />
      <div className="mt-16">
        <RangeSlider
          label={<BoldText>Side spacing: {customize.sideSpacing}%</BoldText>}
          max={100}
          value={customize.sideSpacing}
          onChange={handleChangeCustomize('sideSpacing')}
        />
        <div className="mt-16">
          <RangeSlider
            label={<BoldText>Bottom spacing: {customize.bottomSpacing}%</BoldText>}
            max={100}
            value={customize.bottomSpacing}
            onChange={handleChangeCustomize('bottomSpacing')}
          />
        </div>
      </div>
      <div className="mt-16">
        <BoldText>Icons layout</BoldText>
      </div>
      <div className="mt-8">
        <LegacyStack>
          {inconView.map((option) => (
            <RadioButton
              key={option.value}
              label={capitalizeFirstLetter(option.title)}
              checked={option.value === customize.buttonDirection}
              id={option.value}
              name={option.value}
              onChange={() => handleChangeCustomize('buttonDirection')(option.value)}
            />
          ))}
        </LegacyStack>
      </div>

      <div className="mt-16">
        <BoldText>Tooltip</BoldText>
        <div className="mt-8">
          <InlineGrid columns={2} gap={'400'}>
            <ColorPicker
              title="Text color"
              id="action-text-color"
              defaultValue={customize.ctaTextColor}
              onBlur={handleChangeCustomize('ctaTextColor')}
            />
            <ColorPicker
              title="Background"
              id="action-background"
              defaultValue={customize.ctaBackground}
              onBlur={handleChangeCustomize('ctaBackground')}
            />
          </InlineGrid>
        </div>
      </div>
      <div className="mt-16">
        <BoldText>Icon shape</BoldText>
        <IConBorder value={border} setValue={handleChangeCustomize('buttonStyle')} />
      </div>

      <div className="mt-16 mb-8">
        <BoldText>Custom CSS</BoldText>
      </div>
      <Editor
        className="mt-4"
        onChange={handleChangeCustomize('customCss')}
        height="12rem"
        theme="vs-dark"
        value={customize.customCss}
        language="css"
      />
    </div>
  );
};

export default StepThree;
