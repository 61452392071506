import { PricingPlan } from '@/constants/enum';
import { MagicIcon } from '@shopify/polaris-icons';

export const pricings = {
  [PricingPlan.Free]: [
    {
      description: '1 chat widget',
    },
    {
      description: 'Unlimited visitors/month',
    },
    {
      description: '3 chat channels',
    },
    {
      description: 'Fully custom  widget & channel style',
    },
    {
      description: '24/7 live chat support',
    },
  ],
  [PricingPlan.Basic]: [
    {
      description: 'Everything in Free, plus:',
      icon: MagicIcon,
    },
    {
      description: 'Unlimited chat channels',
    },
    {
      description: '24/7 live chat support',
    },
  ],
  [PricingPlan.Pro]: [
    {
      description: 'Everything in Basic, plus:',
      icon: MagicIcon,
    },
    {
      description: 'Unlimited chat widgets',
    },
    {
      description: 'Visitor tracking analytics',
    },
    {
      description: 'Contact form widget',
    },
    {
      description: 'Premium 24/7 live chat support',
    },
  ],
  [PricingPlan.Premium]: [
    {
      description: 'Everything in Pro, plus:',
      icon: MagicIcon,
    },
    {
      description: 'Unlimited agents for Whatsapp, Messenger',
    },
    {
      description: 'Target pages',
    },
    {
      description: 'Working days and hours',
    },
    {
      description: 'Fast processing for high-traffic stores',
    },
  ],
  subTitle: {
    [PricingPlan.Free]: 'Essential features to get started',
    [PricingPlan.Basic]: 'Connect to all channels',
    [PricingPlan.Pro]: 'Best for small businesses',
    [PricingPlan.Premium]: 'Best for growing businesses',
  },
};

export const wanningDowngrade = {
  toFree: [
    '1 chat widget',
    '3 chat channels',
    'No visitor tracking analytics',
    'No added agents for WhatsApp, Messenger',
    'No page target',
    'No working day and time setup',
    'Slower handling high traffic store ',
  ],
  toBasic: [
    '1 chat widget',
    'No visitor tracking analytics',
    'No added agents for WhatsApp, Messenger',
    'No page target',
    'No working day and time setup',
    'Slower handling high traffic store ',
  ],
  toPro: [
    'No added agents for WhatsApp, Messenger',
    'No page target',
    'No working day and time setup',
    'Slower handling high traffic store',
  ],
};
