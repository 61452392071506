import { ICon, IconIds } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import widgetSlice from '@/redux/slice/widget.slice';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { WeChatIcon } from '../../WeChat';
import { WeChatWindowStyled } from './styled';
interface IProps {
  handleCloseWindow: () => void;
}
const WeChatWindow = ({ handleCloseWindow }: IProps) => {
  const dispatch = useDispatch();
  const wechat = useChannelSelected(ICon.WeChat).channel;

  const handleClose = () => {
    handleCloseWindow();
    dispatch(widgetSlice.actions.handleWindow(undefined));
  };

  return (
    <WeChatWindowStyled>
      <div className="d-flex wechat-header">
        {wechat?.iconId === IconIds.Custom ? (
          <img width={60} src={wechat?.customImage?.url} alt="wechat" />
        ) : (
          <div>
            <WeChatIcon fill={wechat?.backgroundColor} iconColor={wechat?.iconColor} />
          </div>
        )}

        <svg onClick={handleClose} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7.31429 8L14 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        </svg>
      </div>
      <img className="mt-8 wechat-qr" width={188} src={wechat?.advance?.weChatQr?.url} alt="wechat-qr" />
    </WeChatWindowStyled>
  );
};

export default memo(WeChatWindow);
