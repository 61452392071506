import styled from 'styled-components';

export const LimitedBannerStyled = styled.div`
  .Polaris-BlockStack,
  .Polaris-Modal-Dialog__Modal {
    .Polaris-Box {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
