/* eslint-disable jsx-a11y/anchor-is-valid */
import RegularText from '@/components/RegularText';
import { Ga4Event } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import { isSubmittedEstimatedSelector, isSubmittedReturnSelector, sessionSlice } from '@/redux/slice/session.slice';
import { Button, Card, Icon, InlineGrid, Link } from '@shopify/polaris';
import { StarFilledIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { configRecommend } from './configRecommend';

const RecommendedApps = () => {
  const dispatch = useDispatch();
  const [sendGA4Event] = apiCaller.useSendGA4EventMutation();
  const isSubmittedEstimated = useSelector(isSubmittedEstimatedSelector);
  const isSubmittedReturn = useSelector(isSubmittedReturnSelector);

  const handleSendGA4Event = (event: Ga4Event) => {
    const data = {
      eventName: event,
      params: {},
    };
    switch (event) {
      case Ga4Event.EstimatedClick:
        if (!isSubmittedEstimated) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedEstimated', value: true }));
        }
        break;
      case Ga4Event.ReturnClick:
        if (!isSubmittedReturn) {
          sendGA4Event(data);
          dispatch(sessionSlice.actions.handleUpdateSession({ key: 'isSubmittedReturn', value: true }));
        }
        break;
      default:
        break;
    }
  };
  return (
    <InlineGrid columns={{ xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }} gap={'400'}>
      {configRecommend.map((item, index) => {
        return (
          <Card key={index}>
            <div>
              <div className="recommended-apps-container">
                <img className="recommend-app-logo" src={item.logo} alt="recommend apps" />
                <div className="recommend-app-content">
                  <Link
                    onClick={() => {
                      handleSendGA4Event(item.ga4Event);
                      window.open(item.href, '_blank');
                    }}
                    removeUnderline
                  >
                    {item.title}
                  </Link>
                  {item.hasBFS ? (
                    <div>
                      <span className="bfs-badge">
                        <svg width={12} height={10.5} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="m13 0-1 5-4 9 8-9.5L13 0ZM3 0l1 5 4 9-8-9.5L3 0Z" fill="#0D79A7" />
                          <path d="m3 0 1 5 4 9 4-9 1-5H3Z" fill="#49A3C9" />
                          <path d="M8 14 4 5l-4-.5L8 14ZM8 14l4-9 4-.5L8 14Z" fill="#07425B" />
                          <path d="M8 5.5 4 5l4 9 4-9-4 .5Z" fill="#0D79A7" />
                          <path d="m4 5 4-5 4 5-4.001.5L4 5Z" fill="#95D5F0" />
                          <path d="M4 5 3 0h5L4 5ZM12 5l1-5H8l4 5Z" fill="#49A3C9" />
                          <path d="M4 5 3 0 0 4.5 4 5ZM12 5l1-5 3 4.5-4 .5Z" fill="#0D79A7" />
                        </svg>
                        <span>Built for Shopify</span>
                      </span>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <Icon source={StarFilledIcon} tone="warning" />
                    <RegularText>
                      {item.star} - {item.plan}
                    </RegularText>
                  </div>
                  <RegularText>{item.description}</RegularText>
                </div>
              </div>
              <div className="recommend-app-btn">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSendGA4Event(item.ga4Event);
                    window.open(item.href, '_blank');
                  }}
                  external
                  fullWidth
                >
                  Try for free
                </Button>
              </div>
              <img className="recommend-app-banner" src={item.banner} alt="recommend app banner" />
            </div>
          </Card>
        );
      })}
    </InlineGrid>
  );
};

export default memo(RecommendedApps);
