import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const MessengerIcon = ({ fill }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;

  return (
    <svg width={scale} height={scale} viewBox="0 0 48 48" fill={fill || '#fff'} xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="48" height="48" fill="" className="color-element"></rect>
      <g transform="translate(7.8, 7.8)" clipPath="url(#clip0_7455_118013)">
        <path
          className="not-press"
          d="M16.4445 0.443359C7.43252 0.443359 0.444519 7.04736 0.444519 15.9634C0.444519 20.6274 2.35652 24.6594 5.46852 27.4434C5.72852 27.6754 5.88852 28.0034 5.89652 28.3554L5.98452 31.2034C6.01252 32.1114 6.94852 32.7034 7.78052 32.3354L10.9565 30.9354C11.2245 30.8154 11.5285 30.7954 11.8125 30.8714C13.2725 31.2714 14.8245 31.4874 16.4445 31.4874C25.4565 31.4874 32.4445 24.8834 32.4445 15.9674C32.4445 7.05136 25.4565 0.443359 16.4445 0.443359Z"
          fill="url(#paint0_radial_7455_118013)"
        ></path>
        <path
          className="not-press color-element"
          d="M6.83653 20.5041L11.5365 13.0481C12.2845 11.8601 13.8845 11.5681 15.0085 12.4081L18.7485 15.2121C19.0925 15.4681 19.5645 15.4681 19.9045 15.2081L24.9525 11.3761C25.6245 10.8641 26.5045 11.6721 26.0565 12.3881L21.3525 19.8401C20.6045 21.0281 19.0045 21.3201 17.8805 20.4801L14.1405 17.6761C13.7965 17.4201 13.3245 17.4201 12.9845 17.6801L7.93653 21.5121C7.26453 22.0241 6.38453 21.2201 6.83653 20.5041Z"
          fill={fill || 'white'}
        ></path>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_7455_118013"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.60452 32.2691) scale(34.8672 34.8671)"
        >
          <stop stopColor="#0099FF"></stop>
          <stop offset="0.6098" stopColor="#A033FF"></stop>
          <stop offset="0.9348" stopColor="#FF5280"></stop>
          <stop offset="1" stopColor="#FF7061"></stop>
        </radialGradient>
        <clipPath id="clip0_7455_118013">
          <rect width="32" height="32" fill="white" transform="translate(0.444519 0.443359)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
