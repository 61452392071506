import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const ViberIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#665CAC'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39"></rect>
      <path
        d="M17.6837 2.14833C17.16 1.64578 15.0497 0.0389578 10.3496 0.0158143C10.3496 0.0158143 4.80418 -0.334645 2.10085 2.26405C0.596193 3.84111 0.0662511 6.14886 0.0126261 9.01205C-0.0441534 11.8752 -0.116705 17.2346 4.8168 18.6894H4.81995L4.8168 20.9078C4.8168 20.9078 4.78525 21.8071 5.3499 21.989C6.03125 22.2105 6.43186 21.5294 7.08482 20.7921C7.44127 20.3888 7.93651 19.7936 8.30874 19.3407C11.6808 19.6382 14.2769 18.9572 14.5702 18.858C15.2516 18.6265 19.1063 18.1075 19.7309 12.7481C20.3775 7.22338 19.4154 3.7254 17.6837 2.14833ZM18.2546 12.3513C17.7247 16.828 14.5986 17.1123 14.0245 17.3041C13.7785 17.3867 11.501 17.9818 8.63679 17.7835C8.63679 17.7835 6.50126 20.4813 5.83567 21.1856C5.73158 21.2947 5.60856 21.341 5.52654 21.3178C5.41298 21.2881 5.38144 21.1459 5.38144 20.9376C5.38459 20.64 5.40037 17.2512 5.40037 17.2512C5.39721 17.2512 5.39721 17.2512 5.40037 17.2512C1.23023 16.0378 1.47312 11.4719 1.52044 9.08148C1.56775 6.69108 1.99675 4.73379 3.26798 3.41792C5.55493 1.24904 10.2645 1.57305 10.2645 1.57305C14.2422 1.59288 16.1475 2.84594 16.5891 3.26583C18.0527 4.58501 18.8003 7.73585 18.2546 12.3513Z"
        transform="translate(9.47299 8.4238)"
        fill={iconColor || 'white'}
      ></path>
      <path
        d="M1.58982 1.72254C1.53935 0.628182 1.00941 0.0562059 0 1.00898e-07"
        transform="translate(20.166 15.5914)"
        fill={iconColor || 'white'}
        strokeWidth="0.518873"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M3.35945 3.69636C3.38153 2.67804 3.09448 1.82834 2.49514 1.14395C1.8958 0.459565 1.06619 0.0793496 1.9253e-07 2.01796e-07"
        transform="translate(19.7592 14.0966)"
        fill={iconColor || 'white'}
        strokeWidth="0.518873"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M5.22371 5.71646C5.21109 3.94763 4.70639 2.55902 3.71275 1.5407C2.71911 0.525689 1.47942 0.00991877 -3.8506e-07 1.00898e-07"
        transform="translate(19.2931 12.6479)"
        fill={iconColor || 'white'}
        strokeWidth="0.518873"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M6.35738 8.04532C6.35738 8.04532 6.73276 8.07838 6.93149 7.81719L7.32263 7.30142C7.5119 7.04353 7.96929 6.88153 8.41722 7.14272C8.66641 7.2915 9.11434 7.57914 9.39193 7.79405C9.68844 8.02218 10.2972 8.55448 10.2972 8.55448C10.5875 8.80906 10.6537 9.18597 10.4581 9.58271C10.4581 9.58602 10.455 9.58933 10.455 9.58933C10.2531 9.96293 9.9818 10.3167 9.63797 10.644C9.63482 10.6473 9.63482 10.6473 9.63167 10.6506C9.35092 10.8953 9.07333 11.0375 8.80205 11.0705C8.77051 11.0771 8.7295 11.0804 8.68219 11.0771C8.56232 11.0771 8.44561 11.0606 8.32889 11.0209L8.31943 11.0077C7.89989 10.8854 7.20277 10.5746 6.03879 9.90342C5.28173 9.467 4.654 9.02066 4.12406 8.57762C3.84331 8.34619 3.55626 8.08169 3.2629 7.77752C3.25344 7.7676 3.24398 7.75768 3.23451 7.74776C3.22505 7.73784 3.21559 7.72792 3.20612 7.718C3.19666 7.70808 3.1872 7.69817 3.17773 7.68825C3.16827 7.67833 3.15881 7.66841 3.14934 7.65849C2.85914 7.35101 2.60678 7.05015 2.38597 6.75589C1.96328 6.20045 1.53744 5.5392 1.12105 4.74902C0.480708 3.52902 0.184194 2.79834 0.0674803 2.35862L0.0548623 2.3487C0.0170094 2.22637 -0.00191702 2.10404 0.0012374 1.9784C-0.00191702 1.92881 0.0012373 1.88583 0.00754613 1.85276C0.0390903 1.56843 0.17473 1.27748 0.408157 0.983227C0.411312 0.979921 0.411311 0.979921 0.414466 0.976615C0.726753 0.616237 1.06112 0.331902 1.42072 0.120304C1.42388 0.120304 1.42703 0.116997 1.42703 0.116997C1.80556 -0.0879887 2.16517 -0.0185578 2.40806 0.285615C2.41121 0.288921 2.91592 0.923716 3.13357 1.2345C3.33861 1.52875 3.6162 1.99493 3.75499 2.25612C4.00419 2.72561 3.84962 3.20501 3.60358 3.40338L3.11149 3.81335C2.86229 4.02495 2.89384 4.41509 2.89384 4.41509C2.89384 4.41509 3.62566 7.31464 6.35738 8.04532Z"
        transform="translate(13.863 13.0014)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
