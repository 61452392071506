import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const TeamsIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 48 48" fill={fill || '#4B53BC'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="48" height="48" fill={fill || '#4B53BC'}></rect>
      <g clipPath="url(#clip0_3945_17159)">
        <path
          d="M28.9361 21H35.5782C36.2057 21 36.7144 21.5104 36.7144 22.14V28.2102C36.7144 30.5242 34.8448 32.4 32.5385 32.4H32.5188C30.2125 32.4003 28.3426 30.5248 28.3423 28.2108C28.3423 28.2106 28.3423 28.2104 28.3423 28.2102V21.5958C28.3423 21.2667 28.6082 21 28.9361 21Z"
          fill={iconColor || '#F2F2F2'}
        ></path>
        <path
          d="M33.4254 19.8004C34.9116 19.8004 36.1164 18.5916 36.1164 17.1004C36.1164 15.6092 34.9116 14.4004 33.4254 14.4004C31.9392 14.4004 30.7344 15.6092 30.7344 17.1004C30.7344 18.5916 31.9392 19.8004 33.4254 19.8004Z"
          fill={iconColor || '#F2F2F2'}
        ></path>
        <path
          d="M25.0531 19.8C27.1998 19.8 28.9401 18.0539 28.9401 15.9C28.9401 13.7461 27.1998 12 25.0531 12C22.9063 12 21.166 13.7461 21.166 15.9C21.166 18.0539 22.9063 19.8 25.0531 19.8Z"
          fill={iconColor || 'white'}
        ></path>
        <path
          d="M30.2362 21H19.2723C18.6523 21.0154 18.1618 21.5317 18.1762 22.1538V29.0772C18.0896 32.8105 21.0334 35.9086 24.7543 36C28.4751 35.9086 31.4189 32.8105 31.3323 29.0772V22.1538C31.3467 21.5317 30.8562 21.0154 30.2362 21Z"
          fill={iconColor || 'white'}
        ></path>
        <path
          opacity="0.1"
          d="M25.3522 21V30.702C25.3492 31.1469 25.0805 31.5466 24.6705 31.716C24.5399 31.7714 24.3996 31.8 24.2578 31.8H18.7023C18.6246 31.602 18.5528 31.404 18.493 31.2C18.2837 30.5115 18.1769 29.7958 18.1761 29.076V22.152C18.1617 21.5309 18.6514 21.0154 19.2704 21H25.3522Z"
          fill={iconColor || '#D5D5D5'}
        ></path>
        <path
          opacity="0.2"
          d="M24.7542 21V31.302C24.7542 31.4442 24.7257 31.585 24.6705 31.716C24.5016 32.1274 24.1033 32.397 23.6598 32.4H18.9834C18.8817 32.202 18.7861 32.004 18.7023 31.8C18.6186 31.596 18.5528 31.404 18.493 31.2C18.2837 30.5115 18.1769 29.7958 18.1761 29.076V22.152C18.1617 21.5309 18.6514 21.0154 19.2704 21H24.7542Z"
          fill={iconColor || '#D5D5D5'}
        ></path>
        <path
          opacity="0.2"
          d="M24.7542 21V30.102C24.7496 30.7065 24.2623 31.1954 23.6598 31.2H18.493C18.2837 30.5115 18.1769 29.7958 18.1761 29.076V22.152C18.1617 21.5309 18.6514 21.0154 19.2704 21H24.7542Z"
          fill={iconColor || '#D5D5D5'}
        ></path>
        <path
          opacity="0.2"
          d="M24.1562 21V30.102C24.1516 30.7065 23.6643 31.1954 23.0618 31.2H18.493C18.2837 30.5115 18.1769 29.7958 18.1761 29.076V22.152C18.1617 21.5309 18.6514 21.0154 19.2704 21H24.1562Z"
          fill={iconColor || '#D5D5D5'}
        ></path>
        <path
          opacity="0.1"
          d="M25.3523 17.8988V19.7888C25.2507 19.7948 25.155 19.8008 25.0533 19.8008C24.9517 19.8008 24.856 19.7948 24.7543 19.7888C24.5525 19.7753 24.3523 19.7432 24.1563 19.6928C22.9454 19.405 21.9449 18.5529 21.4653 17.4008C21.3828 17.2073 21.3187 17.0064 21.2739 16.8008H24.258C24.8614 16.8031 25.35 17.2933 25.3523 17.8988Z"
          fill={iconColor || '#959595'}
        ></path>
        <path
          d="M24.7544 18.4984V19.7884C24.5525 19.7749 24.3523 19.7428 24.1564 19.6924C22.9454 19.4047 21.9449 18.5526 21.4653 17.4004H23.66C24.2635 17.4027 24.7521 17.8929 24.7544 18.4984Z"
          fill={iconColor || '#EDEDED'}
        ></path>
        <path
          opacity="0.2"
          d="M24.1564 18.4984V19.6924C22.9454 19.4047 21.9449 18.5526 21.4653 17.4004H23.062C23.6655 17.4027 24.1541 17.8929 24.1564 18.4984Z"
          fill={iconColor || 'black'}
        ></path>
        <path
          d="M12.0961 17.4004H23.06C23.6654 17.4004 24.1561 17.8928 24.1561 18.5002V29.5006C24.1561 30.108 23.6654 30.6004 23.06 30.6004H12.0961C11.4908 30.6004 11 30.108 11 29.5006V18.5002C11 17.8928 11.4908 17.4004 12.0961 17.4004Z"
          fill={iconColor || '#F2F2F2'}
        ></path>
        <path
          d="M20.4629 21.5874H18.2712V27.5754H16.8749V21.5874H14.6934V20.4258H20.4629V21.5874Z"
          fill={iconColor || '#C4C4C4'}
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3945_17159">
          <rect width="25.7143" height="24" fill={iconColor || 'white'} transform="translate(11 12)"></rect>
        </clipPath>
      </defs>
    </svg>
  );
};
