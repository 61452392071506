import styled from 'styled-components';
interface Props {
  // borderRadius: string;
}

export const AgentStyled = styled.div<Props>`
  height: 240px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 90px auto 0;
  width: 85%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrap-agent {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
`;
