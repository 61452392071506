import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const GoogleMapIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#37AA66'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39" fill={fill || '#37AA66'}></rect>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.06381C0 3.68631 3.68633 0 8.06383 0C12.4413 0 16.1276 3.68631 16.1276 8.06381C16.1276 12.2109 9.67659 19.5835 8.9854 20.2747C8.755 20.5051 8.29422 20.7355 8.06383 20.7355C7.83344 20.7355 7.37263 20.5051 7.14224 20.2747C6.45107 19.5835 0 12.2109 0 8.06381ZM11.5203 8.06378C11.5203 9.97244 9.97302 11.5197 8.06436 11.5197C6.15572 11.5197 4.60844 9.97244 4.60844 8.06378C4.60844 6.15515 6.15572 4.60788 8.06436 4.60788C9.97302 4.60788 11.5203 6.15515 11.5203 8.06378Z"
        transform="translate(11.3764 9.07178)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
