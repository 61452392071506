import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';
const Zalo = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;
  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: Zalo_handle"
      tooltip={`Enter your Zalo handle (part of the web page address, for "zalo.me/example " the username is "example`}
    />
  ) : null;
};
export default memo(Zalo);
