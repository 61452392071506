import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const TwitterIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 512 512" fill={fill || '#000000'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="512" height="512"></rect>
      <path
        id="path_color_twitter"
        d="M108.72 117L223.002 269.781L108 394H133.884L234.571 285.242L315.92 394H404L283.285 232.626L390.33 117H364.446L271.721 217.16L196.8 117H108.72ZM146.785 136.062H187.248L365.931 374.938H325.467L146.785 136.062Z"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
