import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const WazeIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={scale} height={scale} viewBox="0 0 39 39" fill={fill || '#6ECCEF'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="39" height="39"></rect>
      <path
        d="M11.6549 16.6733C16.5911 16.6733 20.5675 12.9218 20.5675 8.33667C20.5675 3.7515 16.5911 0 11.6549 0C6.7187 0 2.74233 3.7515 2.74233 8.33667C2.74233 8.33667 3.42791 12.505 0 12.505C0 15.9786 6.17024 16.6733 11.6549 16.6733Z"
        transform="translate(9.15631 9.71375)"
        fill={fill || '#6ECCEF'}
        className="color-element not-press"
      ></path>
      <path
        d="M12.3405 18.0628C3.97637 18.0628 0 16.5344 0 13.1997C0 12.7829 0.274233 12.505 0.685582 12.505C1.37116 12.505 1.91963 12.3661 2.19386 11.9492C3.01656 10.9766 2.74233 9.17034 2.74233 9.17034C2.74233 4.02939 6.99293 0 12.3405 0C17.688 0 21.9386 4.02939 21.9386 9.0314C21.9386 14.0334 17.688 18.0628 12.3405 18.0628ZM1.50828 13.7555C2.19386 15.5618 5.896 16.6733 12.3405 16.6733C16.8653 16.6733 20.5675 13.1997 20.5675 9.0314C20.5675 4.86306 16.8653 1.38945 12.3405 1.38945C7.81563 1.38945 4.11349 4.86306 4.11349 9.0314C4.11349 9.0314 4.52484 11.3935 3.29079 12.7829C2.74233 13.3387 2.19386 13.6166 1.50828 13.7555Z"
        transform="translate(8.46936 9.01862)"
        fill={iconColor || 'white'}
      ></path>{' '}
      <path
        d="M2.74233 5.55778C4.25687 5.55778 5.48465 4.31363 5.48465 2.77889C5.48465 1.24415 4.25687 0 2.74233 0C1.22778 0 0 1.24415 0 2.77889C0 4.31363 1.22778 5.55778 2.74233 5.55778Z"
        transform="translate(20.8105 24.3027)"
        fill={iconColor || 'white'}
      ></path>{' '}
      <path
        d="M2.74233 5.55778C4.25687 5.55778 5.48465 4.31363 5.48465 2.77889C5.48465 1.24415 4.25687 0 2.74233 0C1.22778 0 0 1.24415 0 2.77889C0 4.31363 1.22778 5.55778 2.74233 5.55778Z"
        transform="translate(12.5836 24.3025)"
        fill={iconColor || 'white'}
      ></path>{' '}
      <path
        d="M0.685582 1.38945C0.274233 1.38945 0 1.11156 0 0.694723C0 0.277889 0.274233 0 0.685582 0C1.09693 0 1.37116 0.277889 1.37116 0.694723C1.37116 1.11156 1.09693 1.38945 0.685582 1.38945Z"
        transform="translate(17.3835 15.2717)"
        fill={iconColor || 'white'}
      ></path>{' '}
      <path
        d="M0.685582 1.38945C0.274233 1.38945 0 1.11156 0 0.694723C0 0.277889 0.274233 0 0.685582 0C1.09693 0 1.37116 0.277889 1.37116 0.694723C1.37116 1.11156 1.09693 1.38945 0.685582 1.38945Z"
        transform="translate(22.8668 15.2717)"
        fill={iconColor || 'white'}
      ></path>{' '}
      <path
        d="M4.14916 4.20448C2.22953 4.20448 0.447017 2.81503 0.035668 0.869809C-0.101448 0.452975 0.172786 0.175088 0.584135 0.0361436C0.995484 -0.102801 1.26972 0.17509 1.40683 0.591924C1.68106 1.84242 2.77799 2.81503 4.14916 2.81503C5.52032 2.81503 6.61725 1.84242 6.89148 0.591924C7.0286 0.17509 7.30283 0.0361436 7.71418 0.0361436C8.12553 0.175088 8.26265 0.452975 8.26265 0.869809C7.8513 2.81503 6.06879 4.20448 4.14916 4.20448Z"
        transform="translate(16.662 18.7092)"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
