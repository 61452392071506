import styled from 'styled-components';

export const AutoCompleteWithTagStyled = styled.div`
  .Polaris-Tag--removable {
    padding: 0.25rem;
  }
  .Polaris-Icon {
    margin: 0;
  }
`;
