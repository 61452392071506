import { Display } from '@/constants/enum';
import styled from 'styled-components';

interface IProps {
  borderRadius: string;
  tooltipBackgroundColor: string;
  display: Display;
  tooltipTextColor: string;
  scale: number;
}

export const CloseStyled = styled.span<IProps>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.borderRadius};
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.07), 0 2px 10px 0 rgba(0, 0, 0, 0.05);
  svg {
    color: #ffffff;
  }
`;
