import { IconIds } from '@/constants/enum';
import { IIConProps } from '@/types/components/channels';
import { HomeIcon } from '../Home';

export const HomeVariant = ({ size, fill, iconColor }: IIConProps) => {
  return [
    {
      id: IconIds.Default,
      element: <HomeIcon fill={fill} />,
    },
    {
      id: IconIds.Icon2,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            transform="translate(116, 130)"
            fill={iconColor || '#000'}
            d="M288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2zm282.69 121.28l-255.94-226a39.85 39.85 0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59z"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon3,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            transform="translate(116, 130)"
            fill={iconColor || '#000'}
            d="M573.48 219.91L512 170.42V72a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v72.6L310.6 8a35.85 35.85 0 0 0-45.19 0L2.53 219.91a6.71 6.71 0 0 0-1 9.5l14.2 17.5a6.82 6.82 0 0 0 9.6 1L64 216.72V496a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V216.82l38.8 31.29a6.83 6.83 0 0 0 9.6-1l14.19-17.5a7.14 7.14 0 0 0-1.11-9.7zM336 480h-96V320h96zm144 0H368V304a16 16 0 0 0-16-16H224a16 16 0 0 0-16 16v176H96V190.92l187.71-151.4a6.63 6.63 0 0 1 8.4 0L480 191z"
          ></path>
        </svg>
      ),
    },
    {
      id: IconIds.Icon4,
      element: (
        <svg width={size} height={size} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
          <path
            fill={iconColor || '#000'}
            transform="translate(116, 130)"
            d="M64 311.4V496c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16V311.4c-6.7-5.5-44.7-38.3-224-196.4C107.9 273.9 70.4 306.1 64 311.4zm314.1-26.3c27.6 23 29.1 64.2 4.5 89.1l-84.7 85.6c-5.5 5.5-14.3 5.5-19.8 0l-84.7-85.6c-24.6-24.9-23.2-66.1 4.3-89.1 24-20 59.7-16.4 81.6 5.8l8.6 8.7 8.6-8.7c22-22.2 57.7-25.8 81.6-5.8zM64 311.4v-.3s-2.7 2.5 0 .3zm506.7-75.1L512 184.5V48c0-8.8-7.2-16-16-16h-64c-8.8 0-16 7.2-16 16v51.7L314.7 10.3c-15.3-13.7-38.2-13.7-53.5 0l-256 226c-6.6 5.9-7.1 16-1.2 22.6l21.4 23.8c5.9 6.6 16 7.1 22.6 1.2L277.4 81.7c6-5.3 15.1-5.3 21.2 0L527.9 284c6.6 5.9 16.7 5.4 22.6-1.2l21.4-23.8c5.9-6.7 5.4-16.8-1.2-22.7zM512 311.1v.3c2.9 2.3 0-.3 0-.3z"
          ></path>
        </svg>
      ),
    },
  ];
};
