import { useChannelSelected } from '@/hooks/useChannelSelected';
import { IChanelProps } from '@/types/components/channels';
import { memo } from 'react';
import ChannelsButton from '.';

const GoogleMap = ({ keyValue }: IChanelProps) => {
  const channel = useChannelSelected(keyValue).channel;

  return channel ? (
    <ChannelsButton
      key={keyValue}
      keyValue={keyValue}
      placeholder="Example: https://goo.gl/maps/4m93C84v2"
      tooltip="Enter your Google Map URL. E.g., https://goo.gl/maps/4m93C84v2DC2"
    />
  ) : null;
};
export default memo(GoogleMap);
