import styled from 'styled-components';
interface Props {
  backgroundColor: string;
  textColor: string;
}

export const ContactFormStyled = styled.div<Props>`
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  .header {
    height: 50px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    .wrap-icon {
      cursor: pointer;
    }
  }
  .body {
    height: 100%;
    padding: 0.5rem;
  }
  .footer {
    padding: 0 0.5rem 0.5rem 0.5rem;
    .btn-submit {
      border: none;
      outline: none;
      width: 100%;
      padding: 6px 0;
      background-color: ${(props) => props.backgroundColor};
      color: ${(props) => props.textColor};
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;
