import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const ScrollIcon = ({ fill, iconColor }: IIConProps) => {
  const size = useSelector(customizeSelected).widgetSize;
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
      <rect width="800" height="800" className="color-element" fill={fill || '#fff'}></rect>
      <path
        transform="translate(179, 140)"
        fill={iconColor || '#000'}
        d="M6.101 261.899L25.9 281.698c4.686 4.686 12.284 4.686 16.971 0L198 126.568V468c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V126.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 35.515c-4.686-4.686-12.284-4.686-16.971 0L6.101 244.929c-4.687 4.686-4.687 12.284 0 16.97z"
      ></path>
    </svg>
  );
};
