import { customizeSelected } from '@/redux/slice/widget.slice';
import { IIConProps } from '@/types/components/channels';
import { useSelector } from 'react-redux';

export const DiscordIcon = ({ fill, iconColor }: IIConProps) => {
  const scale = useSelector(customizeSelected).widgetSize;
  return (
    // <svg width={scale} height={scale} viewBox={`0 0 50 50`} fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <circle className="color-element" cx="24" cy="24" r="24" fill={fill || '#5865F2'}></circle>
    //   <path
    //     d="M32.3303 16.2278C30.7767 15.5009 29.1156 14.9727 27.3789 14.6719C27.1656 15.0575 26.9164 15.5761 26.7446 15.9887C24.8985 15.7111 23.0693 15.7111 21.2572 15.9887C21.0854 15.5761 20.8306 15.0575 20.6154 14.6719C18.8768 14.9727 17.2138 15.5029 15.6602 16.2317C12.5266 20.9669 11.6772 25.5845 12.1019 30.1365C14.1803 31.6885 16.1944 32.6313 18.1746 33.2483C18.6635 32.5754 19.0995 31.8601 19.4752 31.1063C18.7598 30.8344 18.0745 30.499 17.4271 30.1095C17.5988 29.9822 17.7669 29.8492 17.9292 29.7123C21.8782 31.5594 26.1689 31.5594 30.0707 29.7123C30.235 29.8492 30.403 29.9822 30.5728 30.1095C29.9235 30.5009 29.2364 30.8363 28.521 31.1082C28.8966 31.8601 29.3308 32.5774 29.8216 33.2502C31.8036 32.6333 33.8197 31.6905 35.898 30.1365C36.3964 24.8595 35.0467 20.2843 32.3303 16.2278ZM20.0132 27.337C18.8277 27.337 17.8556 26.2303 17.8556 24.8826C17.8556 23.535 18.807 22.4264 20.0132 22.4264C21.2194 22.4264 22.1916 23.533 22.1708 24.8826C22.1727 26.2303 21.2194 27.337 20.0132 27.337ZM27.9867 27.337C26.8013 27.337 25.8291 26.2303 25.8291 24.8826C25.8291 23.535 26.7805 22.4264 27.9867 22.4264C29.193 22.4264 30.1651 23.533 30.1444 24.8826C30.1444 26.2303 29.193 27.337 27.9867 27.337Z"
    //     fill="white"
    //   ></path>
    // </svg>
    <svg width={scale} height={scale} viewBox="0 0 48 48" fill={fill || '#5865F2'} xmlns="http://www.w3.org/2000/svg">
      <rect className="color-element" x="0" y="0" width="48" height="48"></rect>
      <path
        d="M32.3303 16.2278C30.7767 15.5009 29.1156 14.9727 27.3789 14.6719C27.1656 15.0575 26.9164 15.5761 26.7446 15.9887C24.8985 15.7111 23.0693 15.7111 21.2572 15.9887C21.0854 15.5761 20.8306 15.0575 20.6154 14.6719C18.8768 14.9727 17.2138 15.5029 15.6602 16.2317C12.5266 20.9669 11.6772 25.5845 12.1019 30.1365C14.1803 31.6885 16.1944 32.6313 18.1746 33.2483C18.6635 32.5754 19.0995 31.8601 19.4752 31.1063C18.7598 30.8344 18.0745 30.499 17.4271 30.1095C17.5988 29.9822 17.7669 29.8492 17.9292 29.7123C21.8782 31.5594 26.1689 31.5594 30.0707 29.7123C30.235 29.8492 30.403 29.9822 30.5728 30.1095C29.9235 30.5009 29.2364 30.8363 28.521 31.1082C28.8966 31.8601 29.3308 32.5774 29.8216 33.2502C31.8036 32.6333 33.8197 31.6905 35.898 30.1365C36.3964 24.8595 35.0467 20.2843 32.3303 16.2278ZM20.0132 27.337C18.8277 27.337 17.8556 26.2303 17.8556 24.8826C17.8556 23.535 18.807 22.4264 20.0132 22.4264C21.2194 22.4264 22.1916 23.533 22.1708 24.8826C22.1727 26.2303 21.2194 27.337 20.0132 27.337ZM27.9867 27.337C26.8013 27.337 25.8291 26.2303 25.8291 24.8826C25.8291 23.535 26.7805 22.4264 27.9867 22.4264C29.193 22.4264 30.1651 23.533 30.1444 24.8826C30.1444 26.2303 29.193 27.337 27.9867 27.337Z"
        fill={iconColor || 'white'}
      ></path>
    </svg>
  );
};
