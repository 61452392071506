import { colors } from '@/constants/colors';
import styled from 'styled-components';
interface Props {
  borderRadius: string;
}

export const MessageStyled = styled.div<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0.75rem;
  background-color: rgb(248, 248, 248);

  .message-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 4px;
    height: fit-content;
    column-gap: 0.5rem;
    button {
      width: 20px;
      border-radius: 50%;
      padding: 0;
      background-color: ${colors.gray_background};
    }
    svg {
      border-radius: ${(props) => props.borderRadius};
      width: 24px;
      height: 24px;
    }
    /* border-bottom: 0.5px solid #aaaaaaaa; */
  }
  .message-header-left {
    display: flex;
    .message-avatar {
      padding-right: 0.5rem;
      border-radius: 50%;
    }
    .message-made-by {
      .Polaris-Text--root {
        font-size: 10px;
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .message-header-bottom {
    padding: 0.5rem 0;
    border-top: 0.5px solid #aaaaaaaa;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .message-content-container {
    height: 185px;
    display: flex;
    align-items: center;
  }
  .message-content-chat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 222px;
  }
  .message-content-time {
    text-align: center;
    margin-bottom: 4px;
  }
  .message-content-chat-merchant {
    display: flex;
    align-items: flex-end;
    .Polaris-Avatar {
      height: 20px;
      padding: 4px;
      border-radius: 50%;
      margin-right: 4px;
    }
    .merchant-message {
      background-color: ${colors.gray_background};
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }
  .message-content-chat-buyer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .buyer-message {
      padding: 4px;
      border-radius: 1.3em;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05), 0 8px 16px 0 rgba(0, 0, 0, 0.1);
      line-height: 20px;
      margin: 6px 6px;
      padding: 8px 12px;
      text-align: start;
      transition: opacity 0.2;
      word-break: break-word;
    }
  }
  .start-chat {
    background-color: #692af2;
    color: #fff;
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
  }
  .bottom {
    text-align: center;
    width: 100%;
  }

  .input-container {
    position: relative;
    .messenger-chat-widget-action-send {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 4px;
    }
    input {
      border: none;
      flex: 1 1 0%;
      background-color: rgb(255, 255, 255);
      width: 100%;
      box-shadow: none;
      font-size: 14px !important;
      padding: 6px 24px 6px 6px !important;
      border-radius: 10px !important;
      outline: 0px !important;
      margin: 0px !important;
      color: rgb(17, 27, 33) !important;
    }
  }
`;
