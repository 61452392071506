import styled from 'styled-components';

export const FormAnswerStyled = styled.div`
  .Polaris-TextField__ClearButton {
    display: none !important;
  }

  .paginate {
    .Polaris-Pagination {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .answer-cell {
    max-width: 300px;
    overflow: hidden;
    width: 34%;
  }

  .name-cell {
    min-width: 150px;
    .Polaris-Text--root {
      white-space: break-spaces;
    }
  }

  .actions-cell {
    width: 10%;
    max-width: 80px;
  }

  .Polaris-Icon:hover {
    & > svg {
      fill: var(--p-color-icon-critical);
    }
  }
  .Polaris-Tag > button {
    margin-right: 5px;
  }
`;
