import { ICon } from './enum';

export const colors = {
  primary: 'rgba(48, 48, 48, 1)',
  gray_background: '#F1F1F1',
  warning: 'rgba(248, 217, 144, 1)',
  critical: 'rgba(245, 107, 82, 1)',
  danger: 'rgba(197, 40, 12, 1)',
  bgHover: '#f1f1f180',
  text_disabled: '#979797',
};

export const defaultColorIcon = [
  {
    icon: ICon.Messenger,
    backgroundColor: 'white',
  },
  {
    icon: ICon.Whatsapp,
    backgroundColor: '#49E670',
  },
  {
    icon: ICon.Phone,
    backgroundColor: '#03E78B',
  },
  {
    icon: ICon.Sms,
    backgroundColor: 'white',
  },
  {
    icon: ICon.Line,
    backgroundColor: '#38B900',
  },
  {
    icon: ICon.Viber,
    backgroundColor: '#665CAC',
  },
  {
    icon: ICon.Twitter,
    backgroundColor: '#000000',
  },
  {
    icon: ICon.WeChat,
    backgroundColor: '#05dc6b',
  },
  {
    icon: ICon.SnapChat,
    backgroundColor: '#FFE81D',
  },
  {
    icon: ICon.Skype,
    backgroundColor: '#03A9F4',
  },
  {
    icon: ICon.Email,
    backgroundColor: '#FF485F',
  },
  {
    icon: ICon.Instagram,
    backgroundColor: '#FF485F',
  },
  {
    icon: ICon.Telegram,
    backgroundColor: '#3E99D8',
  },
  {
    icon: ICon.GoogleMap,
    backgroundColor: '#37AA66',
  },
  {
    icon: ICon.TikTok,
    backgroundColor: '#000000',
  },
  {
    icon: ICon.Waze,
    backgroundColor: '#6ECCEF',
  },
  {
    icon: ICon.LinkedIn,
    backgroundColor: '#0077b5',
  },
  {
    icon: ICon.Vkontakte,
    backgroundColor: '#5076AA',
  },
  {
    icon: ICon.Slack,
    backgroundColor: '#350F37',
  },
  {
    icon: ICon.Discrod,
    backgroundColor: '#5865F2',
  },
  {
    icon: ICon.Teams,
    backgroundColor: '#4B53BC',
  },
];
