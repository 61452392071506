import styled from 'styled-components';

interface IProps {
  isCardHorizontal?: boolean;
  isSmallViewWidth?: boolean;
}

export const PricingCardStyled = styled.div<IProps>`
  h2 {
    font-size: 1rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  .Polaris-Badge .Polaris-Text--root {
    font-weight: 700;
  }
  .Polaris-ShadowBevel {
    height: ${(props) => (props.isCardHorizontal ? 'auto' : '100%')};
    width: ${(props) => (props.isCardHorizontal ? '100%' : 'auto')};
    .Polaris-Box {
      display: ${(props) => (props.isCardHorizontal ? 'flex' : 'block')};
      padding: ${(props) => (props.isCardHorizontal ? 0 : '')};
      flex-direction: ${(props) => (props.isSmallViewWidth ? 'column' : 'row')};
    }
  }
  .wrap-pricing-content {
    padding: ${(props) => (props.isCardHorizontal ? '12px' : 0)};
  }
`;
