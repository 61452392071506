import { colors } from '@/constants/colors';
import styled from 'styled-components';

export const ThingsToDoNextStyled = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .Polaris-Icon {
    cursor: pointer;
  }
  .progress {
    display: flex;
    align-items: center;
    margin-top: 8px;
    width: 250px;
  }
  .home-process-container {
    margin-left: 8px;
    width: 150px;
    height: 0.6rem;
    background-color: ${colors.gray_background};
    border-radius: 0.375rem;
    overflow: hidden;
  }
  .home-process {
    background-color: #1a1a1a;
    height: 100%;
  }
`;
