import { customizeSelected } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';

export const WidgetIconFour = () => {
  const scale = useSelector(customizeSelected).widgetSize;
  const customize = useSelector(customizeSelected);

  return (
    <svg
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={-1}
      svg-inline=""
      role="presentation"
      focusable="false"
      className="chat-icon chat-db"
    >
      <rect x="0" y="0" width="48" height="48" fill={customize.groupColor} className="color-fill"></rect>
      <path
        d="M27.91 18.311H14.045c-1.689 0-3.11 1.422-3.11 3.111v9.245c0 1.688 1.421 3.11 3.11 3.11h11.2l4.445 4.445c.177.178.266.178.622.178h.267c.266-.178.444-.444.444-.8V21.422c0-1.689-1.422-3.11-3.111-3.11z"
        fill={customize.groupIconColor || 'white'}
      ></path>
      <path
        d="M32.533 13.689H18.667c-1.69 0-3.111 1.422-3.111 3.111H27.91a4.59 4.59 0 014.622 4.622v10.311l1.69 1.69c.177.177.266.177.622.177h.266c.267-.178.445-.444.445-.8v-16c.089-1.689-1.334-3.111-3.023-3.111z"
        fill={customize.groupIconColor || 'white'}
      ></path>
    </svg>
  );
};
