import { images } from '@/asset';
import BoldText from '@/components/BoldText';
import RegularText from '@/components/RegularText';
import SkeletonPage from '@/components/SkeletonPage';
import { listIconPreview } from '@/pages/NewWidget/listIconDefault';
import { IResponseApi } from '@/types/apis/response';
import { Icon, IndexTable, Pagination } from '@shopify/polaris';
import { DesktopIcon, MobileIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useMemo, useState } from 'react';
import { TableChannelStyled } from './styled';
interface IProps {
  data: IResponseApi.IChannelSummary[];
  isLoading: boolean;
}

const Table = ({ data, isLoading }: IProps): JSX.Element => {
  const PERPAGE = 5;
  const [page, setPage] = useState(0);

  const filterIcon = useCallback((channel: IResponseApi.IChannelSummary) => {
    const result = listIconPreview.find((item) => item.keyValue === channel.channel);
    if (result) {
      return result.icon(channel.backgroundColor, channel.iconColor);
    }
  }, []);

  const items = useMemo(() => {
    let sortedData = [...data];
    //sort data by clicks when init data
    if (sortedData.length > 0) sortedData.sort((a, b) => parseInt(b.total) - parseInt(a.total));

    return sortedData.slice(page * PERPAGE, page * PERPAGE + PERPAGE)?.map((channel) => ({
      channelName: <div className="wrap-icon">{filterIcon(channel)}</div>,
      device: (
        <div className="table-analytics">
          <Icon source={DesktopIcon} tone="base" />
          <Icon source={MobileIcon} tone="base" />
        </div>
      ),
      click: (
        <div className="table-analytics">
          <RegularText>{channel.desktop}</RegularText>
          <RegularText>{channel.mobile}</RegularText>
        </div>
      ),
    }));
  }, [data, page, filterIcon]);

  const rowMarkup = useMemo(() => {
    return items?.map(({ click, channelName, device }, index) => (
      <IndexTable.Row id={index.toString()} key={index} position={index}>
        <IndexTable.Cell>{channelName}</IndexTable.Cell>
        <IndexTable.Cell>{device}</IndexTable.Cell>
        <IndexTable.Cell>{click}</IndexTable.Cell>
      </IndexTable.Row>
    ));
  }, [items]);

  return (
    <TableChannelStyled>
      {isLoading ? (
        <SkeletonPage />
      ) : (
        <div className="pb-8">
          <IndexTable
            emptyState={
              <div className="empty-data-container">
                <img src={images.emptyState} alt="No data" />
                <BoldText>No data to display</BoldText>
              </div>
            }
            itemCount={items?.length || 0}
            headings={[{ title: 'Channels' }, { title: 'Devices' }, { title: 'Unique clicks', alignment: 'center' }]}
            selectable={false}
          >
            {rowMarkup}
          </IndexTable>
          {data && data?.length > 5 ? (
            <div className="mt-16 paginate">
              <Pagination
                label={
                  data?.length ? `Showing ${page * PERPAGE + 1} to ${PERPAGE + page * PERPAGE} of ${data.length} items` : null
                }
                hasPrevious={!isLoading && page > 0}
                onPrevious={() => {
                  setPage((prev) => prev - 1);
                }}
                hasNext={!isLoading && page < Math.ceil(data.length / PERPAGE) - 1}
                onNext={() => {
                  setPage((prev) => prev + 1);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </TableChannelStyled>
  );
};

export default memo(Table);
