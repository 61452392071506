import { BREAKPOINT } from '@/constants/enum';
import styled from 'styled-components';

export const TimePickerStyled = styled.div`
  .Polaris-Connected {
    width: 100px;
    @media only screen and (max-width: ${BREAKPOINT.SM}px) {
      width: 130px;
    }
  }
`;
