import { customizeSelected } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';

export const WidgetIconTwo = () => {
  const customize = useSelector(customizeSelected);
  const scale = customize.widgetSize;

  return (
    <svg
      width={scale}
      height={scale}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      svg-inline=""
      role="presentation"
      focusable="false"
      tabIndex={-1}
      className="chat-icon chat-smile"
    >
      <rect x="0" y="0" width="48" height="48" fill={customize.groupColor} className="color-fill"></rect>
      <path
        d="M33.156 14.578h-20a3.281 3.281 0 00-3.29 3.289V29.51a3.281 3.281 0 003.29 3.289H30.31l4.8 4.8c.178.178.356.178.622.178h.356c.355-.178.533-.534.533-.8V17.867c-.178-1.778-1.689-3.29-3.466-3.29z"
        fill={customize.groupIconColor || 'white'}
      ></path>
      <path
        d="M18.045 26.844c2.222.978 7.555 1.867 11.555-2.4M32 24.356c-.09-1.067-.711-3.023-2.934-2.49"
        fill={customize.groupColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="color-stroke"
      ></path>
    </svg>
  );
};
