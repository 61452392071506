import { Button, Icon, Link, Modal, TextField, Tooltip } from '@shopify/polaris';

import { CustomLinkIcon } from '@/components/Icons/CustomLink';
import RegularText from '@/components/RegularText';
import WarningBanner from '@/components/WarningBanner';
import { BREAKPOINT, Device, ICon, PricingPlan, Subscription } from '@/constants/enum';
import { apiCaller } from '@/redux/query';
import toastSlice from '@/redux/slice/toast.slice';
import widgetSlice, { channelsSeletedSelector, customizeSelected, dataSelector, errorSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { PlusIcon } from '@shopify/polaris-icons';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { listIconDefault } from '../listIconDefault';
import { StepOneStyled } from './styled';
import { PATH } from '@/constants/path';
import { useNavigate } from 'react-router-dom';

const StepOne = () => {
  const error = useSelector(errorSelector);
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINT.SM });
  const { data: dataSetting } = apiCaller.useGeneralSettingsQuery();
  const navigate = useNavigate();
  const [modalConfirmRemove, setModalConfirmRemove] = useState<{ status: boolean; icon?: ICon; customlinkId?: number }>({
    status: false,
  });
  const dispatch = useDispatch();
  const channelsSelected = useSelector(channelsSeletedSelector);
  const customize = useSelector(customizeSelected);
  const data = useSelector(dataSelector);

  const handleChangeWidgetName = (value: string) => {
    dispatch(
      widgetSlice.actions.handleData({
        ...data,
        name: value,
      }),
    );
  };

  const handleIconClick = (key: ICon, value: IParamsApi.IChannelButton, customLinkId?: number) => {
    const newChannels = [...channelsSelected];
    const index = channelsSelected.findIndex((item) => item.channel === key);
    if (index === -1) {
      newChannels.push(value);
      if (error.status && error.field === 'buttons') {
        dispatch(
          widgetSlice.actions.handleError({
            status: false,
          }),
        );
      }
    } else {
      setModalConfirmRemove({
        status: true,
        customlinkId: customLinkId,
        icon: key,
      });
    }
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const handleRemoveCustomChannel = (customId?: number) => {
    const newChannels = [...channelsSelected];
    const index = channelsSelected.findIndex((item) => item.customLinkId === customId);
    newChannels.splice(index, 1);
    dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
  };

  const handleRemove = () => {
    if (modalConfirmRemove.customlinkId) {
      handleRemoveCustomChannel(modalConfirmRemove.customlinkId);
    } else {
      const newChannels = [...channelsSelected];
      const index = channelsSelected.findIndex((item) => item.channel === modalConfirmRemove.icon);
      if (index !== -1) {
        newChannels.splice(index, 1);
        dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
      }
    }
    const index = error.icon?.findIndex((icon) => icon === modalConfirmRemove.icon);
    if (index !== -1) {
      const newIcons = error.icon?.filter((icon) => icon !== modalConfirmRemove.icon);
      dispatch(
        widgetSlice.actions.handleError({
          ...error,
          icon: newIcons,
        }),
      );
    }
    dispatch(
      toastSlice.actions.handleToast({
        content: 'Deleted',
        error: false,
        isOpen: true,
      }),
    );
    setModalConfirmRemove({ status: false });
  };

  const isDisabledSelect = useMemo(() => {
    const isFreePlan = dataSetting?.data?.plan === PricingPlan.Free;
    return isFreePlan && channelsSelected.length >= 3;
  }, [channelsSelected.length, dataSetting?.data?.plan]);

  const isLimited = useMemo(() => {
    return dataSetting?.data?.plan === PricingPlan.Free || dataSetting?.data?.plan === PricingPlan.Basic;
  }, [dataSetting?.data?.plan]);

  return (
    <StepOneStyled borderRadius={customize.buttonStyle}>
      <WarningBanner isVisible={isDisabledSelect} hiddenContactSupport>
        <RegularText>
          You can add up to <b>3</b> channel buttons per widget with current plan.{' '}
          <Link
            onClick={() => {
              navigate(PATH.PRICING_PLAN, { state: Subscription.Monthly });
            }}
          >
            <b>Upgrade now</b>
          </Link>{' '}
          to add unlimited channels at once.
        </RegularText>
      </WarningBanner>
      <TextField
        error={error.msg ? 'Name is already existed' : error.field === 'name' ? 'Name is required!' : undefined}
        onBlur={() => {
          if (!data.name) {
            dispatch(
              widgetSlice.actions.handleError({
                ...error,
                field: 'name',
                status: true,
              }),
            );
          }
        }}
        onChange={handleChangeWidgetName}
        value={data.name}
        requiredIndicator
        label="Widget name"
        autoComplete="off"
      />
      <div className="icon-button-container">
        {/* render list icon default */}
        {listIconDefault.map((item) => {
          const isSelected = channelsSelected.find((channel) => channel.channel === item.keyValue);
          const isDisableFormContact = isLimited && item.keyValue === ICon.ContactForm;
          const isDisabled = !isSelected && (isDisableFormContact || isDisabledSelect);
          return (
            <div
              className={`channel-container-select${isSelected ? ' selected' : ''}${isDisabled ? ' disabled-select' : ''}`}
              key={`${item.keyValue} widget name`}
              onClick={() => {
                if (isDisabled) return;
                handleIconClick(item.keyValue, item.value, isSelected?.customLinkId);
              }}
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
              }}
            >
              {isDisableFormContact && !isSelected ? (
                <Tooltip content={'Available on Pro and Premium plan'}>
                  <div className={`icon-container ${!isSelected ? 'disabled-select' : ''}`}>{item.icon}</div>
                </Tooltip>
              ) : (
                <div className={`icon-container ${!isSelected && isDisabledSelect ? 'disabled-select' : ''}`}>{item.icon}</div>
              )}

              <div className="text-container">{item.label}</div>
            </div>
          );
        })}
        {/* Render customlink selected */}
        {channelsSelected
          .filter((channel) => channel.channel === ICon.CustomLink)
          .map((item, index) => {
            return (
              <div
                className={`channel-container-select selected`}
                key={`${index}-custom-channel`}
                onClick={() =>
                  setModalConfirmRemove({
                    status: true,
                    customlinkId: item.customLinkId,
                    icon: ICon.CustomLink,
                  })
                }
              >
                <div className="icon-container">
                  <CustomLinkIcon channel={item} />
                </div>

                <div className="text-container">Custom {index + 1}</div>
              </div>
            );
          })}
        {/* Render button add icon */}
        <div
          className={`channel-container-select${isDisabledSelect ? ' disabled-select' : ''}`}
          onClick={() => {
            if (isDisabledSelect) return;
            const newChannels = [...channelsSelected];
            const listCustomLinkId = newChannels
              .filter((item) => typeof item.customLinkId === 'number')
              .map((item) => item.customLinkId || 1);

            newChannels.push({
              customLinkId: listCustomLinkId.length > 0 ? Math.max(...listCustomLinkId) + 1 : 1,
              channel: ICon.CustomLink,
              value: '',
              hoverText: 'CustomLink',
              showOn: [Device.Pc, Device.Mobile],
              backgroundColor: '#1E88E5',
              iconColor: '#ffffff',
              iconId: 1,
            });
            dispatch(widgetSlice.actions.handleChannelsSelected(newChannels));
          }}
          style={{
            cursor: isDisabledSelect ? 'not-allowed' : 'pointer',
          }}
        >
          <div className={`add-custom-container icon-container ${isDisabledSelect ? 'disabled-select' : ''}`}>
            <Icon source={PlusIcon} />
          </div>

          <div className="text-container">Add custom</div>
        </div>
      </div>

      {/* Modal remove channel */}

      <Modal
        open={modalConfirmRemove.status}
        title="Are you sure?"
        onClose={() => setModalConfirmRemove({ status: false })}
        sectioned
        footer={
          <div className="content-right" style={{ width: isMobile ? 'calc(100vw - 3rem)' : '588px' }}>
            <Button onClick={() => setModalConfirmRemove({ status: false })}>No</Button>
            <div className="ml-8">
              <Button tone="critical" variant="primary" onClick={handleRemove}>
                Yes
              </Button>
            </div>
          </div>
        }
      >
        <RegularText>
          Do you want to remove the <b>{listIconDefault.find((item) => item.keyValue === modalConfirmRemove.icon)?.label}</b>{' '}
          button from the widget?
        </RegularText>
      </Modal>
    </StepOneStyled>
  );
};

export default StepOne;
