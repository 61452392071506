import { ICon } from '@/constants/enum';
import { useChannelSelected } from '@/hooks/useChannelSelected';
import { triggerSelector } from '@/redux/slice/widget.slice';
import { IParamsApi } from '@/types/apis/params';
import { Button, Text } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import AgentCard from './components/AgentCard.ts';
import { workingTimeFilter } from './helper/agent';
import { AgentStyled } from './styled';
interface IProps {
  handleSelectedAgent: (agent: IParamsApi.IAgent | undefined) => void;
  channel: ICon;
  handleClose: () => void;
}
const AgentWindow = ({ channel, handleSelectedAgent, handleClose }: IProps) => {
  const dataChannel = useChannelSelected(channel);
  const trigger = useSelector(triggerSelector);

  return (
    <AgentStyled>
      <div className="header">
        <Text fontWeight="bold" as="p">
          List agents
        </Text>
        <Button icon={XIcon} variant="plain" onClick={handleClose}></Button>
      </div>
      <div className="wrap-agent">
        {workingTimeFilter(dataChannel.channel?.agents || [], trigger.timezone)
          .slice(0, 2)
          ?.map((agent) => (
            <div
              className="text-center d-flex justify-center"
              style={{
                width: dataChannel.channel?.agents
                  ? `${workingTimeFilter(dataChannel.channel?.agents, trigger.timezone).length === 1 ? 70 : 35}%`
                  : undefined,
              }}
              onClick={() => {
                handleSelectedAgent(agent);
              }}
              key={agent.customId}
            >
              <AgentCard data={agent} />
            </div>
          ))}
      </div>
    </AgentStyled>
  );
};

export default memo(AgentWindow);
