import { customizeSelected } from '@/redux/slice/widget.slice';
import { useSelector } from 'react-redux';
import { CloseStyled } from './styled';

export const CloseIcon = () => {
  const customize = useSelector(customizeSelected);
  const scale = useSelector(customizeSelected).widgetSize;

  return (
    <CloseStyled
      borderRadius={customize.buttonStyle || 'none'}
      scale={customize.widgetSize}
      display={customize.buttonDirection}
      tooltipBackgroundColor={customize.ctaBackground}
      tooltipTextColor={customize.ctaTextColor}
      className="close-icon"
      style={{
        backgroundColor: customize.groupColor,
        width: `${scale}px`,
        height: `${scale}px`,
      }}
    >
      <svg
        width={scale * 0.4}
        height={scale * 0.4}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        svg-inline=""
        role="presentation"
        focusable="false"
        tabIndex={-1}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.757.929A2 2 0 10.93 3.757L5.172 8 .929 12.243a2 2 0 002.828 2.828L8 10.828l4.243 4.243a2 2 0 002.828-2.828L10.83 8l4.242-4.243A2 2 0 1012.243.93L8 5.172 3.757.929z"
          fill={customize.groupIconColor}
        ></path>
      </svg>
    </CloseStyled>
  );
};
