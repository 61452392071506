import styled from 'styled-components';

export const IconBorderStyled = styled.div`
  margin-top: 8px;
  display: flex;
  width: fit-content;
  background-color: white;
  border: 1px solid #aaaaaa;
  .icon-border-wrapper {
    cursor: pointer;
    padding: 8px;
    border-right: 1px solid #e3e4e8;
  }
  .icon-border-container {
    width: 24px;
    height: 24px;
    background-color: #c2c2c2;
  }
  .active {
    background-color: #030303;
  }
`;
