import { useEffect, useState } from 'react';

export const useInView = (ref: React.RefObject<HTMLDivElement>) => {
  const [inview, setInview] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setInview(entry.isIntersecting);
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return inview;
};
