import { WidgetIcon } from '@/constants/enum';
import { fileToBase64 } from '@/helpers/file';
import widgetSlice, { customizeSelected } from '@/redux/slice/widget.slice';
import { DropZone, Icon, Text } from '@shopify/polaris';
import { AlertCircleIcon, UploadIcon } from '@shopify/polaris-icons';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomWidgetIcon from '../Icons/CustomWidgetIcon';
import { WidgetUploadStyled } from './styled';

function UploadWidgetIcon() {
  const dispatch = useDispatch();
  const [isShowErrorInline, setIsShowErrorInline] = useState({ status: false, msg: '' });
  const scale = useSelector(customizeSelected).widgetSize;
  const customize = useSelector(customizeSelected);
  const handleDropZoneDrop = useCallback(
    (dropFiles: File[], _acceptedFiles: File[], _rejectedFiles: File[]) => {
      const uploadedFile = _acceptedFiles[0];
      if (uploadedFile) {
        fileToBase64(uploadedFile).then((res) => {
          dispatch(
            widgetSlice.actions.handleCustomize({
              ...customize,
              groupIcon: {
                key: WidgetIcon.CustomWidgetIcon,
                file: res as string,
                url: window.URL.createObjectURL(uploadedFile),
              },
            }),
          );
        });
      }
    },
    [customize, dispatch],
  );
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];

  const validateFile = (file: any) => {
    setIsShowErrorInline({ status: false, msg: '' });
    if (file.size > 100000) {
      setIsShowErrorInline({ status: true, msg: 'File too large' });
    } else if (!validImageTypes.includes(file.type)) {
      setIsShowErrorInline({ status: true, msg: 'Unsupported format' });
    } else {
      setIsShowErrorInline({ status: false, msg: '' });
    }
    return file.size < 100000 && validImageTypes.includes(file.type);
  };
  return (
    <WidgetUploadStyled>
      <DropZone
        id="widget-upload"
        type="image"
        accept={validImageTypes.toString()}
        customValidator={validateFile}
        onDrop={handleDropZoneDrop}
      >
        <div
          className={
            customize.groupIcon.key === WidgetIcon.CustomWidgetIcon
              ? 'upload-container upload-container-active'
              : 'upload-container'
          }
          style={{
            width: `${scale}px`,
            height: `${scale}px`,
            borderRadius: customize.buttonStyle,
            backgroundColor: 'black',
          }}
        >
          {customize.groupIcon.url ? <CustomWidgetIcon /> : <Icon source={UploadIcon} />}
        </div>
      </DropZone>
      {isShowErrorInline.status ? (
        <div className="mt-8 d-flex provide-evidence">
          <Icon source={AlertCircleIcon} tone="critical" />
          <Text as="span" variant="bodyLg" tone="critical">
            {isShowErrorInline.msg}
          </Text>
        </div>
      ) : null}
    </WidgetUploadStyled>
  );
}

export default memo(UploadWidgetIcon);
