import { Collapsible, LegacyStack, Spinner } from '@shopify/polaris';
import { StepContainer } from './styled';
import RegularText from '@/components/RegularText';
import { memo } from 'react';
import { images } from '@/asset';

interface Props {
  isDone?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
  dataStep: {
    id: number;
    name: string;
    component: JSX.Element;
  };
  isDisabled: boolean;
  isFetching: boolean;
}

const Step = ({ isDone, open, setOpen, dataStep, isDisabled, isFetching }: Props) => {
  const handleToggle = () => {
    setOpen((prev) => (prev === dataStep.id ? -1 : dataStep.id));
  };
  return (
    <StepContainer isOpen={open}>
      <div className="content-container">
        <LegacyStack vertical>
          <div
            className={`header ${isDisabled && 'header-disabled'}`}
            onClick={() => {
              if (!isDisabled) handleToggle();
            }}
          >
            <div className="header-title">
              {isFetching ? (
                <div className="mr-8">
                  <Spinner accessibilityLabel="Loading" size="small" />
                </div>
              ) : isDone ? (
                <img src={images.checkedIcon} alt="circle tick" className="mr-8" />
              ) : (
                <div className="header-icon"></div>
              )}
              <RegularText>{dataStep.name}</RegularText>
            </div>
          </div>
          <Collapsible
            open={open}
            id="basic-collapsible"
            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
            expandOnPrint
          >
            <div className="collapsible-content">{dataStep.component}</div>
          </Collapsible>
        </LegacyStack>
      </div>
    </StepContainer>
  );
};

export default memo(Step);
